import React from 'react';
import { Button, Image, Stack } from 'react-bootstrap';

import aolThumb from '../assets/images/aol-oauth.jpg';
import LogoImage from '../assets/images/favicon.svg';
import outlookcomThumb from '../assets/images/outlookcom-oauth.jpg';
import yahooThumb from '../assets/images/yahoo-oauth.jpg';
import { Credential } from '../shared/types';
import ContentCard from './ContentCard';

type Props = {
  credential: Credential;

  onClick: () => void;
};

const CredentialCard = ({ credential, onClick }: Props) => {
  return (
    <ContentCard className="credential-card">
      <Stack direction="horizontal" gap={3} className="align-items-center">
        <Stack gap={3} className="align-items-start align-self-start">
          <Stack direction="horizontal" gap={3} className="align-items-center">
            <Image fluid src={credential.icon} alt={credential.title} loading="lazy" />
            <h3 className="m-0">{credential.title}</h3>
          </Stack>

          {!credential.id && (
            <Stack className="fs-7" gap={2}>
              <div className="text-danger">
                You don’t have {credential.title} credentials configured.
              </div>
              <div>
                You have to provide your own {credential.title} credentials in order to
                enable it in the Contact Picker.
              </div>
            </Stack>
          )}

          {credential.readonly && (
            <Stack direction="vertical" className="fs-7" gap={2}>
              <div className="d-flex gap-2 mt-3 fs-7 align-items-center">
                <img src={LogoImage} alt="CloudSponge" width="38" />
                <div className="d-flex flex-column">
                  <span className="fw-semibold text-dark">
                    CloudSponge Shared Default
                  </span>
                  <span className="fs-8">https://api.cloudsponge.com</span>
                </div>
              </div>
              <div>
                All sites in this project will use CloudSponge’s shared credentials for{' '}
                {credential.title}.
                {credential.source !== 'aol' && (
                  <>
                    If you’d like your users to see your company’s brand in the permission
                    window instead, then please configure your own provider credentials.
                  </>
                )}
              </div>
            </Stack>
          )}

          {credential.source !== 'aol' && (
            <div className="mt-auto mx-auto mx-sm-0">
              <Button variant="outline-info" onClick={onClick}>
                Configure your {credential.shortTitle} credentials
              </Button>
            </div>
          )}
        </Stack>

        {credential.source === 'outlookcom' && credential.readonly && (
          <div className="d-none d-lg-block">
            <img src={outlookcomThumb} alt="CloudSponge" width="200" />
          </div>
        )}
        {credential.source === 'yahoo' && credential.readonly && (
          <div className="d-none d-lg-block">
            <img src={yahooThumb} alt="CloudSponge" width="200" />
          </div>
        )}
        {credential.source === 'aol' && credential.readonly && (
          <div className="d-none d-lg-block">
            <img src={aolThumb} alt="CloudSponge" width="200" />
          </div>
        )}
      </Stack>
    </ContentCard>
  );
};

export default CredentialCard;
