import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { HiOutlineBars3 } from 'react-icons/hi2';

import ButtonIcon from './ButtonIcon';
import Logo from './Logo';
import NavigationMenu from './NavigationMenu';
import NavigationSocial from './NavigationSocial';
import NavigationZapier from './NavigationZapier';

const Navigation = () => {
  const [show, setShow] = useState(false);
  const toggleNavigation = () => setShow(!show);

  return (
    <>
      <ButtonIcon className="d-lg-none" onClick={toggleNavigation}>
        <HiOutlineBars3 className="icon icon--stroke" />
      </ButtonIcon>
      <Offcanvas show={show} onHide={toggleNavigation} responsive="lg">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Logo />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <Stack className="navigation-holder">
            <NavigationMenu onClick={toggleNavigation} />
            <NavigationZapier />
            <NavigationSocial />
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Navigation;
