import React, { useMemo } from 'react';

import { useAuth } from '../contexts/authContext';
import AppsumoTier from './AppsumoTier';
import Loading from './Loading';
import PlanPicker from './PlanPicker';
import SubscriptionDetails from './SubscriptionDetails';

type Props = {
  isGauntlet?: boolean;
};

const Subscription = ({ isGauntlet }: Props) => {
  const { account } = useAuth();

  const appsumoDetails = {
    cloudspongecontactpicker_tier1: {
      tierName: 'AppSumo Tier 1',
      numSites: 1,
      numBooks: 500,
    },
    cloudspongecontactpicker_tier2: {
      tierName: 'AppSumo Tier 2',
      numSites: 5,
      numBooks: 750,
    },
    cloudspongecontactpicker_tier3: {
      tierName: 'AppSumo Tier 3',
      numSites: 10,
      numBooks: 1000,
    },
    cloudspongecontactpicker_tier4: {
      tierName: 'AppSumo Tier 4',
      numSites: 20,
      numBooks: 2500,
    },
    cloudspongecontactpicker_tier5: {
      tierName: 'AppSumo Tier 5',
      numSites: 50,
      numBooks: 5000,
    },
  };

  const appSumoDetails = useMemo(
    () => appsumoDetails[account?.subscription.handle as keyof typeof appsumoDetails],
    [account],
  );

  return (
    <>
      {!account ? (
        <Loading variant="fullheight" />
      ) : account?.subscription.partner == 'appsumo' && appSumoDetails ? (
        <AppsumoTier
          subscription={account?.subscription}
          numBooks={appSumoDetails.numBooks}
          numSites={appSumoDetails.numSites}
          tierName={appSumoDetails.tierName}
        />
      ) : !account?.subscription.customer_id ||
        account?.subscription.payment_method_required ? (
        <PlanPicker title="Choose your Plan" isGauntlet={isGauntlet} />
      ) : (
        <SubscriptionDetails subscription={account?.subscription} />
      )}
    </>
  );
};

export default Subscription;
