import { Formik } from 'formik';
import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useFetcher, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';



import { RoutePaths } from '../config/route-paths';
import ButtonSubmit from './ButtonSubmit';
import analytics from '../libs/analytics';


type FormValues = {
  name: string;
};

const FormProjectCreate = () => {
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = useMemo(() => {
    return fetcher.state === 'loading' || fetcher.state === 'submitting';
  }, [fetcher.state]);

  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  const handleSubmit = async (values: FormValues) => {
    analytics.track('User created project', { name: values.name });
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('action', 'create');
    fetcher.submit(formData, {
      method: 'post',
    });
  };

  useEffect(() => {
    if (!fetcher.data || fetcher.state !== 'idle') return;
    //navigate to last created sites on success create
    navigate(`${RoutePaths.PROJECTS}/${fetcher.data.project.key}/${RoutePaths.SITES}`);
  }, [fetcher]);

  return (
    <>
      <h1 className="text-center mb-4">Create a New Project</h1>

      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          name: '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Enter your project name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                isInvalid={touched.name && !!errors.name}
              />
              {errors.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <p className="text-center mt-4">
              <ButtonSubmit type="submit" loading={isLoading}>
                Create Project
              </ButtonSubmit>
            </p>
          </Form>
        )}
      </Formik>
      {location.state?._isRedirect ? null : (
        <p className="text-center mt-4">
          <Link to={`${RoutePaths.PROJECTS}`}>Go Back</Link>
        </p>
      )}
    </>
  );
};

export default FormProjectCreate;