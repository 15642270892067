import React from 'react';
import { Dropdown, DropdownButton, Stack } from 'react-bootstrap';
import ButtonCopy from './ButtonCopy';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RoutePaths } from '../config/route-paths';
import { ApiKey } from '../shared/types';

type Props = {
  projectKey: string;
  keys: ApiKey[];
};

const ProjectBarKey = ({ projectKey, keys=[] }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const nextPath = location.pathname.match(`(.+)\/${projectKey}\/(.+)`)?.slice(2,3) || [RoutePaths.METRICS];

  return (
    <Stack direction="horizontal" gap={1}>
      <>
        <span className="text-muted d-none d-sm-block">Project key:</span>
        {keys.length > 1 ? (
          <Dropdown>
            <DropdownButton variant="link" title={<strong>{projectKey}</strong>} bsPrefix="dropdown-toggle btn btn-link btn-sm fs-8 px-0">
              {keys.map((apiKey) => (
                apiKey.key === projectKey ?
                  null :
                  <Dropdown.Item key={apiKey.id} onClick={()=> navigate(`${RoutePaths.PROJECTS}/${apiKey.key}/${nextPath}`)}>
                    <Link to={`${RoutePaths.PROJECTS}/${apiKey.key}/${nextPath}`} className="fs-8">
                      {apiKey.key}
                    </Link>
                  </Dropdown.Item>
                )
              )}
            </DropdownButton>
          </Dropdown>
        ) : 
          <strong>{projectKey}</strong>
        }
        <ButtonCopy text={projectKey} />
      </>
    </Stack>
  );
};

export default ProjectBarKey;
