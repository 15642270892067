import React from 'react';
import { Link } from 'react-router-dom';

import FormProjectCreate from '../components/FormProjectCreate';
import Page from '../components/Page';
import PageSection from '../components/PageSection';

const NewProject = () => {
  return (
    <Page size="md">
      <PageSection className="my-auto">
        <FormProjectCreate />
      </PageSection>
    </Page>
  );
};

export default NewProject;
