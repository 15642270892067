import { Suspense } from 'react';
import { Await, useRouteLoaderData } from 'react-router-dom';

import { Site, SitesLoaderData } from '../shared/types';
import DevSitesListContainer from './DevSiteListContainer';
import Loading from './Loading';

type Props = {
  sites: Site[];
};

const DevSitesList = () => {
  const { data } = useRouteLoaderData('sites') as { data: SitesLoaderData };

  return (
    <>
      <Suspense fallback={<Loading position="fullheight" />}>
        <Await resolve={data} errorElement={<div>Could not load sites 😬</div>}>
          {({ devSites }: SitesLoaderData) => (
            <DevSitesListContainer devSites={devSites} />
          )}
        </Await>
      </Suspense>
    </>
  );
};

export default DevSitesList;
