import { useEffect, useMemo, useState } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { useRouteLoaderData } from 'react-router-dom';



import { useAuth } from '../contexts/authContext';
import useSubscriptionDescriptions from '../hooks/useSubscriptionDescriptions';
import { fetchSitesWithQueryStr } from '../services/sites';
import { Site, SitesData } from '../shared/types';
import Loading from './Loading';
import PageSection from './PageSection';
import SitesListCard from './SitesListCard';
import SubscriptionStatus from './SubscriptionStatus';


type Props = {
  sites: Site[];
};

const ProdSitesListContainer = ({ productionSites }: { productionSites: SitesData }) => {
  const { account } = useAuth();
  const [sites, setSites] = useState(productionSites.sites);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(productionSites.totalRecords);
  const { summary } = useSubscriptionDescriptions(account?.subscription);

  const totalRecordsDefault = useMemo(
    () => productionSites.totalRecords,
    [productionSites],
  );

  useEffect(() => {
    setSites(productionSites.sites);
    setFilter('');
    setPage(1);
    setTotalRecords(productionSites.totalRecords);
  }, [productionSites]);

  /**
   * Project id the site belongs to
   */
  const { projectId } = useRouteLoaderData('project') as {
    projectId: string;
  };

  const loadMoreSites = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const moreSites = await fetchSitesWithQueryStr({
      page: page + 1,
      projectId: projectId,
      filter: filter,
      segment: 'production',
    });
    const updatedSites = sites.concat(moreSites.productionSites.sites);
    setSites(updatedSites);
    setPage(page + 1);
  };

  const filterSites = async ({ target }: { target: { name: string; value: string } }) => {
    setFilter(target.value);

    const filteredData = await fetchSitesWithQueryStr({
      page: 1,
      projectId: projectId,
      filter: target.value,
      segment: 'production',
    });
    //filter after string is 3 chars or more or restore unfiltered site list
    if (target.value.length > 2 || target.value.length === 0) {
      setSites(filteredData.productionSites.sites);
      setTotalRecords(filteredData.productionSites.totalRecords);
      //reset page to display from page=1 initially
      setPage(1);
    }
  };

  return (
    <>
      <PageSection>
        {totalRecordsDefault > 0 ? (
          <div className="mb-4 d-flex flex-column flex-sm-row gap-2 justify-content-between">
            <div>
              <div className="d-flex mb-1 licensed-title-wrapper">
                <h2 className="mb-0">Licensed ({totalRecords}) </h2>
                {account && account.subscription && summary && (
                  <SubscriptionStatus subscription={account.subscription} />
                )}
              </div>
              <p className="fs-7 mb-0">You’ll be charged for these sites.</p>
            </div>
            <div>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Group controlId="search">
                  <Form.Control
                    className="search-input"
                    type="search"
                    placeholder="Search sites..."
                    onChange={filterSites}
                    value={filter || ''}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
        ) : null}

        {sites.length > 0 ? (
          <Stack gap={2}>
            {sites.map((item: any) => (
              <SitesListCard key={item.id} site={item} />
            ))}
            {totalRecords > sites.length ? (
              <div className="text-center pt-3">
                <a href="#" onClick={loadMoreSites}>
                  Load next
                </a>
              </div>
            ) : null}
          </Stack>
        ) : null}
      </PageSection>
    </>
  );
};

export default ProdSitesListContainer;