import { axios } from '../utils/utils';
 
export const put = (path: string, params = {}) => {
  return axios
    .put(path, {
      ...params,
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
}; 
export const post = (path: string, params = {}) => {
  return axios
    .post(path, {
      ...params,
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};

export const destroy = (path: string) => {
  return axios
    .delete(path)
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};