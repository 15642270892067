import React, { useState, useEffect, FormEventHandler } from 'react'
// import the address-book-connector object from the package
import addressBookConnector from '@cloudsponge/address-book-connector.js';

const AddressBookConnector = ({
  cloudspongeKey,
  onSubmit,
  onSuccess,
  onComplete,
  onClose,
  onError,
  children 
} : {
  cloudspongeKey: string,
  onSubmit?: Function,
  onSuccess?: Function,
  onComplete?: Function,
  onClose?: Function,
  onError?: Function,
  children?: Function | JSX.Element | JSX.Element[],
}) => {
  const [contacts, setContacts] = useState('');
  useEffect(() => {
    addressBookConnector.setOptions({
      key: cloudspongeKey,
      success: () => {
        onSuccess && onSuccess();
        onComplete && onComplete();
      },
      failure: (error: {status: number, responseText: string}) => {
        onError && onError(error);
        onComplete && onComplete();
      },
      cloudspongeOptions: {
        key: cloudspongeKey,
        afterClosing: () => {
          onClose && onClose();
        },
      },
    });
  }, []);

  return (
    <form data-addressbookconnector-js onSubmit={() => onSubmit && onSubmit()}>
      {typeof children === 'function' ? children(contacts) : children}
    </form>
  )
}

export default AddressBookConnector;
