import { axios } from '../utils/utils';
import { ActionFunctionArgs } from 'react-router-dom';

import { NewUserData } from '../components/FormCreateNewUsers';
import config from '../config/config';
import { trackedEvents } from '../config/tracked-events';
import analytics from '../libs/analytics';
import { Account, NewUser, User } from '../shared/types';
import { put } from './rest';

// TODO: use on signup page
export const fetchMeWithToken = async (token: string) => {
  return axios
    .post(`${config.DATA_PATH}/users/token`, {
      token,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const confirmWithToken = async (token: string, opts = {}) => {
  return axios
    .post(`${config.DATA_PATH}/users/confirm`, {
      user: { token },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createUser = async (user: NewUser) => {
  try {
    const res = await axios.post(`${config.DATA_PATH}/users/register`, {
      format: 'json',
      user: user,
    });
    analytics.track(trackedEvents.createdLogin); // old event
    analytics.track(trackedEvents.signedUp); // old event
    analytics.track(trackedEvents.visitorSubmittedSugnUpForm);
    return res.data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const resendConfirmationEmail = async (email: string) => {
  try {
    const res = await axios.post(`${config.DATA_PATH}/users/resend_confirmation`, {
      format: 'json',
      user: { email },
    });
    analytics.track(trackedEvents.resentConfirmationEnail);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAccount = async (opts = {}) => {
  try {
    const res = await put(`${config.DATA_PATH}/account`, opts);

    return res.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getAccount = async (accountId: Account['id']) => {
  try {
    const response = await axios.get(`${config.DATA_PATH}/accounts/${accountId}`);

    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const skipPaymentInfo = async () => {
  try {
    const res = await axios.post(`${config.DATA_PATH}/subscriptions/skip`);
    return res.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const requestPasswordReset = (user: { email: string }) => {
  try {
    const res = axios.post(`${config.DATA_PATH}/users/request_password_reset`, {
      format: 'json',
      user,
    });
    analytics.track(trackedEvents.requestPasswordReset);
    return res;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const resetPassword = async (user: any, opts: any = {}) => {
  return axios
    .post(`${config.DATA_PATH}/users/reset_password?inviting=${opts?.inviting}`, {
      format: 'json',
      user: {
        ...user,
        inviting: opts.inviting,
      },
    })
    .then((response) => {
      if (opts.inviting) {
        analytics.track(trackedEvents.inviteeSubmittedSignUpForm);
        analytics.track(trackedEvents.accountAddedLogin);
      } else {
        analytics.track(trackedEvents.resetPassword);
      }
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};
// export const deleteUser = (id: string) => {
//   return axios
//     .delete(`${config.DATA_PATH}/users/${id}`)
//     .then((response) => {
//       analytics.track('Deleted user');
//       return Promise.resolve(response.data);
//     })
//     .catch((error) => {
//       console.error(error);
//       return Promise.reject(error);
//     });
// };
export const destroyImpersonation = () => {
  return axios
    .delete(`${config.DATA_PATH}/session/destroy_impersonation`)
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};

export const fetchUsers = async () => {
  try {
    const users = await axios.get(`${config.DATA_PATH}/users`);
    return users.data;
  } catch (error) {
    console.error({ error });
    return Promise.reject(error);
  }
};
export const createUsers = async (users: any) => {
  return axios
    .post(`${config.DATA_PATH}/users`, {
      format: 'json',
      users: users,
    })
    .then((response) => {
      // send a invited user event for each new user
      users.forEach((invitee: any) => {
        analytics.track('Invited User'); // old event
        analytics.track('Created Login'); // old event
        analytics.track('User invited another user', { invitee });
        analytics.page('/users/invited');
      });
      return Promise.resolve(response);
    })
    .catch(({ response }) => {
      console.error(response.data);
      return Promise.reject({ users: response.data, status: response.status });
    });
};
//@TODO fix analytics
export const updateUser = async (id: string, user: User) => {
  try {
    const updatedUser = await axios.put(`${config.DATA_PATH}/users/${id}`, {
      user: user,
    });
    if ('owner' in user) {
      analytics.track('Changed owner', { user_id: id });
    }
    if ('is_administrator' in user) {
      analytics.track('Changed user role', {
        user_id: id,
        is_administrator: user.is_administrator,
      });
    }
    return updatedUser.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export async function teamActions({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData();
  if (formData.get('action') === 'createUsers') {
    try {
      const usersFormData = formData.get('users') as string;
      const users = JSON.parse(usersFormData);
      const createUsers = await axios.post(`${config.DATA_PATH}/users`, {
        users: users,
      });
      // users.forEach((invitee: NewUserData) => {
      //   analytics.track('Invited User'); // old event
      //   analytics.track('Created Login'); // old event
      //   analytics.track('User invited another user', { invitee });
      //   analytics.page('/users/invited');
      // });
    } catch (error) {
      console.error(error);
      if (error?.code === "ERR_BAD_REQUEST") {
        return error.response;
      }
      return Promise.reject(error);
    }
    return { ok: true };
  }
  if (formData.get('action') === 'toggleAdmin') {
    try {
      const id = formData.get('id');
      const isAdministrator = formData.get('user') === 'true' ? true : false;
      const updatedUser = await axios.put(`${config.DATA_PATH}/users/${id}`, {
        user: { is_administrator: isAdministrator },
      });
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
    return { ok: true };
  }
  if (formData.get('action') === 'toggleOwner') {
    try {
      const id = formData.get('id');
      const isOwner = formData.get('user') === 'true' ? true : false;
      const updatedUser = await axios.put(`${config.DATA_PATH}/users/${id}`, {
        user: { owner: isOwner },
      });
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
    return { ok: true };
  }
  if (formData.get('action') === 'deleteUser') {
    try {
      const id = formData.get('id');
      const deletedUser = await axios.delete(`${config.DATA_PATH}/users/${id}`);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
    return { ok: true };
  }
}
