import React from 'react';
import { Helmet } from 'react-helmet-async';

import FormSignIn from '../components/FormSignIn';
import PageHeader from '../components/PageHeader';
import PageSection from '../components/PageSection';

const Signin = () => {
  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>

      <PageHeader>
        <h1>Sign In</h1>
      </PageHeader>
      <PageSection>
        <FormSignIn />
      </PageSection>
    </>
  );
};

export default Signin;
