import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { footerUrls } from '../config/external-urls';
import ExternalLink from './ExternalLink';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <Container fluid>
        <div className="footer__inner">
          <Row xs="1" sm="2">
            <Col>
              <p className="m-0 text-center text-sm-start">
                Copyright © 2010-{year} - Cloud Copy, Inc. All rights reserved.
              </p>
            </Col>
            <Col className="ms-auto">
              <p className="m-0 text-center text-sm-end">
                <ExternalLink href={footerUrls.terms}>Terms of Service</ExternalLink>

                <span className="sep">|</span>
                <ExternalLink href={footerUrls.privacy}>Privacy Policy</ExternalLink>

                <span className="sep">|</span>
                <ExternalLink href={footerUrls.gdpr}>GDPR</ExternalLink>
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
