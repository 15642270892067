import React, { useMemo, useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { HiOutlineExclamationTriangle, HiOutlineTrash } from 'react-icons/hi2';
import { useFetcher } from 'react-router-dom';

import { supportEmail } from '../config/external-urls';
import { Site } from '../shared/types';
import ButtonIcon from './ButtonIcon';
import ButtonSubmit from './ButtonSubmit';
import ExternalLink from './ExternalLink';
import Loading from './Loading';
import ModalDialog from './ModalDialog';

type Props = {
  site: Site;
};

const FormSiteDelete = ({ site }: Props) => {
  const fetcher = useFetcher();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isLoading = useMemo(() => {
    return (
      (fetcher.state === 'loading' || fetcher.state === 'submitting') &&
      fetcher.formData?.get('action') !== 'convertToDev'
    );
  }, [fetcher.state]);
  const handleDelete = () => {
    const formData = new FormData();
    formData.append('siteId', site.id);
    formData.append('action', 'delete');
    fetcher.submit(formData, {
      method: 'post',
    });
  };
  const handleConvertToDev = () => {
    const formData = new FormData();
    formData.append('siteId', site.id);
    formData.append('action', 'convertToDev');
    fetcher.submit(formData, {
      method: 'post',
    });
  };
  const handleShow = () => {
    setShowDeleteModal(true);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      <ButtonIcon onClick={handleShow}>
        <HiOutlineTrash className="icon icon--stroke" />
      </ButtonIcon>

      <ModalDialog show={showDeleteModal}>
        <Stack gap={2} className="align-items-center text-center">
          <span>
            <HiOutlineExclamationTriangle className="icon icon--stroke icon--xl text-danger" />
          </span>
          <div>
            <h3>
              {site.production ? (
                <>You are about to delete a site</>
              ) : (
                <>You are about to delete a test site</>
              )}
            </h3>
            <p className="fs-5 text-danger fw-semibold">{site.name}</p>
            <p className="fs-7">
              {site.production ? (
                <>
                  These changes may disrupt your CloudSponge service. You have been
                  warned! Need help, please contact us at{' '}
                  <ExternalLink href={`mailto:${supportEmail}`}>
                    {supportEmail}
                  </ExternalLink>
                </>
              ) : (
                <>
                  Need help, please contact us at{' '}
                  <ExternalLink href={`mailto:${supportEmail}`}>
                    {supportEmail}
                  </ExternalLink>
                </>
              )}
            </p>
          </div>
          <Stack gap={2} direction="horizontal" className="justify-content-center">
            <Button variant="outline-gray" onClick={handleClose}>
              No, don't delete
            </Button>
            <ButtonSubmit loading={isLoading} variant="danger" onClick={handleDelete}>
              Yes, Delete
            </ButtonSubmit>
          </Stack>

          {site.production ? (
            <Button variant="link" size="sm" onClick={handleConvertToDev}>
              Convert to Test site instead?
            </Button>
          ) : null}
        </Stack>
      </ModalDialog>
    </>
  );
};

export default FormSiteDelete;
