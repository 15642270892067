import React from 'react';

type Props = {
  children: React.ReactNode;
  href: string;
  newTab?: boolean;
  className?: string;
};

const ExternalLink = ({ children, href, newTab = true, className, ...rest }: Props) => {
  const newTabProps = newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  return (
    <a href={href} {...newTabProps} {...rest} className={className}>
      {children}
    </a>
  );
};

export default ExternalLink;
