import React from 'react';
import { Row } from 'react-bootstrap';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  XIcon,
} from 'react-share';

const ReferralButtons = ({url, quote}:{url: string, quote: string}) => (
  <Row className="mt-4">
    <FacebookShareButton url={url} title={quote} className="d-flex justify-content-center col-4 col-sm-3 col-md-2 col-xl-1">
      <FacebookIcon size={48} round={true} />
    </FacebookShareButton>
    <TwitterShareButton url={url} title={quote} className="d-flex justify-content-center col-4 col-sm-3 col-md-2 col-xl-1">
      <XIcon size={48} round={true} />
    </TwitterShareButton>
    <LinkedinShareButton url={url} title={quote} className="d-flex justify-content-center col-4 col-sm-3 col-md-2 col-xl-1">
      <LinkedinIcon size={48} round={true} />
    </LinkedinShareButton>
  </Row>
);

export default ReferralButtons;
