export type Integration = {
  id: string;
  title: string;
  url?: string;
  subtitle?: string;
  thumb?: string;
  video?: string;
  new_window?: boolean;
};

export type IntegrationsCategory = {
  category_id: string;
  category_name: string;
  integrations?: Integration[];
};

export type IntegrationsData = {
  featured: Integration[] | [];
  integrations: IntegrationsCategory[] | [];
  categoryList: IntegrationsCategory[] | [];
};

export const fetchIntegrations = async () => {
  try {
    const res = await fetch('https://www.cloudsponge.com/wp-json/wp/v2/integrations/');
    const data = await res.json();

    const featured = data['Featured'];
    const integrations = data['Integrations'];
    const categoryList = Object.keys(integrations).map((key) => ({
      category_id: integrations[key].category_id,
      category_name: integrations[key].category_name,
    }));

    const featuredCategory = {
      category_id: 'featured',
      category_name: 'Featured',
    };

    categoryList.unshift(featuredCategory);

    return {
      featured,
      integrations,
      categoryList,
    } as {
      featured: Integration[];
      integrations: Record<string, IntegrationsCategory>;
      categoryList: IntegrationsCategory[];
    };
  } catch (error) {
    return {
      featured: [],
      integrations: {},
      categoryList: [],
    } as IntegrationsData;
  }
};
