import { track } from '../services/tracking' 

declare global {
  interface Window {
    analytics?: any;
  }
}
const serverEvents = [
    "Account activated",
    "User downloaded Proxy URL file",
    "User verified Proxy URL",
    "User added an OAuth credential",
]
type TrackArgs<T> = {
    0: T
} & Array<any>

export const trackClick = (clickEvent: Event, properties?: {[key: string]: string|boolean|unknown}) => {
  if (!clickEvent) return;
  // const eventName = clickEvent.currentTarget?.getAttribute('data-event-name') || 'generic button';
  const el = clickEvent.currentTarget as HTMLElement;
  const eventName = el?.getAttribute('data-event-name') || el?.textContent || el?.innerText || el.getAttribute('aria-label') || 'generic button';
  analytics.track(`User Clicked '${eventName}'`, properties);
};

// wraps the calls to window.analytics to be sure that the object exists
const analytics = {
    page: (...args: string[] | undefined[]) => { 
        if (window.analytics) {
            return window.analytics.page(...args)
        }
    },
    identify: (...args: [string, {[key: string]: string|boolean|unknown}]) => { 
        if (window.analytics) {
            return window.analytics.identify(...args)
        }
    },
    group: (...args: [string, {[key: string]: string|boolean|unknown}]) => { 
        if (window.analytics) {
            return window.analytics.group(...args)
        }
    },
    track: (...args: TrackArgs<string>) => {  
        if (serverEvents.indexOf(args[0]) >= 0) {
            track(...args)
        }
        else if (window.analytics) {
            return window.analytics.track(...args)
        }
    },
}

export default analytics
