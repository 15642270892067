import moment from 'moment';
import { CSVLink } from 'react-csv';
import { HiOutlineArrowUpOnSquare } from 'react-icons/hi2';
import NumberFormat from 'react-number-format';

import { DetailedMetricsType } from '../shared/types';
import EmptyChart from './EmptyChart';
import MetricsChart from './MetricsChart';
import MetricsSideMenu from './MetricsSideMenu';

const chartOptionsLines = {
  maintainAspectRatio: false,
  responsive: true,
  title: {
    display: false,
  },
  tooltips: {
    mode: 'index',
    intersect: false,
    callbacks: {
      label: (tooltipItem: any, data: any) => {
        if (tooltipItem.yLabel) {
          const { suffix, decimals } = data.datasets[tooltipItem.datasetIndex];
          const val = decimals
            ? tooltipItem.yLabel.toFixed(decimals)
            : Math.round(tooltipItem.yLabel);
          return `${val.toLocaleString()}${suffix}`;
        }
        return tooltipItem.value;
      },
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        stacked: true,

        ticks: {
          autoSkip: true,
          beginAtZero: true,
          callback: (tickValue: any) => {
            return moment(tickValue).format('MMM D');
          },
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          callback: (value: any) => value && value.toLocaleString(),
        },
      },
    ],
  },
  legend: false,
};

const chartOptionsBar = {
  maintainAspectRatio: false,
  responsive: true,
  title: {
    display: false,
  },
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        stacked: true,
        ticks: {
          beginAtZero: true,
          autoSkip: true,
          callback: (tickValue: any) => {
            if (moment(tickValue, 'Y-M-D', true).isValid()) {
              return moment(tickValue).format('MMM D');
            }

            return tickValue;
          },
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};
type DetailedMetricProps = {
  currentDetailedMetrics: DetailedMetricsType | null;
  detailedMetricsTypes: DetailedMetricsType[];
  handleChangeDetailedMetrics: (metricsId: number) => void;
};
const DetailedMetrics = ({
  currentDetailedMetrics,
  detailedMetricsTypes,
  handleChangeDetailedMetrics,
}: DetailedMetricProps) => {
  return (
    <>
      {currentDetailedMetrics && (
        <div className="row">
          <div className="col-sm-5 col-xl-3 mb-3 mb-sm-0">
            <MetricsSideMenu
              items={detailedMetricsTypes}
              selectedItem={currentDetailedMetrics}
              onClick={handleChangeDetailedMetrics}
            />
          </div>
          <div className="col-sm-7 col-xl-9 ps-xl-5">
            <div>
              <div className="pl-sm-3">
                <div className="d-flex flex-column flex-xl-row gap-2 justify-content-between mb-5">
                  <div>
                    <h4 className="h3 mb-1 fw-semibold">
                      {currentDetailedMetrics.title}:
                      <span className="fw-bold ms-1">
                        <NumberFormat
                          value={currentDetailedMetrics?.value || 0}
                          displayType={'text'}
                          thousandSeparator={true}
                          decimalScale={2}
                          suffix={currentDetailedMetrics?.suffix}
                        />
                      </span>
                    </h4>
                    {currentDetailedMetrics.infoText && (
                      <p className="text-muted fs-7 m-0">
                        {currentDetailedMetrics.infoText}
                      </p>
                    )}
                  </div>
                  <div>
                    {currentDetailedMetrics.chartData && (
                      <>
                        <CSVLink
                          data={[currentDetailedMetrics.chartData]}
                          filename={`${currentDetailedMetrics.title}.csv`}
                          target="_blank"
                          className="fs-8 text-nowrap"
                        >
                          <HiOutlineArrowUpOnSquare className="me-1" />
                          Export as CSV
                        </CSVLink>
                      </>
                    )}
                  </div>
                </div>

                <div className="chart-container">
                  {currentDetailedMetrics.chartData ? (
                    <>
                      {currentDetailedMetrics.chartType === 'line' && (
                        <MetricsChart
                          type="line"
                          data={{
                            labels: Object.keys(currentDetailedMetrics.chartData),
                            datasets: [
                              {
                                data: Object.values(currentDetailedMetrics.chartData),
                                backgroundColor: 'rgba(80,182,226,0.2)',
                                borderColor: '#50B6E2',
                                borderWidth: 2,
                                fill: true,
                                pointRadius: 2,
                                pointHoverRadius: 2,
                                suffix: currentDetailedMetrics.suffix || '',
                                decimals: currentDetailedMetrics.decimals || 0,
                              },
                            ],
                          }}
                          width={100}
                          height={60}
                          options={chartOptionsLines}
                        />
                      )}
                      {currentDetailedMetrics.chartType === 'bar' && (
                        <MetricsChart
                          type="bar"
                          data={{
                            labels: currentDetailedMetrics.chartLabels,
                            datasets: [
                              {
                                data: currentDetailedMetrics.chartData.passed.data,
                                label: 'Successful',
                                backgroundColor: '#ACD663',
                                borderColor: '#ACD663',
                                borderWidth: 1,
                              },
                              {
                                data: currentDetailedMetrics.chartData.failed.data,
                                label: 'Failed',
                                backgroundColor: '#EF6262',
                                borderColor: '#EF6262',
                                borderWidth: 1,
                              },
                              {
                                data: currentDetailedMetrics.chartData.abandoned.data,
                                label: 'Abandoned',
                                backgroundColor: '#50B6E2',
                                borderColor: '#50B6E2',
                                borderWidth: 1,
                              },
                            ],
                          }}
                          width={100}
                          // height={50}
                          options={chartOptionsBar}
                        />
                      )}
                      {currentDetailedMetrics.chartType === 'horizontalBar' && (
                        <MetricsChart
                          type="horizontalBar"
                          data={{
                            labels: currentDetailedMetrics.chartLabels,
                            datasets: [
                              {
                                data: currentDetailedMetrics.chartData.passed.data,
                                label: 'Successful',
                                backgroundColor: '#ACD663',
                                borderColor: '#ACD663',
                                borderWidth: 1,
                              },
                              {
                                data: currentDetailedMetrics.chartData.failed.data,
                                label: 'Failed',
                                backgroundColor: '#EF6262',
                                borderColor: '#EF6262',
                                borderWidth: 1,
                              },
                              {
                                data: currentDetailedMetrics.chartData.abandoned.data,
                                label: 'Abandoned',
                                backgroundColor: '#50B6E2',
                                borderColor: '#50B6E2',
                                borderWidth: 1,
                              },
                            ],
                          }}
                          width={100}
                          // height={50}
                          options={chartOptionsBar}
                        />
                      )}
                    </>
                  ) : (
                    <EmptyChart text="No data recorded" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailedMetrics;
