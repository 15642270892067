import axios from 'axios';
import { RoutePaths, publicPaths } from '../config/route-paths';

type IeScript = {
  onreadystatechange?: (() => void) | null;
  readyState: string;
} & HTMLScriptElement;

const getMetaContent = function (name: string, data?: Document) {
  return getMetaElement(name, data)?.content || '';
};

const updateMetaContent = async () => {
  // get a new session and then try again
  const { data } = await axios.get('v3/app/signin', {responseType: 'document'});
  // pull the new csrf token from the meta tag
  const csrfMatch = getMetaContent('csrf-token', data);
  if (csrfMatch) {
    return setMetaContent('csrf-token', csrfMatch);
  }
  return false;
};

const setMetaContent = function (name: string, value: string) {
  let meta = getMetaElement(name);
  if (!meta)  {
    meta = document.createElement('meta');
    meta.setAttribute('name', name);
  }
  meta.setAttribute('content', value);
  return true;
};

const getMetaElement = (name: string, data?: Document) => {
  if (!data) {
    data = document;
  }
  const matchingElement = data.head.querySelector(`meta[name=${name}]`) as HTMLMetaElement;
  return matchingElement;
};

export const addJavascript = (src: string, opts: {type? : string, id? : string}, callback? : VoidFunction) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.defer = true;
  script.id = opts.id || '';
  script.type = opts.type || 'text/javascript';
  if (callback) {
    if (script.hasOwnProperty('readyState')) {
      // IE7+
      const ieScript = script as IeScript;
      ieScript.onreadystatechange = () => {
        if (ieScript.readyState == 'loaded' || ieScript.readyState == 'complete') {
          ieScript.onreadystatechange = null;
          callback();
        }
      };
    }
    else {
      script.onload = () => {
        // Other browsers
        callback();
      };
    }
  }
  document.body.appendChild(script);
};

export const addStylesheet = (src: string) => {
  const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = src;
    document.head.appendChild(link);
    return link;
};

// capitalize the first letter of a string
export const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

// hide the secret value of a string by turning the characters after the first 3 into *
export const hideSecret = (secret: string) => {
  const firstThree = secret.slice(0, 3);
  const rest = secret.slice(3);
  return firstThree + rest.replace(/./g, '*');
};

export const relativeDate = (date: string) => {
  const format = (amount: number, unit: any) => {
    const sign = Math.sign(amount);
    const abs = Math.abs(amount);
    const floor = Math.floor(abs);

    return formatter.format(sign * floor, unit);
  };

  // turn the date into a relative time in days
  const formatter = new Intl.RelativeTimeFormat('en-US', {numeric: 'auto'});

  const diff = Date.parse(date) - (new Date() as any);
  
  const offsetDays = new Date().getTimezoneOffset() / (24 * 60);
  const days = (diff / (24 * 60 * 60 * 1000)) + offsetDays;
  const weeks = days / 7;
  if (Math.abs(weeks) < 1) {
    return format(days, 'day');
  }

  const months = days / 30;
  if (Math.abs(months) < 1) {
    return format(weeks, 'week');
  }

  const years = days / 365;
  if (Math.abs(years) < 1) {
    return format(months, 'month');
  }
  return format(years, 'year');
};

export function param(params = {}) {
  const p = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    p.set(key, String(value));
  }
  return p.toString();
}

// add interceptors to axios
// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (config.method !== 'get') {
    config.headers['X-CSRF-Token'] = getMetaContent('csrf-token');
  }
  return config;
});

// Add a response interceptor
axios.interceptors.response.use(undefined, async function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // if there was a authenticity token failure, get a new one and retry the request
  if (error?.response?.status === 422 && await updateMetaContent()) {
    // retry the original request
    return axios.request(error.config);
  } else if (error?.response.status === 401 && !isPublicPath()) {
    // redirect to signin page if we were previously signed in
    window.location.href = RoutePaths.BASE + RoutePaths.SIGNIN;
  }
  return Promise.reject(error);
});

const isPublicPath = () => {
  const publicRegex = new RegExp(`^${RoutePaths.BASE}(${publicPaths.join('|')})`);
  return publicRegex.test(window.location.pathname);
}

export { axios };
