import { axios } from '../utils/utils';
import Cleave from 'cleave.js/react';
import { Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { Button, Col, Collapse, Form, Row } from 'react-bootstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import * as yup from 'yup';

import { useAuth } from '../contexts/authContext';
import { Product, SubscriptionSecure } from '../shared/types';
import ButtonSubmit from './ButtonSubmit';
import analytics from '../libs/analytics';

type Props = {
  product: Product;
  hasChargify: boolean;
  secureParams?: SubscriptionSecure;
};

const FormCreditCard = ({ product, hasChargify }: Props) => {
  const [showMoreAddress, setShowMoreAddress] = useState(false);

  const { account, secureParams } = useAuth();

  const signupUrl = hasChargify
    ? `https://api.chargify.com/api/v2/subscriptions/${account?.subscription.subscription_id}/card_update`
    : 'https://api.chargify.com/api/v2/signups';

  const fieldPrefix = hasChargify ? 'payment_profile' : 'signup[payment_profile]';
  const ctaLabel = hasChargify ? 'Add your credit card' : `Start ${product.name} Trial`;

  const schema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    card_number: yup.string().required('Card number is required'),
    // .min(16, 'Card number must be 16 digits'),
    cvv: yup.string().required('CVV is required').min(3, 'CVV must be 3 digits'),
    formatted_exp: yup.string().required('Expiration date is required'),
    billing_address: yup.string().required('Address is required'),
    billing_city: yup.string().required('City is required'),
    billing_state: yup.string().required('State is required'),
    billing_country: yup.string().required('Country is required'),
    billing_zip: yup.string().required('Zip/postal code is required'),
  });

  const handleSubmit = async (values: any) => {
    analytics.track("User submitted payment method", {
      path: location.pathname,
      plan: values.handle,
      name: values.name,
    });

    let params = {} as any;

    Object.keys(values).forEach((key) => {
      params[`${fieldPrefix}[${key}]`] = values[key];
    });

    const secureParamsData = {
      'secure[api_id]': secureParams?.secure?.api_id,
      'secure[timestamp]': secureParams?.secure?.timestamp,
      'secure[nonce]': secureParams?.secure?.nonce,
      'secure[data]': secureParams?.secure?.data,
      'secure[signature]': secureParams?.secure?.signature,
    };

    const productParamsData = hasChargify
      ? {
          'product[handle]': product.handle,
          product_price_point_id: product.price_point,
        }
      : {
          'signup[product][handle]': product.handle,
          'signup[product][price_point_id]': product.price_point,
        };

    params = {
      ...params,
      ...secureParamsData,
      ...productParamsData,
    };

    // create new form with params
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = signupUrl;

    const formData = new FormData();

    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });

    // add hidden inputs to form
    for (const [key, value] of formData) {
      const input = document.createElement('input');

      input.type = 'hidden';
      input.name = key;
      input.value = value as string;
      form.appendChild(input);
    }

    document.body.appendChild(form);

    // submit form
    form.submit();
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        first_name: '',
        last_name: '',
        card_number: '',
        cvv: '',
        expiration_month: '',
        expiration_year: '',
        billing_address: '',
        billing_address_2: '',
        billing_city: '',
        billing_state: '',
        billing_country: '',
        billing_zip: '',
        formatted_exp: '',
        ...secureParams.payment_profile,
      }}
      initialErrors={secureParams.errors}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row xs={1} sm={2}>
            <Form.Group as={Col} className="mb-3" controlId="first_name">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={values.first_name}
                placeholder=""
                autoComplete="given-name"
                onChange={handleChange}
                isInvalid={touched.first_name && !!errors.first_name}
              />
              {errors.first_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.first_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="last_name">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={values.last_name}
                placeholder=""
                autoComplete="family-name"
                onChange={handleChange}
                isInvalid={touched.last_name && !!errors.last_name}
              />
              {errors.last_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.last_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>
          <Row xs={1} sm={2}>
            <Col>
              <Form.Group className="mb-3" controlId="card_number">
                <Form.Label>Credit card number</Form.Label>
                <Cleave
                  placeholder="**** **** **** ****"
                  value={values.card_number}
                  options={{ creditCard: true }}
                  autoComplete="cc-number"
                  inputMode="numeric"
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('card_number', e.target.rawValue);
                  }}
                  className={`form-control inspectletIgnore ${
                    !!errors.full_number || touched.card_number && !!errors.card_number ? 'is-invalid' : ''
                  }`}
                  id="card_number"
                />
                {errors.card_number && (
                  <Form.Control.Feedback type="invalid">
                    {errors.card_number}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="cvv">
                  <Form.Label>CVV</Form.Label>
                  <Cleave
                    placeholder="***"
                    value={values.cvv}
                    autoComplete="cc-csc"
                    inputMode="numeric"
                    onChange={handleChange}
                    id="cvv"
                    options={{ blocks: [3], numericOnly: true }}
                    className={`form-control inspectletIgnore ${
                      touched.cvv && !!errors.cvv ? 'is-invalid' : ''
                    }`}
                  />

                  {errors.cvv && (
                    <Form.Control.Feedback type="invalid">
                      {errors.cvv}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} controlId="formatted_exp">
                  <Form.Label>Expiry</Form.Label>
                  <Cleave
                    placeholder="MM/YY"
                    autoComplete="cc-exp"
                    inputMode="numeric"
                    value={values.formatted_exp}
                    id="formatted_exp"
                    options={{ date: true, datePattern: ['m', 'y'] }}
                    onChange={(e: any) => {
                      handleChange(e);
                      setFieldValue('expiration_month', e.target.rawValue.slice(0, 2));
                      setFieldValue('expiration_year', e.target.rawValue.slice(2, 4));
                    }}
                    className={`form-control ${
                      // touched.formatted_exp && !!errors.formatted_exp ? 'is-invalid' : ''
                      errors.expiration ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.expiration && (
                    <Form.Control.Feedback type="invalid">
                      {errors.expiration}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="billing_address">
            <Form.Label>Street Address</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={values.billing_address}
              autoComplete="address-line1"
              onChange={handleChange}
              isInvalid={touched.billing_address && !!errors.billing_address}
            />
            {errors.billing_address && (
              <Form.Control.Feedback type="invalid">
                {errors.billing_address}
              </Form.Control.Feedback>
            )}
            <Form.Text className="text-muted">
              <Button
                variant="link"
                className="p-0"
                size="sm"
                onClick={() => {
                  setFieldValue('billing_address_2', '', false);
                  setShowMoreAddress(!showMoreAddress);
                }}
              >
                {showMoreAddress ? 'Hide' : 'Show'} more address
              </Button>
            </Form.Text>
          </Form.Group>
          <Collapse in={showMoreAddress}>
            <Form.Group className="mb-3" controlId="billing_address_2">
              <Form.Label>Address (line 2)</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={values.billing_address_2}
                autoComplete="address-line2"
                onChange={handleChange}
                key={showMoreAddress ? 'show' : 'hide'} // force re-render
                isInvalid={touched.billing_address_2 && !!errors.billing_address_2}
              />
              {errors.billing_address_2 && (
                <Form.Control.Feedback type="invalid">
                  {errors.billing_address_2}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Collapse>

          <Form.Group className="mb-3" controlId="billing_country">
            <Form.Label>Country</Form.Label>
            <CountryDropdown
              valueType="short"
              classes={`form-select ${
                touched.billing_country && !!errors.billing_country ? 'is-invalid' : ''
              }`}
              id="billing_country"
              name={`billing_country`}
              value={values.billing_country}
              onChange={(val) => setFieldValue('billing_country', val)}
            />
            {errors.billing_country && (
              <Form.Control.Feedback type="invalid">
                {errors.billing_country}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Collapse in={!!values.billing_country}>
            <Row xs={1} sm={2}>
              <Col>
                <Form.Group className="mb-3" controlId="billing_state">
                  <Form.Label>State</Form.Label>
                  <RegionDropdown
                    countryValueType="short"
                    country={values.billing_country}
                    valueType="short"
                    value={values.billing_state}
                    classes={`form-select ${
                      touched.billing_state && !!errors.billing_state ? 'is-invalid' : ''
                    }`}
                    id="billing_state"
                    name={`billing_state`}
                    onChange={(val) => setFieldValue('billing_state', val)}
                  />
                  {errors.billing_state && (
                    <Form.Control.Feedback type="invalid">
                      {errors.billing_state}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Row xs={1} sm={2}>
                  <Col>
                    <Form.Group className="mb-3" controlId="billing_city">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={values.billing_city}
                        autoComplete="address-level2"
                        onChange={handleChange}
                        isInvalid={touched.billing_city && !!errors.billing_city}
                      />
                      {errors.billing_city && (
                        <Form.Control.Feedback type="invalid">
                          {errors.billing_city}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="billing_zip">
                      <Form.Label>Zip/Postal code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={values.billing_zip}
                        autoComplete="postal-code"
                        inputMode="numeric"
                        onChange={handleChange}
                        isInvalid={touched.billing_zip && !!errors.billing_zip}
                      />
                      {errors.billing_zip && (
                        <Form.Control.Feedback type="invalid">
                          {errors.billing_zip}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Collapse>

          <p className="mt-4">
            <ButtonSubmit className="w-100" type="submit">
              {ctaLabel}
            </ButtonSubmit>
          </p>
        </Form>
      )}
    </Formik>
  );
};

export default FormCreditCard;
