import React from 'react';
import { Stack } from 'react-bootstrap';
import { Params, useMatches } from 'react-router-dom';

// https://reactrouter.com/en/main/hooks/use-matches

type MathchesWithCrumb = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: {
    crumb: (data: unknown) => React.ReactNode;
  };
};

const Breadcrumbs = () => {
  const matches = useMatches() as MathchesWithCrumb[];
  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data));

  return crumbs && crumbs.length > 0 ? (
    <Stack direction="horizontal" gap={1}>
      {crumbs.map((crumb, index) => (
        <Stack direction="horizontal" gap={1} key={index} as="span">
          <span>{crumb}</span>
          {index < crumbs.length - 1 ? <span className="sep">|</span> : null}
        </Stack>
      ))}
    </Stack>
  ) : null;
};

export default Breadcrumbs;
