import React, { useEffect } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';

import PageHeader from '../components/PageHeader';
import PageSection from '../components/PageSection';
import Page from '../components/Page';
import ContentCard from '../components/ContentCard';
import { addJavascript, addStylesheet } from '../utils/utils';

const Zapier = () => {
  useEffect(() => {
    addJavascript("https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js", {type: "module"});
    addStylesheet("https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css");
  }, []);

  return (
    <Page size="xl">
      <PageHeader>
        <h1 className="mb-4">Zapier connects CloudSponge to all the software you rely on</h1>
        <Stack gap={2} direction="horizontal">
          <Stack gap={2} direction="vertical">
            <p>
              <svg aria-hidden="true" viewBox="0 0 22 22" width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#000" d="M0 11.25C0 5.3375 4.8375 0.5 10.75 0.5C16.6625 0.5 21.5 5.3375 21.5 11.25C21.5 17.1625 16.6625 22 10.75 22C4.8375 22 0 17.1625 0 11.25ZM9.675 12.325L15.265 15.765L16.125 14.3675L11.2875 11.465V5.875H9.675V12.325Z"></path></svg>
              &nbsp;Automate repetitive tasks without writing code.
            </p>
            <p>
              <svg aria-hidden="true" viewBox="0 0 20 22" width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#000" d="M13.002 2.2H17.6C18.81 2.2 19.8 3.19 19.8 4.4V19.8C19.8 21.01 18.81 22 17.6 22H2.2C2.046 22 1.903 21.989 1.76 21.967C1.331 21.879 0.946 21.659 0.649 21.362C0.451 21.153 0.286 20.922 0.176 20.658C0.0660001 20.394 0 20.097 0 19.8V4.4C0 4.092 0.0660001 3.806 0.176 3.553C0.286 3.289 0.451 3.047 0.649 2.849C0.946 2.552 1.331 2.332 1.76 2.244C1.903 2.211 2.046 2.2 2.2 2.2H6.798C7.26 0.924 8.47 0 9.9 0C11.33 0 12.54 0.924 13.002 2.2ZM7.249 16.049L8.8 14.487L6.413 12.1L8.8 9.713L7.249 8.151L3.3 12.1L7.249 16.049ZM11 14.487L12.551 16.049L16.5 12.1L12.551 8.151L11 9.713L13.387 12.1L11 14.487ZM9.89995 3.5748C10.3556 3.5748 10.725 3.20544 10.725 2.7498C10.725 2.29417 10.3556 1.9248 9.89995 1.9248C9.44432 1.9248 9.07495 2.29417 9.07495 2.7498C9.07495 3.20544 9.44432 3.5748 9.89995 3.5748Z"></path></svg>
              &nbsp;Connect 7,000+ apps you already use.
            </p>
          </Stack>
          <Stack gap={2} direction='vertical'>
            <p>
              <svg aria-hidden="true" viewBox="0 0 15 27" width="22" height="27" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#000" d="M0.562312 12.5279C-0.264398 13.4917 0.0922429 15.4744 1.36839 15.5412L5.54577 15.7598L1.8701 23.9981C0.321109 27.4699 2.28155 28.0131 4.84922 25.1937L14.5335 14.4474C15.3836 13.5139 15.0376 11.555 13.7743 11.4885L9.44802 11.2609L12.2168 3.30871C13.2589 0.315599 11.2891 -0.167635 9.21932 2.24535L0.562312 12.5279Z"></path></svg>
              &nbsp;Build custom workflows to save time.
            </p>
            <p>
              <svg aria-hidden="true" viewBox="0 0 21 22" width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#000" d="M11.804 3.20213C11.804 3.38258 11.7988 3.55197 11.7906 3.7091C12.227 3.66338 12.6702 3.57727 13.0503 3.43623C13.6742 3.20467 13.8233 2.95515 13.8669 2.78077C13.9293 2.53107 13.8711 2.32937 13.6723 2.12411C13.4337 1.87762 13.0789 1.7383 12.8095 1.7383C12.4963 1.7383 11.804 2.1321 11.804 3.20213ZM14.5979 4.57447H18.2979C19.5559 4.57447 20.5851 5.60372 20.5851 6.8617V8.23404H0V6.8617C0 6.54149 0.0686171 6.24415 0.182979 5.98112C0.29734 5.70665 0.468883 5.45505 0.674734 5.2492C0.983511 4.94043 1.38378 4.7117 1.82979 4.62021C1.97846 4.5859 2.12713 4.57447 2.28723 4.57447L5.60618 4.57451C5.14908 4.24257 4.79932 3.79692 4.65073 3.20236C4.19342 1.37258 5.87956 0.000236683 7.39541 0.000236683C8.75688 0.000236683 9.88683 1.15675 10.1028 2.67365C10.3187 1.15663 11.4484 0 12.8095 0C14.3249 0 16.0105 1.37234 15.5534 3.20213C15.4048 3.7968 15.055 4.2425 14.5979 4.57447ZM8.41512 3.7094C8.40696 3.55225 8.40179 3.38284 8.40179 3.20236C8.40179 2.13287 7.70948 1.73853 7.39541 1.73853C7.12563 1.73853 6.77054 1.878 6.53175 2.12453C6.33289 2.32984 6.27481 2.53142 6.33715 2.78089C6.3807 2.95512 6.52984 3.20476 7.15423 3.43641C7.53464 3.57755 7.97838 3.66369 8.41512 3.7094ZM0 10.0638V19.2128C0 19.5215 0.0686171 19.8303 0.182979 20.1048C0.29734 20.3793 0.468883 20.6194 0.674734 20.8367C0.983511 21.1455 1.38378 21.3742 1.82979 21.4657C1.97846 21.4886 2.12713 21.5 2.28723 21.5H9.14894V10.0638H0ZM11.4362 21.5H18.2979C19.5559 21.5 20.5851 20.4707 20.5851 19.2128V10.0638H11.4362V21.5Z"></path></svg>
              &nbsp;Free forever for core features. 14-day free trial for premium features.
            </p>
          </Stack>
        </Stack>
      </PageHeader>

      <PageSection className="my-lg-auto">
        <ContentCard className="mt-0">
          <zapier-full-experience
            client-id="HxLdplhBZBijHh704XHvRPqhwk0TGbt1YDXXSxTf"
            theme="light"
            app-search-bar-display="show"
            intro-copy-display="hide"
          ></zapier-full-experience>
        </ContentCard>
      </PageSection>
    </Page>
  );
}

export default Zapier;
