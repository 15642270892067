import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { resendConfirmationEmail } from '../services/user';
import ButtonSubmit from './ButtonSubmit';

type FormValues = {
  email: string;
};

type Props = {
  email?: string;
};

const FormResendConfirmationEmail = ({ email }: Props) => {
  const handleSubmit = async (values: FormValues) => {
    try {
      await resendConfirmationEmail(values.email);
      toast(
        "We'll send you another confirmation link, if we find an account matching your email address.",
        {
          type: 'success',
        },
      );
    } catch (error: any) {
      console.log(error);
      toast(
        'Something went wrong when requesting a new confirmation email. Wait a bit before trying again. If the problem persists contact us to help sort it out.',
        {
          type: 'error',
        },
      );
    }
  };

  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  return (
    <div>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          email: email || '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label className="fs-7">
                You can request a new confirmation email by entering your email address
                below.
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={touched.email && !!errors.email}
                placeholder="Your Work Email"
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <p className="mt-4">
              <ButtonSubmit className="w-100" type="submit">
                Resend a Confirmation Email
              </ButtonSubmit>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormResendConfirmationEmail;
