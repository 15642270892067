import React from 'react';
import { Stack } from 'react-bootstrap';
import ButtonCopy from './ButtonCopy';
import { ApiKey } from '../shared/types';
import { hideSecret } from '../utils/utils';

type Props = {
  projectKey: string;
  keys: ApiKey[];
};

const ProjectBarRestKey = ({ projectKey, keys }: Props) => {
  const key = keys.find((k: ApiKey) => k.key === projectKey) as ApiKey;
  return (
    <Stack direction="horizontal" gap={1}>
      <span className="text-muted">REST API key: <code>{key.key}</code></span>
      <ButtonCopy text={key.key} />
      <span className="text-muted">REST API password: <code>{hideSecret(key.password)}</code></span>
      <ButtonCopy text={key.password} />
  </Stack>
  );
};

export default ProjectBarRestKey;
