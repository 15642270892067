import React from 'react';
import ZapierLogo from '~/customer/src/assets/images/zapier-logo.png';
import LogoImage from '../assets/images/logo.svg';
import { Link, useParams } from 'react-router-dom';
import { RoutePaths } from '../config/route-paths';

const NavigationZapier = () => {
  const { projectKey } = useParams();
  const targetPath = `${RoutePaths.PROJECTS}/${projectKey}/${RoutePaths.ZAPIER}`;
  const isActive = window.location.pathname.includes(targetPath);

  return (
    <nav className="navigation-callout">
      <div className={isActive ? "navigation-callout__content is-active" : "navigation-callout__content"}>
        <span className="navigation-callout__header">
          <img src={ZapierLogo} alt="Zapier" height="32px" /> + <img src={LogoImage} alt="CloudSponge" height="32px" />
        </span>
        <div className="navigation-callout__text">
          <div>Install our App to Connect with</div>
          <div>1000's of Apps on Zapier.</div>
          <Link to={targetPath} className="btn btn-primary">Connect Now</Link>
        </div>
      </div>
    </nav>
  );
};

export default NavigationZapier;
