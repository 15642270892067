// import { updateUser } from 'customer/api';
import React from 'react';
import { useFetcher } from 'react-router-dom';

import { User } from '../shared/types';

type UserOwnerRadioProps = {
  me: User;
  id?: string;
  user: User;
  onComplete?: any;
  onError: any;
  index: number;
};
const UserOwnerRadio = ({
  me,
  id,
  user,
  onComplete,
  onError,
  index,
}: UserOwnerRadioProps) => {
  const fetcher = useFetcher();
  const updateForm = ({ id, is_owner }: Record<string, string | Blob>) => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('user', is_owner);
    formData.append('action', 'toggleOwner');
    fetcher.submit(formData, {
      method: 'post',
    });
  };

  return (
    <form>
      <div className="d-flex justify-content-sm-center gap-2">
        <input name="id" type="hidden" />
        <input
          disabled={!(me.is_administrator || me.owner)}
          checked={user.owner}
          name="owner"
          id={`owner-${index}`}
          type="radio"
          className="form-check-input position-static"
          onChange={() => {
            updateForm({
              id: user.id,
              is_owner: !user.is_owner ? 'true' : 'false',
            });
          }}
        />
        <label htmlFor={`owner-${index}`} className="form-check-label d-sm-none">
          Owner
        </label>
      </div>
    </form>
  );
};

export default UserOwnerRadio;
