import { axios } from '../utils/utils';
import config from '../config/config';
import { SubscriptionSecure } from '../shared/types';
import analytics from '../libs/analytics';

export const getSecureParams = async (
  queryParams: Record<string, string | null | any>,
) => {
  try {
    const response = await axios.get(`${config.DATA_PATH}/subscriptions/new`, {
      params: queryParams,
    });
    return response.data as {
      secure: SubscriptionSecure;
    };
  } catch (error) {
    throw new Error('Something went wrong');
  }
};

export const updateProduct = async (product_handle: string, price_point: string) => {
  try {
    const response = await axios.put(`${config.DATA_PATH}/subscriptions/_`, {
      product_handle,
      price_point_handle: price_point,
    });
    // Track the event
    analytics.track("User picked a plan", {
      product_handle,
      price_point,
    });
    return response.data as any;
  } catch (error) {
    throw new Error('Something went wrong');
  }
};
