import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import Loading from './Loading';

interface IButtonIcon extends ButtonProps {
  loading?: boolean;
}

const ButtonIcon = forwardRef<HTMLButtonElement, IButtonIcon>(
  ({ loading, children, className, variant = 'link', ...props }, ref) => {
    return (
      <Button
        ref={ref}
        className={classNames(className, 'btn-loading', 'btn-icon', {
          'is-loading': loading,
        })}
        {...props}
        variant={variant}
      >
        <span className="btn-loading__content">{children}</span>
        {loading ? <Loading size="sm" /> : null}
      </Button>
    );
  },
);

ButtonIcon.displayName = 'ButtonIcon';

export default ButtonIcon;
