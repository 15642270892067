import React from 'react';
import { Link } from 'react-router-dom';

import LogoImage from '../assets/images/logo.svg';
import { RoutePaths } from '../config/route-paths';
import { useAuth } from '../contexts/authContext';

const Logo = ({ size = 160 }) => {
  const { isAuthed } = useAuth();
  return (
    <Link to={isAuthed ? RoutePaths.PROJECTS : RoutePaths.SIGNIN}>
      <img src={LogoImage} alt="CloudSponge" width={size} />
    </Link>
  );
};

export default Logo;
