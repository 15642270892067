import React from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  show: boolean;
  handleClose?: () => void;
  children?: React.ReactNode;
};

const ModalDialog = ({ show, handleClose, children }: Props) => {
  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header></Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ModalDialog;
