import React from 'react';
import { IoPlayCircle } from 'react-icons/io5';

import { Integration } from '../services/integrations';
import ContentCard from './ContentCard';
import ExternalLink from './ExternalLink';

const InstallListCard = ({ integration }: { integration: Integration }) => {
  return (
    <ContentCard key={integration.id}>
      <div className="d-flex flex-wrap flex-sm-nowrap gap-4 fs-7 position-relative align-items-lg-stretch">
        <a
          href={integration.url}
          target={integration.new_window ? '_blank' : '_self'}
          rel={integration.new_window ? 'noopener noreferrer' : ''}
          className="position-absolute top-0 start-0 w-100 h-100"
        >
          <span className="visually-hidden">{integration.title}</span>
        </a>
        <div className="d-flex gap-4 w-100">
          <div className="flex-flex-shrink-0">
            <img src={integration.thumb} alt={integration.title} width="72" />
          </div>
          <div>
            <h3 dangerouslySetInnerHTML={{ __html: integration.title }} />
            {integration.subtitle && (
              <div dangerouslySetInnerHTML={{ __html: integration.subtitle }} />
            )}
          </div>
        </div>
        {integration.video && (
          <div className="flex-grow-1 position-relative">
            <div className="integration-video-button">
              <ExternalLink
                newTab
                href={integration.video}
                className="d-flex align-items-center gap-2 text-dark text-decoration-none fw-bold"
              >
                <IoPlayCircle className="icon icon--lg text-danger" />
                <span className="d-sm-none">View video</span>
              </ExternalLink>
            </div>
          </div>
        )}
      </div>
    </ContentCard>
  );
};

export default InstallListCard;
