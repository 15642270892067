import React from 'react';
import ModalDialog from './ModalDialog';
import { useNavigate } from 'react-router-dom';
import NavigationSocial from './NavigationSocial';
import { capitalize, relativeDate } from '../utils/utils';
import { Badge, Button, Stack } from 'react-bootstrap';
import { HiOutlineExclamationTriangle, HiOutlineQuestionMarkCircle } from 'react-icons/hi2';
import { Site } from '../shared/types';

const SitesLastLoadPill = ({ site }: {site: Site}) => {
  const navigate = useNavigate();
  const [installModalOpen, setInstallModalOpen] = React.useState(false);
  if (!site.last_loaded_on) {
    return (
      <>
        <ModalDialog show={installModalOpen}>
          <Stack gap={2} className="align-items-center text-center">
            <span>
              <HiOutlineExclamationTriangle className="icon icon--stroke icon--xl text-danger" />
            </span>
            <div>
              <h3>We haven't seen any activity on <code>{site.name}</code> yet...</h3>
              <p className="fs-7">
                Usually this means that you haven't installed the Contact Picker. The next step is to install the Contact Picker on your site.
              </p>
              <Button onClick={() => navigate(`/projects/${site.key}/install`)}>Installation Options</Button>
            </div>

            <div className="mt-5">
              <h3>It's OK if the choice is not obvious.</h3>
              <p className="fs-7">
                If you aren't sure how to get started, reach out. We're approchable and helpful, don't be shy. We've done it before.
              </p>
              <NavigationSocial />
            </div>

            <div className="w-50">
              <Button variant="link" className="w-100" onClick={() => setInstallModalOpen(false)}>
                Close
              </Button>
            </div>
          </Stack>
        </ModalDialog>
        <span className="fs-7 me-3">
          <span className="text-muted">Last activity:</span>
          <Button variant="link" className="p-0 ms-1" onClick={() => setInstallModalOpen(true)}>
            <Badge bg="danger">
              Not Installed{' '}
              <HiOutlineQuestionMarkCircle className="icon icon--stroke icon--sm" />
            </Badge>
            
          </Button>
        </span>
      </>
    );
  }

  return <span className="fs-7 me-3"><span className="text-muted">Last activity:</span> <strong>{capitalize(relativeDate(site.last_loaded_on))}</strong></span>;
}

export default SitesLastLoadPill;
