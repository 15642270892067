import React, { useRef } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { FiCopy } from 'react-icons/fi';

import useCopyToClipboard from '../hooks/useCopyToClipboard';
import ButtonIcon from './ButtonIcon';

type Props = {
  text: string;
};

const ButtonCopy = ({ text }: Props) => {
  const [value, copy] = useCopyToClipboard();
  const target = useRef(null);
  return (
    <>
      <span ref={target}>
        <ButtonIcon onClick={() => copy(text)}>
          <FiCopy className="icon icon--sm icon--stroke" />
        </ButtonIcon>
      </span>
      <Overlay target={target.current} show={!!value} placement="left">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Copied!
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default ButtonCopy;
