import { axios } from '../utils/utils';
import config from "../config/config";


export const track = (...args: unknown[]) => {
  const data = {
    event: args[0],
    properties: args[1] || {},
  };
  console.log('Segment track: ', data);
  return axios.post(
    `${config.DATA_PATH}/users/track`,
    { data },
  );
};