import React, { useMemo } from 'react';

import { useAuth } from '../contexts/authContext';
import { updateProduct } from '../services/payment';
import { Product } from '../shared/types';
import Loading from './Loading';
import PageSection from './PageSection';
import PlanPickerItem from './PlanPickerItem';
import SubscriptionModal from './SubscriptionModal';
import analytics from '../libs/analytics';

const plans = {
  launch: {
    name: 'Launch',
    features: [
      'Universal Contact Picker',
      '50 New Contact Lists per Month',
      '5 Selected Contacts in the Contact Picker',
    ],
    price: '99',
    handle: 'gated_launch',
    price_points: {
      yearly: { handle: 'launch_99_yearly', price: '$99/year' },
    },
  },
  basic: {
    name: 'Growth',
    features: [
      'Everything in Launch',
      'Unlimited New Contact Lists',
      'Unlimited Selected Contacts in the Contact Picker',
      'Privacy Compliance Service included (Limited time only)',
    ],
    price: '69',
    handle: 'gated_basic',
    price_points: {
      monthly: { handle: 'growth_69_monthly', price: '$69/month' },
      yearly: { handle: 'growth_69_yearly', price: '$759/year' },
    },
  },
  premium: {
    name: 'Branded',
    features: [
      'Everything in Growth',
      'Custom Stylesheets and Fonts',
      'Custom Languages and Labels',
      'Your Branding',
    ],
    price: '189',
    handle: 'gated_premium',
    price_points: {
      monthly: { handle: 'branded_189_monthly', price: '$189/month' },
      yearly: { handle: 'branded_189_yearly', price: '$2,079/year' },
    },
  },
};

type Props = {
  title: string;
  isGauntlet?: boolean;
};

const PlanPicker = ({ title = 'Choose your Plan', isGauntlet }: Props) => {
  const { account, fetchAccount, user, secureParams } = useAuth();

  const [loading, setLoading] = React.useState(false);
  // initialize the modal to be open if the location includes open=subscription
  const [showCreditCardModal, setShowCreditCardModal] = React.useState(!!secureParams.errors);
  const [product, setProduct] = React.useState<Product>(secureParams.product as Product);

  const hasChargify = !!account?.subscription.customer_id;

  const handleClick = async (handle: string, name: string, price_point: string) => {
    try {
      setLoading(true);
      await updateProduct(handle, price_point);
      setProduct({ handle, name, price_point });
      setShowCreditCardModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShowCreditCardModal(false);
  };

  return (
    <>
      <PageSection className="my-lg-auto">
        <h1 className={isGauntlet ? 'mb-4 h2 text-center' : 'mb-4 mb-sm-5 text-center'}>
          {title}
        </h1>

        <div className="plan-picker">
          {loading && <Loading position="overlay" />}
          <PlanPickerItem
            {...plans.launch}
            updatePlan={handleClick}
            subscription={account?.subscription}
            loading={loading}
          />
          <PlanPickerItem
            {...plans.basic}
            updatePlan={handleClick}
            subscription={account?.subscription}
            loading={loading}
          />
          <PlanPickerItem
            {...plans.premium}
            updatePlan={handleClick}
            subscription={account?.subscription}
            loading={loading}
          />
        </div>
        {!isGauntlet && (
        <div>
          <p className="text-center mt-5">
            Need help deciding? Just{' '}
            <a href="/contact">reach out</a>. We're happy to help.
          </p>
        </div>
        )}
      </PageSection>

      {product && account && (
        <SubscriptionModal
          show={showCreditCardModal}
          handleClose={handleClose}
          product={product}
          hasChargify={hasChargify}
          secureParams={secureParams}
        />
      )}
    </>
  );
};

export default PlanPicker;
