import { Suspense } from 'react';
import React from 'react';
import { Button, Stack } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { LinkContainer } from 'react-router-bootstrap';
import { Await, useLoaderData } from 'react-router-dom';

import Loading from '../components/Loading';
import Page from '../components/Page';
import PageHeader from '../components/PageHeader';
import PageSection from '../components/PageSection';
import ProjectList from '../components/ProjectList';
import { RoutePaths } from '../config/route-paths';
import { useAuth } from '../contexts/authContext';
import { Project } from '../services/projects';

const MyProjects = () => {
  const { user } = useAuth();
  const { data } = useLoaderData() as { data: Project[] };

  return (
    <>
      <Helmet>
        <title>My Projects</title>
      </Helmet>
      <Page size="lg">
        <PageHeader>
          <Stack
            gap={2}
            direction="horizontal"
            className="align-items-center justify-content-between"
          >
            <h1>My Projects</h1>
            <LinkContainer to={`${RoutePaths.NEW_PROJECT}`}>
              <Button>New Project</Button>
            </LinkContainer>
          </Stack>
        </PageHeader>
        <PageSection>
          <Suspense>
            <Await resolve={data} errorElement={<div>Could not load projects 😬</div>}>
              {(data) => <ProjectList data={data} />}
            </Await>
          </Suspense>
        </PageSection>
      </Page>
    </>
  );
};

export default MyProjects;
