import { Suspense } from 'react';
import React from 'react';
import { Container, Stack } from 'react-bootstrap';
import { HiArrowLeft } from 'react-icons/hi2';
import { Await, Link, useLoaderData, useRouteLoaderData } from 'react-router-dom';

import { RoutePaths } from '../config/route-paths';
import { ApiKey, Project } from '../shared/types';
import Breadcrumbs from './Breadcrumbs';
import Loading from './Loading';
import ProjectBarKey from './ProjectBarKey';
import ProjectBarRestKey from './ProjectBarRestKey';

const ProjectBar = () => {
  const { data } = useRouteLoaderData('project') as { data: Project };
  return (
    <div className="project-bar">
      <Container fluid>
        <Stack direction="horizontal" gap={2} className="align-items-center fs-8">
          <div>
            <Link to={`${RoutePaths.PROJECTS}`} className="link-dark">
              <HiArrowLeft className="icon icon--sm" />
            </Link>
          </div>
          <Suspense fallback={<Loading size="sm" />}>
            <Await resolve={data}>
              {(data) => <span className="project-bar__name">{data.name}</span>}
            </Await>
          </Suspense>

          <div className="d-none d-lg-block">
            <Breadcrumbs />
          </div>
          <div className="ms-auto">
            <Suspense fallback={<Loading size="sm" />}>
              <Await resolve={data}>
                {data => data.type === 'project' ? 
                  <ProjectBarKey projectKey={data.key} keys={data.keys} /> : 
                  <ProjectBarRestKey projectKey={data.key} keys={data.keys} />
                }
              </Await>
            </Suspense>
          </div>
        </Stack>
      </Container>
    </div>
  );
};

export default ProjectBar;
