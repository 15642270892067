import { useFetcher } from 'react-router-dom';

import { User } from '../shared/types';

type UserAdminCheckboxType = {
  me: User;
  user: User;
  onError: (user: User) => void;
};
const UserAdminCheckbox = ({ me, user }: UserAdminCheckboxType) => {
  const fetcher = useFetcher();
  const updateForm = ({ id, is_administrator }: Record<string, string | Blob>) => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('user', is_administrator);
    formData.append('action', 'toggleAdmin');
    fetcher.submit(formData, {
      method: 'post',
    });
  };
  return (
    <form>
      <div className="d-flex justify-content-sm-center gap-2">
        <input name="id" type="hidden" />
        <input
          disabled={!(me.is_administrator || me.owner)} //@TODO check
          checked={user.is_administrator}
          name="is_administrator"
          id={`admin-${user.id}`}
          type="checkbox"
          className="form-check-input position-static"
          onChange={() =>
            updateForm({
              id: user.id,
              is_administrator: !user.is_administrator ? 'true' : 'false',
            })
          }
        />
        <label htmlFor={`admin-${user.id}`} className="form-check-label d-sm-none">
          Admin
        </label>
      </div>
    </form>
  );
};

export default UserAdminCheckbox;
