import { useAuth } from '../contexts/authContext';
// import Notifications from 'customer/components1/Notifications';
// import {
//   addError,
//   addFlashError,
//   addFlashSuccess,
//   addNotification,
//   addSuccess,
//   fetchMeAndAccount,
// } from 'customer/store/actions';
import { User } from '../shared/types';
import ContentCard from './ContentCard';
import NewUserModal from './NewUserModal';
import TeamListCard from './TeamListCard';

type TeamListProps = {
  data: User[];
};
const TeamList = ({ data }: TeamListProps) => {
  const dummyData = [
    {
      account_id: 2,
      confirmed_at: '2023-02-07T07:57:46.000Z',
      created_at: '2023-02-07T07:57:20.000Z',
      current_sign_in_at: '2023-02-14T09:05:56.000Z',
      email: 'milena@productiv-e.com',
      first_name: 'milena',
      id: 2,
      is_administrator: true,
      is_super_user: false,
      job_title: 'Executive (Owner, Founder)',
      last_name: 'tomova',
      owner: true,
    },
    {
      account_id: 4,
      confirmed_at: '2023-02-07T07:57:46.000Z',
      created_at: '2023-02-07T07:57:20.000Z',
      current_sign_in_at: '2023-02-14T09:05:56.000Z',
      email: 'fname1@productiv-e.com',
      first_name: 'fname1',
      id: 4,
      is_administrator: false,
      is_super_user: false,
      job_title: 'Executive (Owner, Founder)',
      last_name: 'lname1',
      owner: false,
    },
    {
      account_id: 5,
      confirmed_at: '2023-02-07T07:57:46.000Z',
      created_at: '2023-02-07T07:57:20.000Z',
      current_sign_in_at: '2023-02-14T09:05:56.000Z',
      email: 'fname2@productiv-e.com',
      first_name: 'fname2',
      id: 5,
      is_administrator: false,
      is_super_user: false,
      job_title: 'Executive (Owner, Founder)',
      last_name: 'lname2',
      owner: false,
    },
  ];
  const { user } = useAuth(); //TODO exported from authContext, see it is relevant

  const onUpdateError = (user: User) => {
    // this.updateUsers();
    // this.props.addFlashError(`Unable to update user.`);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center gap-2 mb-4">
        <div>
          <h2 className="h3 mb-0">Current Users</h2>
        </div>
        <div>
          {(user?.is_administrator || user?.owner) && (
            <NewUserModal maxNewUsers={5 - data.length} />
          )}
        </div>
      </div>
      <ContentCard>
        <div className="team-list">
          <div className="team-list__header">
            <div>Name</div>
            <div>Email</div>
            <div>Administrator</div>
            <div>Owner</div>
            <div></div>
          </div>
          {user &&
            data.map((teamMember: User, index) => (
              <TeamListCard
                me={user} //loggedin user
                user={teamMember} //team member
                key={teamMember.id}
                onError={onUpdateError}
                index={index}
              />
            ))}
        </div>
      </ContentCard>
    </>
  );
};

export default TeamList;
