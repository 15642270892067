import { Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { RoutePaths } from '../config/route-paths';
import { confirmWithToken, resetPassword } from '../services/user';
import ButtonSubmit from './ButtonSubmit';
import { jobTitleOptions } from './FormSignUp';
import PageHeader from './PageHeader';
import PageSection from './PageSection';

type FormValues = {
  password: string;
  job_title: string;
};

type Props = {
  inviting?: boolean;
};

const FormResetPassword = ({ inviting }: Props) => {
  const [loading, setLoading] = useState(false);
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const confirm = async () => {
      try {
        await confirmWithToken(token as string, { inviting }).then((response) => {
          console.log(response);
        });
      } catch (error: any) {
        !inviting && navigate(RoutePaths.RESET_PASSWORD, { replace: true });
        toast(
          'We could not find your account. You may have used an expired link to reset your password.',
          {
            type: 'error',
          },
        );
      }
    };

    confirm();

    return () => {};
  }, [token]);

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      setLoading(true);

      const user = {
        password: values.password,
        job_title: values.job_title,
        reset_password_token: token,
      };

      await resetPassword(
        {
          ...user,
        },
        { inviting },
      );

      navigate(RoutePaths.SIGNIN, { replace: true });
      toast('Success! Sign in with your brand new password!', {
        type: 'success',
      });
    } catch (error: any) {
      console.log(error);
      const { response: { status, data: { errors } } } = error;
      let errorMessage = 'There was a problem updating your password.';
      if (status === 400) {
        errorMessage = errors?.job_title ? errors.job_title[0] : errors?.password ? errors.password[0] : 'There was a problem updating your password.';
      } else if (status === 404) {
        errorMessage = 'Your code was invalid or expired. Please send another request to update your password.';
      } else if (status === 429) {
        errorMessage = 'Cool your jets! You tried to reset your password too many times, too quickly. Wait one minute and try again.';
      }
      toast(errorMessage, {
        type: 'error',
      });
  } finally {
      setLoading(false);
    }
  };

  const schema = yup.object().shape({
    password: yup.string().required().matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
    job_title: inviting ? yup.string().required() : yup.string(),
  });

  return (
    <>
      <PageHeader>
        <h1>{inviting ? 'Welcome to CloudSponge!' : 'Update your password'}</h1>
        {inviting && (
          <p className="mt-3">
            Tell us about your role and set a password that you will use to sign in to
            your account.
          </p>
        )}
      </PageHeader>
      <PageSection>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            password: '',
            job_title: '',
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {inviting && (
                <Form.Group controlId="job_title" className="mb-3">
                  <Form.Select
                    aria-label="Job Title"
                    name="job_title"
                    value={values.job_title}
                    onChange={handleChange}
                    className={touched.job_title && errors.job_title ? 'is-invalid' : ''}
                  >
                    <option value="" disabled>
                      Select Role
                    </option>
                    {jobTitleOptions.map((option, i) => (
                      <option value={option} key={i}>
                        {option}
                      </option>
                    ))}
                  </Form.Select>

                  {touched.job_title && errors.job_title && (
                    <Form.Control.Feedback type="invalid">
                      {errors.job_title}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              )}
              <Form.Group controlId="password" className="mb-3">
                <Form.Label className="fs-7">Enter new password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={touched.password && !!errors.password}
                />
                {errors.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <p className="mt-4">
                <ButtonSubmit className="w-100" type="submit" loading={loading}>
                  Update my password
                </ButtonSubmit>
              </p>
            </Form>
          )}
        </Formik>
      </PageSection>
    </>
  );
};

export default FormResetPassword;
