export const sourceToName = (source: string) => {
  if (!source) {
      return ""
  }
  switch (source.toLowerCase()) {
      case "gmail":
          return "Google"
      case 'yahoo':
          return "Yahoo"
      case 'microsoft':
      case 'outlook':
      case 'outlookcom':
          return "Microsoft"
      case 'windowslive':
          return "Windows Live"
      case 'office365':
          return "Office365"
      case 'aol':
          return "AOL"
      case 'facebook':
          return "Facebook (deprecated)"
      case 'mail_ru':
          return "Mail.ru"
      case 'addressbook':
          return 'Mac Contacts'
      case 'csv':
          return 'CSV'
      case 'icloud':
          return 'iCloud'
      case 'linkedin':
          return 'LinkedIn'
      case 'mail163':
          return '163.com'
      case 'mailcom':
          return 'mail.com'
      case 'mail_yeah_net':
          return 'yeah.net'
      case 'qq_mail':
          return 'qq.com'
      default:
          return source
  }
}