import { axios } from '../utils/utils';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useParams, useRouteLoaderData } from 'react-router-dom';

import CommonMetrics from '../components/CommonMetrics';
import ContentCard from '../components/ContentCard';
import ConversionFunnel from '../components/ConversionFunnel';
import DetailedMetrics from '../components/DetailedMetrics';
import Loading from '../components/Loading';
import MetricsFilters from '../components/MetricsFilters';
import Page from '../components/Page';
import PageHeader from '../components/PageHeader';
import PageSection from '../components/PageSection';
import SectionDisabledUnlock from '../components/SectionDisabledUnlock';
import { METRICS_DATA } from '../config/metrics-data';
import { useDisabledSection } from '../hooks/useDisabledSection';
import { fetchImportChart, fetchMetricsData } from '../services/metrics';
import {
  DetailedMetricsType,
  MetricsPeriodType,
  MetricsProps,
  Project,
  Site,
} from '../shared/types';
import { sourceToName } from '../utils/credentials';
import { fetchSite } from '../services/sites';

const Metrics = ({ context }: MetricsProps) => {
  const { siteId } = useParams();
  const { data } = useRouteLoaderData('project') as {
    data: Project;
  };

  const { isSectionDisabled } = useDisabledSection();

  const periodItervals = useMemo(() => {
    return METRICS_DATA.periodIntervals;
  }, []);
  const detailedMetricsTypes: DetailedMetricsType[] = React.useMemo(() => {
    return data.metrics.sandwich ? 
      METRICS_DATA.typeDetails : METRICS_DATA.typeDetails.filter(({ server }) => server);
  }, [data.metrics.sandwich]);
  const [loading, setLoading] = useState(false);
  const [sites, setSites] = React.useState(METRICS_DATA.sitesGroups);
  const [currentSiteId, setCurrentSiteId] = useState<number | null>(null);
  const [currentSite, setCurrentSite] = useState<Site | null>(null);

  useEffect(() => {
    if (!siteId) return;

    setLoading(true);
    fetchSite(siteId).then((data) => {
      setCurrentSite(data);
      setCurrentSiteId(parseInt(siteId));
      setSites([]);
      setLoading(false);
    });
  }, [siteId]);

  const [currentDetailedMetricsId, setCurrentDetailedMetricsId] = useState(data.metrics.sandwich ? 0 : 8);
  const [currentDetailedMetrics, setCurrentDetailedMetrics] =
    useState<DetailedMetricsType | null>(null);

  const [dateRange, setDateRange] = useState<Record<string, string>>();
  const [currentPeriod, setCurrentPeriod] = useState(
    periodItervals[context === 'site' ? 1 : 0],
  );

  // Select Site From Dropdown
  const handleSelectSite = useCallback((site: Record<string, number | null>) => {
    setCurrentSiteId(site.id);
  }, []);
  // Select Period
  const handleSelectPeriod = useCallback((period: MetricsPeriodType) => {
    setCurrentPeriod(period);
  }, []);
  // Select Date
  const handleDateChange = useCallback(
    (startDate: string, endDate: string) => {
      setDateRange({
        dateFrom: startDate ? startDate : currentPeriod.dateFrom,
        dateTo: endDate ? endDate : currentPeriod.dateTo,
      });
    },
    [currentPeriod],
  );
  // Change Detailed Metrics
  const handleChangeDetailedMetrics = useCallback((metricsId: number) => {
    setCurrentDetailedMetricsId(metricsId);
  }, []);
  // Set Date Range
  useEffect(() => {
    if (!currentPeriod) return;
    setDateRange({
      dateFrom: currentPeriod.dateFrom,
      dateTo: currentPeriod.dateTo,
    });
  }, [currentPeriod]);

  // fetch Current Site
  useEffect(() => {
    if (currentSiteId || dateRange) {
      const queryParams = {
        domain_id: currentSiteId,
        project_key: data?.key,
        start_date: dateRange ? dateRange.dateFrom : null,
        end_date: dateRange ? dateRange.dateTo : null,
      };
      setLoading(true);
      fetchMetricsData(queryParams).then((data) => {
        setCurrentSite(data);
        setLoading(false);
      });
    }
  }, [currentSiteId, dateRange]);

  useEffect(() => {
    if (currentSite) {
      const metrics = detailedMetricsTypes.filter(
        (item) => item.id === currentDetailedMetricsId, //by default is 0
      )[0];

      const valueByMetricsValue = metrics.value ? currentSite[metrics.value] : null;
      setCurrentDetailedMetrics({
        id: metrics.id,
        value: valueByMetricsValue,
        chartDataFetch: metrics.chartDataFetch,
        chartData: currentSite[metrics.chartData],
        chartType: metrics.chartType,
        name: metrics.name,
        title: metrics.title,
        infoText: metrics.infoText,
        suffix: metrics.suffix,
        decimals: metrics.decimals,
      });
      setCurrentDetailedMetricsId(metrics.id);
    }
  }, [currentSite, currentDetailedMetricsId, detailedMetricsTypes]);

  // Fetch detailed metrics volumes and add it to currentDetailedMetrics
  // volume
  // volume_by_address_book_provider
  useEffect(() => {
    if (
      (currentSiteId || dateRange) &&
      currentDetailedMetrics &&
      !currentDetailedMetrics.chartData
    ) {
      const start_date = dateRange ? dateRange.dateFrom : null;
      const end_date = dateRange ? dateRange.dateTo : null;
      let group = 'date';
      const fetchChartData = async () => {
        try {
          if (
            currentDetailedMetrics.chartDataFetch === 'volume_by_address_book_provider'
          ) {
            group = 'source';
          }
          setLoading(true);
          const chartDatas = await fetchImportChart({ start_date, end_date, group, domain_id: currentSiteId, project_key: data?.key });
          const summaryValue = chartDatas.data.datasets.passed.data.reduce((a, b) => a + b, 0);
          setLoading(false);
          if (group == 'source') {
            // map the labels to human readable text
            chartDatas.data.labels.forEach((label: string, i: number | string) => {
              chartDatas.data.labels[i] = sourceToName(label);
            });
          }
          setCurrentDetailedMetrics((prev: any) => ({
            ...prev,
            chartData: chartDatas.data.datasets,
            chartLabels: chartDatas.data.labels,
            value: summaryValue,
          }));
        } catch (error) {
          throw new Error('Something went wrong');
        }
      };
      fetchChartData();
    }
  }, [currentSite, currentDetailedMetrics, dateRange]);

  const title = useMemo(() => {
    const metricsTitle = `${data.metrics.sandwich ? "" : "Browser "} Metrics`;
    return currentSite?.domain && currentSite?.domain.name
      ? `${metricsTitle} for ${currentSite.domain.name}`
      : metricsTitle;
  }, [currentSite, data.metrics.sandwich]);

  return (
    <>
      <Helmet>
        <title>Metrics</title>
      </Helmet>
      <Page>
        <PageHeader>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <h1>{title}</h1>
            {loading && <Loading size="sm" variant="info" />}
          </div>
        </PageHeader>
        {isSectionDisabled && <SectionDisabledUnlock lockedSection="Metrics" />}
        <PageSection sectionDisabled={isSectionDisabled}>
          {currentSite && (
            <Stack gap={2}>
              <MetricsFilters
                context={context}
                sites={sites} //@TODO fix typing
                currentSite={currentSite} //@TODO Fix  typing
                periodItervals={periodItervals}
                currentPeriod={currentPeriod}
                dateRange={dateRange}
                handleSelectSite={handleSelectSite}
                handleSelectPeriod={handleSelectPeriod}
                handleDateChange={handleDateChange}
              />

              { data.metrics.sandwich && (
                <>
                  <ContentCard>
                    <CommonMetrics
                      site={currentSite}
                      detailedMetricsTypes={detailedMetricsTypes}
                    />
                  </ContentCard>

                  <ContentCard>
                    <Stack gap={3}>
                      <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3 mb-4">
                        <h3 className="m-0">Conversion Funnel </h3>
                        <p className="fs-7 m-0">
                          Measures the number of people did each funnel step during the
                          period.
                        </p>
                      </div>
                      <ConversionFunnel
                        site={currentSite}
                        detailedMetricsTypes={detailedMetricsTypes}
                      />
                    </Stack>
                  </ContentCard>

                  <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-2 mt-4 mb-2">
                    <h3 className="m-0 fw-normal">Browse metrics for</h3>
                    <p className="h3 m-0">
                      {currentPeriod &&
                        ` ${moment(
                          dateRange ? dateRange.dateFrom : currentPeriod.dateFrom,
                        ).format('MMM D, Y')} - ${moment(
                          dateRange ? dateRange.dateTo : currentPeriod.dateTo,
                        ).format('MMM D, Y')}`}
                    </p>
                  </div>
                </>
              )}

              <ContentCard>
                <DetailedMetrics
                  handleChangeDetailedMetrics={handleChangeDetailedMetrics}
                  detailedMetricsTypes={detailedMetricsTypes}
                  currentDetailedMetrics={currentDetailedMetrics}
                />
              </ContentCard>

              { !data.metrics.sandwich && (
                <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-2 mt-2 mb-2">
                  <p className="m-0">
                    Client metrics are unavailable for this project. <a href="mailto:support@cloudsponge.com?subject=no client metrics">Contact us</a> with any questions.
                  </p>
                </div>
              )}
            </Stack>
          )}
        </PageSection>
      </Page>
    </>
  );
};
export default Metrics;
