import React from 'react';
import { HiAtSymbol } from 'react-icons/hi';
import { RiBook2Line, RiSlackLine } from 'react-icons/ri';
import { SiHandshake } from 'react-icons/si';

import { footerUrls, socialUrls, supportEmail } from '../config/external-urls';

const NavigationSocial = () => {
  const items = [
    {
      icon: HiAtSymbol,
      label: 'Email us',
      path: `mailto:${supportEmail}`,
      newTab: true,
    },
    {
      icon: RiSlackLine,
      label: 'Join us in Slack',
      path: socialUrls.slack,
      newTab: true,
    },
    {
      icon: SiHandshake,
      label: 'Meet us',
      path: socialUrls.calendly,
      newTab: true,
    },
    {
      icon: RiBook2Line,
      label: 'Documentation',
      path: footerUrls.documentation,
      newTab: true,
    },
  ];

  return (
    <nav className="navigation-social">
      {items.map((item, i) => (
        <a
          key={i}
          href={item.path}
          className="navigation-social__btn"
          target={item.newTab ? '_blank' : undefined}
          rel={item.newTab ? 'noopener noreferrer' : undefined}
        >
          <span className="navigation-social__icon">
            <item.icon className="icon" />
          </span>
          <span>{item.label}</span>
        </a>
      ))}
    </nav>
  );
};

export default NavigationSocial;
