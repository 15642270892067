import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ButtonCopy from './ButtonCopy';

const ReferralSnippet = ({
  referralUrl
}:{
  referralUrl: string,
}) => {
  return (
    <Row>
      <Col lg="8" md="12" className="pt-3">
        <Form.Control as="textarea" className="form-control" onClick={(event) => event.currentTarget.select()} readOnly={true} rows={1} style={{resize: 'none', wordBreak: 'break-all', color: '#444444'}} defaultValue={referralUrl}/>
      </Col>
      <Col lg="4" md="12" className="pt-3 pb-4">
        <ButtonCopy text={referralUrl}/>
      </Col>
    </Row>
  )
}

export default ReferralSnippet;
