import React from 'react';
import { Helmet } from 'react-helmet-async';

import Page from '../components/Page';

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Page not found</title>
      </Helmet>

      <Page>NotFound</Page>
    </>
  );
};

export default NotFound;
