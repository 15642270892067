import { axios } from '../utils/utils';

import config from '../config/config';

//use of any because of currently not clear domainId type
export const fetchMetricsData = async (
  queryParams: Record<string, string | null | any>,
) => {
  try {
    queryParams = {
      ...queryParams,
      domain_id: queryParams.domain_id || '',
      project_key: queryParams.project_key || '',
      start_date: queryParams.start_date || '',
      end_date: queryParams.end_date || '',
    };
    const searchStr = new URLSearchParams(queryParams).toString();
    const url = `${config.DATA_PATH}/daily_events?${searchStr}`;
    const response = await axios(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Something went wrong');
  }
};

export const fetchImportChart = async (
  queryParams: Record<string, string | null | any>,
) => {
  try {
    queryParams = {
      ...queryParams,
      domain_id: queryParams.domain_id || '',
      group: queryParams.group || '',
      project_key: queryParams.project_key || '',
      start_date: queryParams.start_date || '',
      end_date: queryParams.end_date || '',
    };
    const url = `${config.DATA_PATH}/import_results?${new URLSearchParams(queryParams).toString()}`;
    const response = await axios(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Something went wrong');
  }
};
