import { Helmet } from 'react-helmet-async';

import FormResetPassword from '../components/FormResetPassword';

type Props = {
  inviting?: boolean;
};

const ResetPassword = ({ inviting = false }: Props) => {
  return (
    <>
      <Helmet>
        <title>Update your password</title>
      </Helmet>

      <FormResetPassword inviting={inviting} />
    </>
  );
};

export default ResetPassword;
