import React from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams } from 'react-router-dom';

import { RoutePaths } from '../config/route-paths';
import ContentCard from './ContentCard';

type Props = {
  lockedSection: string;
};

const SectionDisabledUnlock = ({ lockedSection }: Props) => {
  const { projectKey } = useParams<{ projectKey: string }>();

  return (
    <ContentCard className="mb-3">
      <div className="align-items-center justify-content-between d-flex flex-column flex-md-row gap-3">
        <span className="text-dark fw-semibold">
          To unlock {lockedSection} for your project, please add a site first.
        </span>

        <LinkContainer to={`${RoutePaths.PROJECTS}/${projectKey}/${RoutePaths.SITES}`}>
          <Button variant="primary" className="text-nowrap">
            Add site
          </Button>
        </LinkContainer>
      </div>
    </ContentCard>
  );
};

export default SectionDisabledUnlock;
