import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';

import AirbnbLogo from '../assets/images//customer-logos/airbnb.png';
import GoFundMeLogo from '../assets/images//customer-logos/gofundme.png';
import LyftLogo from '../assets/images//customer-logos/lyft.png';
import NextDoorLogo from '../assets/images//customer-logos/nextdoor.png';
import PatreoLogo from '../assets/images//customer-logos/patreon.png';
import YelpLogo from '../assets/images//customer-logos/yelp.png';

type Props = {};

const HeroGuest = (props: Props) => {
  return (
    <div>
      <h1 className="mb-6">
        Engage with your users’ contacts to generate more leads, referrals, and virality.
      </h1>
      <h2 className="fs-6 mb-4">Trusted by:</h2>
      <Row xs={2} sm={3} className="align-items-center gx-5 gy-4">
        <Col>
          <Image
            fluid
            src={AirbnbLogo}
            alt="Airbnb"
            style={{ maxHeight: '2.5rem' }}
            loading="lazy"
          />
        </Col>
        <Col>
          <Image
            fluid
            src={NextDoorLogo}
            alt="NextDoor"
            style={{ maxHeight: '2.5rem' }}
            loading="lazy"
          />
        </Col>
        <Col>
          <Image fluid src={YelpLogo} alt="Yelp" style={{ maxHeight: '2.5rem' }} />
        </Col>
        <Col>
          <Image
            fluid
            src={GoFundMeLogo}
            alt="GoFundMe"
            style={{ maxHeight: '2.5rem' }}
            loading="lazy"
          />
        </Col>
        <Col>
          <Image
            fluid
            src={PatreoLogo}
            alt="Patreon"
            style={{ maxHeight: '2.5rem' }}
            loading="lazy"
          />
        </Col>
        <Col>
          <Image
            fluid
            src={LyftLogo}
            alt="Lyft"
            style={{ maxHeight: '2.5rem' }}
            loading="lazy"
          />
        </Col>
      </Row>
    </div>
  );
};

export default HeroGuest;
