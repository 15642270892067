import { useEffect, useState } from 'react';

import { useProfileName } from '../hooks/useProfile';
import { User } from '../shared/types';
import UserAdminCheckbox from './UserAdminCheckbox';
import UserAvatar from './UserAvatar';
import UserDeleteButton from './UserDeleteButton';
import UserOwnerRadio from './UserOwnerRadio';

type TeamListCardProps = {
  me: User; //loggedin user
  user: User; //team member
  onError: (user: User) => void;
  index: number;
};
const TeamListCard = (props: TeamListCardProps) => {
  const { me, user } = props;
  const [canDeleteUser, setCanDeleteUser] = useState(
    me.id !== user.id && !user.owner && me.is_administrator && me.owner,
  );
  const userName = useProfileName(me, user, true);

  useEffect(() => {
    setCanDeleteUser(me.id !== user.id && !user.owner && me.is_administrator && me.owner);
  }, [user]);

  return (
    <div className="team-list__row">
      <div className="d-flex flex-nowrap align-items-center gap-3 team-list__user">
        <UserAvatar me={me} user={user} />
        <span className="text-truncate">{userName}</span>
      </div>
      <div className="text-truncate">{user.email}</div>
      <div>
        <UserAdminCheckbox {...props} />
      </div>
      <div>
        <UserOwnerRadio {...props} />
      </div>
      <div>{canDeleteUser && <UserDeleteButton {...props} />}</div>
    </div>
  );
};
export default TeamListCard;
