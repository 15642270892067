export const trackedEvents = {
  createdLogin: 'Created Login',
  signedUp: 'Signed up',
  visitorSubmittedSugnUpForm: 'Visitor submitted sign up form',
  resentConfirmationEnail: 'Resent confirmation email', 
  requestPasswordReset: 'Requested password reset',
  inviteeSubmittedSignUpForm: 'Invitee submitted sign up form',
  accountAddedLogin: 'Account added login',
  resetPassword: 'Reset password',
  createdOAuth: 'Created OAuth',
  userAddedOAuthCredential: "User added an OAuth credential",
  userAddedDataSourceOAuthCredential: (replaceString: string) => `User added a ${replaceString} OAuth credential`,
  deletedOAuth: 'Deleted OAuth', 
  assignedOAuthToSite: 'Assigned OAuth to site',
  userSetSite: 'User set their website',
  userAddedSiteToSubscription: 'User added a site to their subscription',
  userDeletedSite: 'User deleted a site',
  blockedSite: 'Blocked site',
  userBlockedSite: 'User blocked a site',
  regeneratedSiteCredentials: 'Regenerated site credentials',
  changedOwner: 'Changed owner',
  changedUserRole: 'Changed user role', 
  invitedUser: 'Invited User',
  userInvitedAnotherUser: 'User invited another user',
  deletedUser:  'Deleted user', 
  updatedProfile:'Updated profile',
  changedPassword:'Changed password',  

}