import { useEffect, useMemo, useState } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { useRouteLoaderData } from 'react-router-dom';

import { fetchSitesWithQueryStr } from '../services/sites';
import { Site, SitesData, SitesLoaderData } from '../shared/types';
import Loading from './Loading';
import PageSection from './PageSection';
import SitesListCard from './SitesListCard';

type Props = {
  sites: Site[];
};

const DevSitesListContainer = ({ devSites }: { devSites: SitesData }) => {
  const [sites, setSites] = useState(devSites.sites);

  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(devSites.totalRecords);

  const totalRecordsDefault = useMemo(() => devSites.totalRecords, [devSites]);

  useEffect(() => {
    setSites(devSites.sites);
    setPage(1);
    setTotalRecords(devSites.totalRecords);
  }, [devSites]);
  /**
   * Project id the site belongs to
   */
  const { projectId } = useRouteLoaderData('project') as {
    projectId: string;
  };

  const loadMoreSites = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const moreSites = await fetchSitesWithQueryStr({
      page: page + 1,
      projectId: projectId,
      filter: filter,
      segment: 'development',
    });
    const updatedSites = sites.concat(moreSites.devSites.sites);
    setSites(updatedSites);
    setPage(page + 1);
  };

  const filterSites = async ({ target }: { target: { name: string; value: string } }) => {
    setFilter(target.value);
    const filteredData = await fetchSitesWithQueryStr({
      page: 1,
      projectId: projectId,
      filter: target.value,
      segment: 'development',
    });
    //filter after string is 3 chars or more or restore unfiltered site list
    if (target.value.length > 2 || target.value.length === 0) {
      setSites(filteredData.devSites.sites);
      setTotalRecords(filteredData.devSites.totalRecords);
      //reset page to display from page=1 initially
      setPage(1);
    }
  };
  return (
    <>
      <PageSection>
        {totalRecordsDefault > 0 ? (
          <div className="mb-4 d-flex flex-column flex-sm-row gap-2 justify-content-between">
            <div>
              <h2>Test ({totalRecords})</h2>
              <p className="fs-7 mb-0">You won't be charged for these sites.</p>
            </div>

            <div>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Group controlId="search">
                  <Form.Control
                    className="search-input"
                    type="search"
                    placeholder="Search sites..."
                    onChange={filterSites}
                    value={filter || ''}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
        ) : null}
        {sites.length > 0 ? (
          <Stack gap={2}>
            {sites.map((item: Site) => (
              <SitesListCard key={item.id} site={item} />
            ))}
            {totalRecords > sites.length ? (
              <a href="#" onClick={loadMoreSites}>
                Load next
              </a>
            ) : null}
          </Stack>
        ) : null}
      </PageSection>
    </>
  );
};

export default DevSitesListContainer;
