import { Col, Container, Row } from 'react-bootstrap';
import { Navigate, Outlet } from 'react-router-dom';

import { RoutePaths } from '../config/route-paths';
import { useAuth } from '../contexts/authContext';
import HeroGuest from './HeroGuest';
import Logo from './Logo';
import NavigationGuest from './NavigationGuest';
import Page from './Page';
import Loading from './Loading';

const LayoutGuest = () => {
  const { isAuthed, initialLoad } = useAuth();

  if (isAuthed) {
    return <Navigate to={RoutePaths.PROJECTS} replace />;
  }

  if (initialLoad) {
    return <Loading position="fullscreen" />;
  }

  return (
    <>
      <div className="guest-layout-background"></div>

      <Container fluid className="my-auto py-4">
        <Row>
          <Col xs={12} lg={6}>
            <Page size="sm">
              <div className="mb-5 mb-sm-8">
                <Logo size={200} />
              </div>
              <Outlet />
              <div className="mt-3">
                <NavigationGuest />
              </div>
            </Page>
          </Col>
          <Col xs={12} lg={6} className="mt-5 mt-lg-0">
            <Page size="sm">
              <HeroGuest />
            </Page>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LayoutGuest;
