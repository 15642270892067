import React, { Suspense, useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData, useParams } from 'react-router-dom';

import GmailLogo from '../assets/images/logo-google.svg';
import CredentialForm from '../components/CredentialForm';
import CredentialInformation from '../components/CredentialInformation';
import Page from '../components/Page';
import { useCredentials } from '../hooks/useCredentials';
import { Credential } from '../shared/types';

const ProviderHelp = ({ provider }: { provider: Credential['source'] }) => {
  const component = {
    gmail: () => import('../components/CredentialInformationGmail'),
    outlookcom: () => import('../components/CredentialInformationMicrosoft'),
    yahoo: () => import('../components/CredentialInformationYahoo'),
    aol: () => import('../components/CredentialInformationAol'),
    default: <div>Not found</div>,
  };

  const Component = React.lazy(component[provider] || component.default);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component />
    </Suspense>
  );
};

const ProviderCredentialsDetails = () => {
  const [showForm, setShowForm] = React.useState(false);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const { currentCredential } = useCredentials();

  useLayoutEffect(() => {
    if (!currentCredential) return;

    const { readonly, source, id } = currentCredential;

    if (source === 'aol') {
      setShowForm(false);
      return;
    }

    if (!!readonly) {
      setShowForm(false);
      return;
    }

    if (!id && source === 'gmail') {
      setShowForm(false);
      return;
    }

    setShowForm(true);
  }, [currentCredential]);

  return (
    <>
      <Helmet>
        <title>Provider Credentials Details</title>
      </Helmet>
      <Page>
        {showForm ? (
          <CredentialForm credential={currentCredential} />
        ) : (
          <>
            <CredentialInformation
              credential={currentCredential}
              onClick={handleShowForm}
            />

            <ProviderHelp provider={currentCredential?.source} />
          </>
        )}
      </Page>
    </>
  );
};

export default ProviderCredentialsDetails;
