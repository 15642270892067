import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

import Loading from './Loading';

interface IButtonSubmit extends ButtonProps {
  loading?: boolean;
}

const ButtonSubmit = forwardRef<HTMLButtonElement, IButtonSubmit>(
  ({ loading, children, className, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        className={classNames(className, 'btn-loading', {
          'is-loading': loading,
        })}
        {...props}
      >
        <span className="btn-loading__content">{children}</span>
        {loading ? <Loading size="sm" /> : null}
      </Button>
    );
  },
);

ButtonSubmit.displayName = 'ButtonSubmit';

export default ButtonSubmit;
