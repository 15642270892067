import React from 'react';

import SegmentAnalytics from './SegmentAnalytics';

type Props = {
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
};

const Page = ({ children, size = 'xl' }: Props) => {
  const style = size
    ? ({
        '--content-size': `var(--content-size-${size})`,
      } as React.CSSProperties)
    : undefined;
  return (
    <SegmentAnalytics>
      <div className="content__wrapper" style={style}>
        {children}
      </div>
    </SegmentAnalytics>
  );
};

export default Page;
