import React from 'react';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Await, useLoaderData } from 'react-router-dom';

import InstallList from '../components/InstallList';
import Loading from '../components/Loading';
import Page from '../components/Page';
import PageHeader from '../components/PageHeader';
import PageSection from '../components/PageSection';
import SectionDisabledUnlock from '../components/SectionDisabledUnlock';
import { useDisabledSection } from '../hooks/useDisabledSection';
import { IntegrationsData } from '../services/integrations';

const Install = () => {
  const { data } = useLoaderData() as { data: IntegrationsData };
  const { isSectionDisabled } = useDisabledSection();

  return (
    <>
      <Helmet>
        <title>Install</title>
      </Helmet>
      <Page>
        <PageHeader>
          <h1>Install</h1>
        </PageHeader>
        {isSectionDisabled && <SectionDisabledUnlock lockedSection="Install" />}
        <PageSection sectionDisabled={isSectionDisabled}>
          <Suspense fallback={<Loading position="fullheight" />}>
            <Await
              resolve={data}
              errorElement={<div>Could not load integrations 😬</div>}
            >
              {(data) => <InstallList data={data} />}
            </Await>
          </Suspense>
        </PageSection>
      </Page>
    </>
  );
};
export default Install;
