import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { RoutePaths } from '../config/route-paths';
import { useAuth } from '../contexts/authContext';
import ButtonSubmit from './ButtonSubmit';
import { trackClick } from '../libs/analytics';

type FormValues = {
  email: string;
  password: string;
};

const FormSignIn = () => {
  const { signin, loading, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || RoutePaths.PROJECTS;

  const handleSubmit = async (
    values: FormValues,
    { setStatus }: FormikHelpers<FormValues>,
  ) => {
    const creds = { username: values.email, password: values.password };
    try {
      await signin(creds)
    }
    catch (error) {
      setStatus({error: 'Could not sign in with the username and password provided.'});
    }
  };

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user]);

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });

  return (
    <div>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          email: '',
          password: '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors, status, setStatus }) => (
          <Form noValidate onSubmit={handleSubmit} onChange={() => {setStatus({})}}>
            {status?.error && (
              <Alert variant="danger">
                {status.error}
              </Alert>
            )}
            <Form.Group controlId="email" className="mb-3">
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={touched.email && !!errors.email}
                placeholder="Email"
                autoComplete="username"
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Control
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                isInvalid={touched.password && !!errors.password}
                placeholder="Password"
                autoComplete="current-password"
              />
              {errors.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <p className="mt-4">
              <ButtonSubmit className="w-100" type="submit" loading={loading} onClick={trackClick}>
                Sign In
              </ButtonSubmit>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormSignIn;
