import React, { useEffect } from 'react';
import { Stack } from 'react-bootstrap';

import { Project } from '../shared/types';
import ProjectListCard from './ProjectListCard';

type Props = {
  data: Project[];
};

const ProjectList = ({ data: projects }: Props) => {
  return (
    <Stack gap={2}>
      <div className="project-list-card d-none d-md-grid">
        <span className="project-list-card__header">Name</span>
        <span className="project-list-card__header">Licensed Sites</span>
        <span className="project-list-card__header">Test Sites</span>
      </div>
      {projects.map((project) => (
        <ProjectListCard key={project.id} project={project} />
      ))}
    </Stack>
  );
};

export default ProjectList;
