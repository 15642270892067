import React from 'react';
import ExternalLink from './ExternalLink';
import { supportEmail } from '../config/external-urls';

const SubscriptionTrialInfo = ({className=""}) => {
  return (
    <>
      <p className={className}>
        <strong>A 14-day free trial</strong><br/>
        We won't charge your card until your trial ends.
      </p>
      <p className={className}>
        <strong>No committments</strong><br/>
        You can cancel at any time during your trial, for no cost.
      </p>
      <p className={className}>
        <strong>Money-back guarantee</strong><br/>
        We'll refund your first payment for any reason. You only have to <ExternalLink href={`mailto:${supportEmail}`}>ask us</ExternalLink>.
      </p>
    </>
  );
};

export default SubscriptionTrialInfo;
