import { Helmet } from 'react-helmet-async';

import FormSignUp from '../components/FormSignUp';
import PageHeader from '../components/PageHeader';
import PageSection from '../components/PageSection';

const Signup = () => {
  return (
    <>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <PageHeader>
        <h1>Sign Up</h1>
      </PageHeader>
      <PageSection>
        <FormSignUp />
      </PageSection>
    </>
  );
};

export default Signup;
