import { Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import FormResendConfirmationEmail from '../components/FormResendConfirmationEmail';
import PageHeader from '../components/PageHeader';
import PageSection from '../components/PageSection';

const ConfirmationRequired = () => {
  const { state } = useLocation();
  const email = state?.email || '';

  return (
    <>
      <Helmet>
        <title>Successfully signed up</title>
      </Helmet>
      {email && (
        <PageHeader>
          <Alert variant="success">
            <strong>Successfully signed up.</strong> We sent an email to {email}. Please
            look for your confirmation email from support@cloudsponge.com.
          </Alert>
        </PageHeader>
      )}
      <PageSection>
        <FormResendConfirmationEmail email={email} />
      </PageSection>
    </>
  );
};

export default ConfirmationRequired;
