import React, { useMemo } from 'react';
import { Link, defer } from 'react-router-dom';

import { RoutePaths } from '../config/route-paths';
import { Project, Site } from '../shared/types';
import ContentCard from './ContentCard';
import { Badge } from 'react-bootstrap';

type Props = {
  project: Project;
};

const ProjectListCard = ({ project }: Props) => {
  return (
    <ContentCard key={project.id}>
      <Link to={`${project.key}/${RoutePaths.METRICS}`} className="project-list-card">
        <div className="project-list-card__name">
          {project.name}{' '}
          {project.type === 'rest_api_project' && (
            <Badge bg="info">
              REST API
            </Badge>
          )}
        </div>
        <div className="project-list-card__sites_1">
          <span className="project-list-card__label">Licensed Sites</span>
          {project.prod_sites_count || 0}
        </div>
        <div className="project-list-card__sites_2">
          <span className="project-list-card__label">Test Sites</span>
          {project.dev_sites_count || 0}
        </div>
      </Link>
    </ContentCard>
  );
};

export default ProjectListCard;
