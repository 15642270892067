import React from 'react';
import { Modal } from 'react-bootstrap';

import { Product, SubscriptionSecure } from '../shared/types';
import FormCreditCard from './FormCreditCard';
import SubscriptionTrialInfo from './SubscriptionTrialInfo';

type Props = {
  show: boolean;
  handleClose?: () => void;
  product?: Product;
  hasChargify: boolean;
  secureParams?: SubscriptionSecure;
};

const SubscriptionModal = ({
  show,
  handleClose,
  product,
  hasChargify,
  secureParams,
}: Props) => {
  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {hasChargify ? 'Add your credit card' : `Start a trial for the ${product?.name} plan`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {product && (
          <>
            {hasChargify ? (
              <p className="mb-4 mt-sm-n4">
                Adding your payment information will ensure that your Contact Picker
                continues to function seamlessly when your trial ends.
              </p>
            ) : (
              <>
                <p className="mb-4 mt-sm-n4">
                  Save your payment information to start your 14-day trial. At the end of
                  your trial, your {product?.name} subscription will begin.
                </p>
                <SubscriptionTrialInfo />
              </>
            )}
            <FormCreditCard
              product={product}
              hasChargify={hasChargify}
              secureParams={secureParams}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionModal;
