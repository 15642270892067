import { useRef, useState } from 'react';
import Popper from 'popper.js';
import { Overlay } from 'react-bootstrap';

type TooltipOverlayProps = {
  children: React.ReactNode;
  title: string;
  placement?: Popper.Placement;
};
const TooltipOverlay = (props: TooltipOverlayProps) => {
  const { children, title, placement = 'left' } = props;
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <span ref={target} onClick={() => setShow(!show)}>{children}</span>
      <Overlay target={target.current} placement={placement}>
        <span>{title}</span>
      </Overlay>
    </>
  );
};
export default TooltipOverlay;
