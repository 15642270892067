import { useEffect } from 'react';
import { addJavascript } from '../utils/utils';

// Simple React Component that manages adding the cloudsponge object to the page
//  and initializing it with the options that were passed through.
// props:
//  * cloudspongeKey (required): your widget key from your CloudSponge account https://app.cloudsponge.com/app
//  * options (optional): any javascript options you wish to pass to configure the cloudpsonge object
//
// For example, you can use the component like so:
// import CloudSpongeWidget from './CloudSpongeWidget'
// // react boilerplate ...
// <CloudSpongeWidget
//     cloudspongeKey="localhost-only"
//     options={{
//         selectionLimit: this.availableNewUserSlots.bind(this),
//         afterSubmitContacts: this.addUsers.bind(this),
//     }}
// >
//     <a className="cloudsponge-launch" data-cloudsponge-source="gmail">
//         Add your Gmail Contacts
//     </a>
//     <a className="cloudsponge-launch">
//         Add From Address Book
//     </a>
// </CloudSpongeWidget>
declare const window: any;
const CloudSpongeWidget = (props: any) => {
  const applyOptions = () => {
    // calling init attaches the cloudsponge.launch action to any cloudsponge-launch links on the page
    if (window.cloudsponge) {
      window.cloudsponge.init(props.options);
      return true;
    } else {
      return false;
    }
  };
  //render CS js and init CS with the options provided
  //if CS js is alredy rendered, update CS init options
  useEffect(() => {
    if (!applyOptions() && props) {
      if (document.getElementById('__cloudsponge_widget_script')) {
        // the script is already loaded so just invoke the callback and return
        applyOptions();
        return;
      }

      // create and add the scrpt tag
      addJavascript(`https://api.cloudsponge.com/widget/${props.cloudspongeKey}.js`, {id: '__cloudsponge_widget_script'}, applyOptions);
    }
  }, [props?.options]);

  // just render the children, if any.
  //  typically the children will include at least one link decorated with
  //  class="cloudsponge-launch" so that the cloudsponge library will attach
  //  to its onClick handler to launch the widget

  return props.children;
};

export default CloudSpongeWidget;
