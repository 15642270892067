import { Stack } from 'react-bootstrap';

import FormProfile from '../components/FormProfile';
import Page from '../components/Page';
import PageHeader from '../components/PageHeader';
import PageSection from '../components/PageSection';

const Profile = () => {
  return (
    <Page size="sm">
      <PageHeader>
        <Stack gap={2} direction="horizontal" className="align-items-center">
          <h1>My Profile</h1>
        </Stack>
      </PageHeader>
      <PageSection>
        <FormProfile />
      </PageSection>
    </Page>
  );
};

export default Profile;
