import React from 'react';
import { Container, Stack } from 'react-bootstrap';

import { useAuth } from '../contexts/authContext';
import HeaderUser from './HeaderUser';
import Logo from './Logo';
import Navigation from './Navigation';
import ImpersonationBanner from './ImpersonationBanner';

type Props = {
  navigation?: boolean;
};

const Header = ({ navigation }: Props) => {
  const { signout, user: me, account } = useAuth();

  return (
    <div className="header">
      <Container fluid>
        <Stack direction="horizontal" gap={2} className="align-items-center">
          {navigation ? (
            <div className="header__navigation">{me && account && <Navigation />}</div>
          ) : null}

          <Logo />

          <ImpersonationBanner />

          {me && account ? (
            <HeaderUser me={me} account={account} signout={signout} />
          ) : null}
        </Stack>
      </Container>
    </div>
  );
};

export default Header;
