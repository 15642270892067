import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from '../contexts/analyticsContext';
import { useAuth } from '../contexts/authContext';
import analytics from '../libs/analytics';

type SegmentAnalyticsProps = {
  children: ReactElement;
};

const SegmentAnalytics = ({ children }: SegmentAnalyticsProps) => {
  const { user, account } = useAuth();
  const { lastPath, lastUser, lastAccount } = useAnalytics();
  const location = useLocation();

  // const handlePageChange = () => {
  if (user?.impersonation_session) {
    // don't track impersonated users
    return children;
  }
  // track the page load if it changed
  if (location.pathname !== lastPath()) {
    // changed = true, set in state the new lastPath value
    lastPath(location.pathname);
    analytics.page();
  }

  if (user?.id) {
    // track the user and account
    if (user.id != lastUser()) {
      // changed = true, set in state new lastUser value
      lastUser(user.id);
      analytics.identify(user.id, {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        username: user.email,
        created_at: user.created_at,
        // custom data about the user
        activated_at: user.confirmed_at,
        account_owner: user.owner,
        role: user.is_administrator ? 'customer_admin' : 'developer',
        // to ensure that contacts are associated with accounts in zoho:
        company_name: account?.name,
        job_title: user.job_title,
        account_id: user.account_id,
        last_signed_in_at: user.current_sign_in_at,
        customer_portal_version: 'v3',
      });
    }

    // track the account
    if (account) {
      if (account.id.toString() != lastAccount()) {
        // changed = true, set in state new lastAccount value
        lastAccount(account.id.toString());
        analytics.group(account.id.toString(), {
          name: account.name,
          chargify_state: account.subscription.state,
          created_at: account.created_at,
          gauntlet_complete: account.gauntletComplete,
          id: account.id,
          newly_activated: account.newly_activated,
          plan_name: account.subscription.plan_name,
          payment_method_required: account.subscription.payment_method_required,
          customer_portal_version: 'v3',
        });
      }
    }
  }

  return children;
};

export default SegmentAnalytics;
