import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RoutePaths } from '../config/route-paths';
import { useAuth } from '../contexts/authContext';
import { updateAccount } from '../services/user';

const steps = [
  {
    title: 'What vertical/industry are you in?',
    options: [
      {
        value: 'E-commerce',
        label: 'E-commerce',
        description: '',
      },
      {
        value: 'Crowdfunding',
        label: 'Crowdfunding',
        description: '',
      },
      {
        value: 'Marketplaces',
        label: 'Marketplaces',
        description: '',
      },

      {
        value: 'Social Networks',
        label: 'Social Networks',
        description: '',
      },
      {
        value: 'Events',
        label: 'Events',
        description: '',
      },

      {
        value: 'Non-profit',
        label: 'Non-profit',
        description: '',
      },

      {
        value: 'Political',
        label: 'Political',
        description: '',
      },

      {
        value: 'Crypto',
        label: 'Crypto',
        description: '',
      },
    ],
    stepKey: 'industry',
    showOtherOption: true,
  },
  {
    title: 'What’s your use case for a contact picker?',
    options: [
      {
        value: 'E-commerce Referrals',
        label: 'E-commerce Referrals',
        description: 'Double the performance of your existing referral program.',
      },
      {
        value: 'E-card Recipient Lists',
        label: 'E-card Recipient Lists',
        description: 'Because Excel templates are brutal.',
      },
      {
        value: 'Event Invitations',
        label: 'Event Invitations',
        description: 'Perfect invitation personalization without the extra input fields.',
      },
      {
        value: 'Wishlist Sharing',
        label: 'Wishlist Sharing',
        description:
          'What do you call a wishlist without a contact picker? A shopping list. 🥁',
      },
      {
        value: 'Coupon Sharing',
        label: 'Coupon Sharing',
        description:
          'Make it easy for people to send sweet coupons to a bunch of contacts all at once.',
      },
      {
        value: 'Crowdfunding Campaigning',
        label: 'Crowdfunding Campaigning',
        description:
          'Let campaign creators and their backers spread the word using their address books.',
      },
      {
        value: 'Gift Registry Invitations',
        label: 'Gift Registry Invitations',
        description:
          'Easily create large recipient lists, including names, emails and mailing addresses.',
      },
      {
        value: 'Find-a-Friend on Social Networks',
        label: 'Find-a-Friend on Social Networks',
        description:
          'Connect users with everyone they know and send invitations to everyone else.',
      },
      {
        value: 'Newsletter Refer-a-Friend Programs',
        label: 'Newsletter referrals are so hot right now. #bluesteel',
      },
    ],
    stepKey: 'useCase',
    showOtherOption: true,
  },
];

export const useGauntletWelcome = () => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const [otherOption, setOtherOption] = useState(false);

  const { updateAccountData } = useAuth();

  const currentStep = useMemo(() => {
    return steps[step];
  }, [step]);

  const currentStepValue = useMemo(() => {
    return formValues[currentStep.stepKey] || '';
  }, [step, formValues]);

  const handleStepValueChange = (value: string) => {
    setFormValues((prev) => ({ ...prev, [currentStep.stepKey]: value }));
  };

  const handleNext = () => {
    setStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  const handleOtherOption = (value: boolean) => {
    setOtherOption(value);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const res = await updateAccount({
      industry: formValues.industry,
      useCases: formValues.useCase,
      // multiSite: showMultiSite,
      multiSite: 0,
    });

    updateAccountData(res);
    setLoading(false);
  };

  useEffect(() => {
    if (currentStepValue === '') {
      handleOtherOption(false);
      return;
    }

    if (!!currentStep.options.find((option) => option.value === currentStepValue)) {
      handleOtherOption(false);
      return;
    }
    setOtherOption(true);
  }, [currentStep]);

  const showSubmit = useMemo(() => {
    return step === steps.length - 1;
  }, [step]);

  const showBack = useMemo(() => {
    return step > 0;
  }, [step]);

  return {
    currentStep,
    currentStepValue,
    otherOption,
    showBack,
    showSubmit,
    loading,
    handleStepValueChange,
    handleNext,
    handleBack,
    handleSubmit,
    handleOtherOption,
  };
};
