import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { RoutePaths } from '../config/route-paths';
import { Account, User } from '../shared/types';
import SubscriptionStatus from './SubscriptionStatus';
import UserAvatar from './UserAvatar';

type Props = {
  me: User;
  subscription: Account['subscription'];
  signout: () => void;
};

const HeaderUserDropdown = ({ me, subscription, signout }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateTo = (path: string) => {
    navigate(path, { state: { from: location } });
  };

  const handleSignOut = async () => {
    await signout();
    navigate(RoutePaths.SIGNIN);
  };
  return (
    <>
      <Dropdown>
        <DropdownButton
          variant="link"
          title={<UserAvatar me={me} />}
          bsPrefix="btn btn-text p-0 rounded-circle no-caret"
        >
          <Dropdown.Header>
            <SubscriptionStatus subscription={subscription} />
          </Dropdown.Header>
          {me.is_super_user &&
            <Dropdown.Item onClick={() => (window.location.href = "/admin/dashboard")}>
              Admin Portal
            </Dropdown.Item>
          }
          {me.v2Enabled &&
            <Dropdown.Item onClick={() => (window.location.href = "/app?from=v3")}>
              Legacy Portal
            </Dropdown.Item>
          }
          <Dropdown.Item onClick={() => navigateTo(RoutePaths.PROJECTS)}>
            My Projects
          </Dropdown.Item>
          <Dropdown.Item onClick={() => navigateTo(RoutePaths.PAYMENT)}>
            My Billing
          </Dropdown.Item>
          <Dropdown.Item onClick={() => navigateTo(RoutePaths.PROFILE)}>
            My Profile
          </Dropdown.Item>
          <Dropdown.Item onClick={() => navigateTo(RoutePaths.TEAM)}>
            My Team
          </Dropdown.Item>
          
          {!!subscription.referral_code && 
            <Dropdown.Item onClick={() => navigateTo(RoutePaths.REFERRALS)}>
              My Referrals
            </Dropdown.Item>
          }
          <Dropdown.Item onClick={handleSignOut}>Sign Out</Dropdown.Item>
        </DropdownButton>
      </Dropdown>
    </>
  );
};

export default HeaderUserDropdown;
