import { Suspense } from 'react';
import { Stack } from 'react-bootstrap';
import { Await, useLoaderData } from 'react-router-dom';

import Loading from '../components/Loading';
import Page from '../components/Page';
import PageHeader from '../components/PageHeader';
import PageSection from '../components/PageSection';
import TeamList from '../components/TeamList';
import { User } from '../shared/types';

const Team = () => {
  const { data } = useLoaderData() as { data: User[] };

  return (
    <Page size="lg">
      <PageHeader>
        <Stack gap={2} direction="horizontal" className="align-items-center">
          <h1>My Team</h1>
        </Stack>
      </PageHeader>
      <PageSection>
        <Suspense fallback={<Loading position="fullheight" />}>
          <Await resolve={data} errorElement={<div>Could not load team members 😬</div>}>
            {(data) => <TeamList data={data} />}
          </Await>
        </Suspense>
      </PageSection>
    </Page>
  );
};

export default Team;
