import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';

import GauntletPayment from '../components/GauntletPayment';
import GauntletWelcome from '../components/GauntletWelcome';
import Page from '../components/Page';
import { useAuth } from '../contexts/authContext';

const Gauntlet = () => {
  const { gauntletStep } = useAuth();

  return (
    <>
      {!gauntletStep ? (
        <Outlet />
      ) : (
        gauntletStep === 'welcome' && (
          <Page size="sm">
            <GauntletWelcome />
          </Page>
        )
      )}
      {gauntletStep === 'payment' && (
        <Page size="xl">
          <GauntletPayment />
        </Page>
      )}
    </>
  );
};

export default Gauntlet;
