import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

export const SelectDropdown = (props: {
  children?: React.ReactNode | React.ReactNode[];
  show?: boolean;
}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(props.show || false);
  const toggle = () => setDropdownOpen((prev) => !prev);
  return (
    <Dropdown show={dropdownOpen} onToggle={toggle}>
      {props.children}
    </Dropdown>
  );
};
