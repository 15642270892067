import classnames from 'classnames';
import React, { useMemo } from 'react';
import { Spinner, SpinnerProps } from 'react-bootstrap';

type Props = {
  position?: 'default' | 'overlay' | 'fullscreen' | 'fullheight';
  variant?: SpinnerProps['variant'];
  size?: SpinnerProps['size'];
  className?: string;
};

const Loading = ({ position = 'default', variant, size, className }: Props) => {
  const positionClassname = useMemo(() => {
    switch (position) {
      case 'overlay':
        return 'loading--overlay';

      case 'fullscreen':
        return 'loading--fullscreen';

      case 'fullheight':
        return 'loading--fullheight';

      default:
        return '';
    }
  }, [position]);

  return (
    <div className={classnames('loading', positionClassname, className)}>
      {/* tailwindcss.com/docs/transition-property */}
      <Spinner animation="border" variant={variant} size={size} />
    </div>
  );
};

export default Loading;
