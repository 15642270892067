//@TODO obsolete component ?
import DevSitesList from './DevSiteList';
import ProdSitesList from './ProdSiteList';

const SitesList = () => {
  return (
    <>
      <ProdSitesList />
      <DevSitesList />
    </>
  );
};

export default SitesList;
