import React, { Suspense, useEffect } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  redirect,
} from 'react-router-dom';

import { RoutePaths } from '../config/route-paths';
import { credentialsActions, fetchCredentials } from '../services/credentials';
import { fetchIntegrations } from '../services/integrations';
import { fetchProjectById, fetchProjects, projectsActions } from '../services/projects';
import { fetchSites, siteActions } from '../services/sites';
import { fetchUsers, teamActions } from '../services/user';
import ConfirmationRequired from '../views/ConfirmationRequired';
import ConfirmationToken from '../views/ConfirmationToken';
import Gauntlet from '../views/Gauntlet';
import Install from '../views/Install';
import Metrics from '../views/Metrics';
import MyProjects from '../views/MyProjects';
import NewProject from '../views/NewProject';
import NotFound from '../views/NotFound';
import Payment from '../views/Payment';
import Profile from '../views/Profile';
import ProviderCredentials from '../views/ProviderCredentials';
import ProviderCredentialsDetails from '../views/ProviderCredentialsDetails';
import ResetPassword from '../views/ResetPassword';
import ResetPasswordToken from '../views/ResetPasswordToken';
import Signin from '../views/Signin';
import Signup from '../views/Signup';
import Sites from '../views/Sites';
import Team from '../views/Team';
import LayoutAuthed from './LayoutAuthed';
import LayoutGuest from './LayoutGuest';
import Loading from './Loading';
import Referrals from '../views/Referrals';
import Zapier from '../views/Zapier';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Guest */}
      <Route element={<LayoutGuest />}>
        <Route path={`${RoutePaths.SIGNIN}`} index element={<Signin />} />
        <Route path={`${RoutePaths.SIGNUP}`} element={<Signup />} />
        <Route path={`${RoutePaths.RESET_PASSWORD}`} element={<ResetPassword />} />
        <Route
          path={`${RoutePaths.RESET_PASSWORD}/${RoutePaths.CONFIRMATION_TOKEN}`}
          element={<ResetPasswordToken />}
        />
        <Route
          path={`${RoutePaths.RESET_PASSWORD_ACCEPT}/${RoutePaths.CONFIRMATION_TOKEN}`}
          element={<ResetPasswordToken inviting />}
        />
        <Route path={`${RoutePaths.CONFIRMATION}`} element={<ConfirmationRequired />} />
        <Route path="*" loader={() => redirect(RoutePaths.SIGNIN) } />
      </Route>

      <Route
        path={`${RoutePaths.CONFIRMATION}/${RoutePaths.CONFIRMATION_TOKEN}`}
        element={<ConfirmationToken />}
      />

      {/* Authed */}
      <Route element={<LayoutAuthed />}>
        <Route element={<Gauntlet />}>
          <Route
            path={`${RoutePaths.PROJECTS}`}
            element={<MyProjects />}
            loader={async () => {
              const data = await fetchProjects();
              return data.length === 0
                ? redirect(`${RoutePaths.PROJECTS}/${RoutePaths.NEW_PROJECT}`)
                : defer({ data });
            }}
            errorElement={<div>Could not load projects 😬</div>}
          />

          <Route
            path={`${RoutePaths.PROJECTS}/${RoutePaths.NEW_PROJECT}`}
            element={<NewProject />}
            action={projectsActions}
          />

          {/* Project */}
          <Route
            id="project"
            loader={async ({ params }) => {
              const { projectKey } = params;
              if (!projectKey) {
                return {};
              }
              const data = await fetchProjectById(projectKey);

              return defer({ data, projectId: data.id });
            }}
            errorElement={<NotFound />}
          >
            {/* Sites */}
            <Route
              id="sites"
              loader={async ({ params }) => {
                const { projectKey } = params as any;

                const data = fetchSites(projectKey);
                return defer({ data: await data });
              }}
            >
              <Route
                path={`${RoutePaths.PROJECTS}/${RoutePaths.PROJECT_KEY}/${RoutePaths.METRICS}`}
                index
                element={<Metrics context="dashboard" />}
                handle={{
                  crumb: () => <>Metrics</>,
                }}
              />

              <Route
                path={`${RoutePaths.PROJECTS}/${RoutePaths.PROJECT_KEY}/${RoutePaths.SITES}`}
                index
                element={<Sites />}
                handle={{
                  crumb: () => <>Sites</>,
                }}
                action={siteActions}
              />

              <Route
                path={`${RoutePaths.PROJECTS}/${RoutePaths.PROJECT_KEY}/${RoutePaths.SITES}/${RoutePaths.SITE_ID}`}
                element={<Metrics context="site" />}
                handle={{
                  crumb: () => <>Sites Metrics</>,
                }}
              />

              <Route
                id="credentials"
                loader={({ params }) => {
                  const { projectKey } = params;
                  return fetchCredentials(projectKey);
                }}
                handle={{
                  crumb: () => <>Provider Credentials</>,
                }}
              >
                <Route
                  path={`${RoutePaths.PROJECTS}/${RoutePaths.PROJECT_KEY}/${RoutePaths.PROVIDER_CREDENTIALS}`}
                  element={<ProviderCredentials />}
                />
                <Route
                  path={`${RoutePaths.PROJECTS}/${RoutePaths.PROJECT_KEY}/${RoutePaths.PROVIDER_CREDENTIALS}/${RoutePaths.PROVIDER_ID}`}
                  element={<ProviderCredentialsDetails />}
                  loader={({ params }) => {
                    return {
                      provider: params.provider,
                    };
                  }}
                  action={credentialsActions}
                  handle={{
                    crumb: (context: any) => {
                      return <span className="text-capitalize">{context.provider}</span>;
                    },
                  }}
                />
              </Route>

              <Route
                path={`${RoutePaths.PROJECTS}/${RoutePaths.PROJECT_KEY}/${RoutePaths.INSTALL}`}
                element={<Install />}
                id="install"
                loader={() => {
                  const data = fetchIntegrations();
                  return defer({ data });
                }}
                handle={{
                  crumb: () => <>Install</>,
                }}
              />

              <Route
                path={`${RoutePaths.PROJECTS}/${RoutePaths.PROJECT_KEY}/${RoutePaths.ZAPIER}`}
                element={<Zapier />}
                id="zapier"
                handle={{
                  crumb: () => <>Zapier</>,
                }}
              />

            </Route>

          </Route>
          <Route path={`${RoutePaths.PAYMENT}`} element={<Payment />} />
        </Route>

        <Route path={`${RoutePaths.PROFILE}`} element={<Profile />} />

        <Route
          path={`${RoutePaths.TEAM}`}
          element={<Team />}
          loader={() => {
            const data = fetchUsers();
            return defer({ data });
          }}
          action={teamActions}
          errorElement={<div>Could not load team members 😬</div>}
        />
        <Route path={`${RoutePaths.REFERRALS}`} element={<Referrals />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </>,
  ),
  {
    basename: `${RoutePaths.BASE}`,
  },
);
if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default function Router() {
  return <RouterProvider router={router} fallbackElement={<Loading position="fullscreen" />} />;
}
