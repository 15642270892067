import React, { useRef, useState } from 'react';

import { Button, Collapse, FormGroup, Form, FormLabel, InputGroup } from 'react-bootstrap';
import FaIcon from './FaIcon';
import { toast } from 'react-toastify';
import config from '../config/config';
import { marketingSiteUrl } from '../config/external-urls';
import { useAuth } from '../contexts/authContext';
import AddressBookConnector from './AddressBookConnector';

const ReferralForm = () => {
  const { user: me, account } = useAuth();
  const [contacts, setContacts] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const contactsInput = useRef(null);

  if (!me) return null;

  const referralUrl = `${marketingSiteUrl}/?ref=${account?.subscription?.referral_code}`;
  const body = `Your friend wanted you to have exclusive access to this deal: 10% off your CloudSponge subscription for 12 months when you subscribe!\n\nCheck it out: ${referralUrl}\n\nCloudSponge is a contact picker for your website. We integrate with over 25 different webmail providers.\n\nReply to this email if you have any questions or would like a demo, we'd love to hear from you.\n\nThanks!\n\nThe CloudSponge Team`;
  const ownerJson = JSON.stringify({
    first_name: me.first_name,
    last_name: me.last_name,
    email: me.email,
  });

  const previewToggle = () => {
    setPreviewOpen(!previewOpen);
  };

  const onSuccess = () => {
    toast(`We sent an email to ${contacts} for you.`, {type: 'success'});
    setContacts('');
  };

  const onError = (error: {status: number, responseText: string}) => {
    let detail = error.status == 429 ? "There have been too many requests lately. Please wait a bit before trying again." : `${error.responseText} ${error.status}`
    toast(`We couldn't send your email. ${detail}.`, {type: 'warning'});
  };
  
  return (
    <>
      <AddressBookConnector
        cloudspongeKey={config.CLOUDSPONGE_KEYS.FOR_REFERRALS}
        onSubmit={() => setIsSubmitting(true)}
        onComplete={() => setIsSubmitting(false)}
        onSuccess={onSuccess}
        onError={onError}
      >
        <input type="hidden" name="refUrl" defaultValue={referralUrl} />
        <input type="hidden" name="owner" id="owner" data-address-book-connector-json-data={ownerJson}/>

        <FormGroup>
          <FormLabel>Tell us who to invite:</FormLabel>
          <InputGroup>
            <Form.Control
              as="input"
              ref={contactsInput}
              type="text"
              name="contacts"
              value={contacts}
              onChange={(event) => setContacts(event.currentTarget.value)}
              className="form-control cloudsponge-contacts"
              placeholder="enter some email addresses or pick them from your address book by clicking the button"
              required={true}
              aria-describedby="#emailHelp"
            />
            <a className="cloudsponge-launch btn btn-primary">
              <FaIcon icon="address-card"/> Add from Address Book
            </a>
          </InputGroup>
          <small className="form-text text-muted" id="emailHelp">Separate multiple emails with commas.</small>
        </FormGroup>

        <FormGroup className='my-2'>
          <Button color="primary" disabled={isSubmitting} type="submit">
            <FaIcon icon="paper-plane"/>&nbsp;
            <span>Send The Invitation</span>
          </Button>
        </FormGroup>

        <FormGroup>
          <a href="javascript:void(0)" onClick={previewToggle}>Preview the email</a>
          <Collapse in={previewOpen} className="mt-2">
            <Form.Control as="textarea" name="body" readOnly={true} className="form-control email-refer" rows={12} value={body}/>
          </Collapse>
        </FormGroup>
      </AddressBookConnector>
    </>
  );
}

export default ReferralForm;
