import React from 'react';
import { Badge, Stack } from 'react-bootstrap';
import { HiOutlineChartBarSquare } from 'react-icons/hi2';
import { LinkContainer } from 'react-router-bootstrap';

import { Site } from '../shared/types';
import ButtonIcon from './ButtonIcon';
import ContentCard from './ContentCard';
import FormSiteDelete from './FormSiteDelete';
import SitesLastLoadPill from './SitesLastLoadPill';

type Props = {
  site: Site;
};

const SitesListCard = ({ site }: Props) => {
  return (
    <ContentCard key={site.id} className="py-1 pe-1">
      <Stack gap={2} direction="horizontal" className="justify-content-between">
        <Stack gap={2} direction="horizontal" className="justify-content-between">
          <strong className="fw-semibold">{site.name}</strong>
          {site.alias ? <Badge bg="info">Alias</Badge> : null}
        </Stack>

        <div className="flex-shrink-0">
          <Stack gap={1} direction="horizontal">
            <SitesLastLoadPill site={site} />
            <LinkContainer to={`${site.id}`}>
              <ButtonIcon>
                <HiOutlineChartBarSquare className="icon icon--stroke" />
              </ButtonIcon>
            </LinkContainer>
            <FormSiteDelete site={site} />
          </Stack>
        </div>
      </Stack>
    </ContentCard>
  );
};

export default SitesListCard;
