import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RoutePaths } from '../config/route-paths';
import { useAuth } from '../contexts/authContext';
import analytics from '../libs/analytics';
import { Account, User } from '../shared/types';

const ConfirmationToken = () => {
  const { token } = useParams() as { token: string };
  const { signinWithToken, isAuthed } = useAuth();

  const navigate = useNavigate();

  const confirm = useCallback(async () => {
    if (isAuthed) {
      navigate(RoutePaths.PROJECTS);
      return;
    }

    try {
      const data = (await signinWithToken(token)) as unknown as {
        me: User;
        account: Account;
      };

      navigate(RoutePaths.PROJECTS);

      const userData = {
        ...data.me,
        company_name: data.account.name,
        last_signed_in_at: data.me.current_sign_in_at,
        account_owner: data.me.owner,
        role: data.me.is_administrator ? 'customer_admin' : 'developer',
        chargify_state: data.account.subscription.state,
        activated_at: data.me.confirmed_at,
      };
      const accountData = {
        ...data.account,
        chargify_state: data.account.subscription.state,
      };

      analytics.track('Signed in', userData);

      if (data.me.first_sign_in) {
        if (data.account.subscription.partner == 'appsumo') {
          toast('Welcome to your CloudSponge account, Sumo-ling!', {
            type: 'success',
          });
        } else {
          toast('Welcome to your CloudSponge account!', {
            type: 'success',
          });
        }
        analytics.track('User signed in (first time)', userData);

        if (data.account.newly_activated) {
          analytics.track('Account activated', accountData);
        }
      } else {
        toast(`Welcome back, ${data.me.first_name}.`, {
          type: 'success',
        });
      }
      analytics.track('User signed in', userData);
    } catch (error: any) {
      navigate(RoutePaths.CONFIRMATION);

      if (error.status == 410) {
        toast('The token is expired. Please request a new confirmation email.', {
          type: 'error',
        });
      } else if (error.status == 404) {
        // code not found
        toast(
          'There was a problem trying to confirm your email address. Please request a new confirmation email or sign up a new account.',
          {
            type: 'error',
          },
        );
      } else {
        // bad request
        toast(
          'There was a problem trying to confirm your email address. Please request a new confirmation email or sign up a new account.',
          {
            type: 'error',
          },
        );
      }
    }
  }, [isAuthed]);

  useEffect(() => {
    confirm();
  }, []);

  return <></>;
};

export default ConfirmationToken;
