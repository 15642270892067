import { Bar, HorizontalBar, Line } from 'react-chartjs-2';

type MetricsChartProps = {
  type: string;
  data: any;
  width: number;
  height?: number;
  options: any;
};
const MetricsChart = ({ type, width, height, data, options }: MetricsChartProps) => {
  return (
    <>
      {type === 'bar' && (
        <Bar data={data} width={width} height={height} options={options} />
      )}
      {type === 'horizontalBar' && (
        <HorizontalBar data={data} width={width} height={height} options={options} />
      )}
      {type === 'line' && (
        <Line data={data} width={width} height={height} options={options} />
      )}
    </>
  );
};
export default MetricsChart;
