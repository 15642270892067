type EmptyChartProps = {
  text: string;
};
const EmptyChart = ({ text }: EmptyChartProps) => {
  return (
    <div className="d-flex flex-column gap-2 align-items-center justify-content-center py-6 fs-7 text-gray-light">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="64"
          height="64"
          className="icon icon--lg text-gray-lightest"
        >
          <path fill="none" d="M0 0H24V24H0z" />
          <path d="M5 3v16h16v2H3V3h2zm15.293 3.293l1.414 1.414L16 13.414l-3-2.999-4.293 4.292-1.414-1.414L13 7.586l3 2.999 4.293-4.292z" />
        </svg>
      </span>
      <span>{text}</span>
    </div>
  );
};

export default EmptyChart;
