import React from 'react';

import { Account } from '../shared/types';
import ContentCard from './ContentCard';
import ExternalLink from './ExternalLink';

type Props = {
  subscription: Account['subscription'];
  numSites: number;
  numBooks: number;
  tierName: string;
};

const AppsumoTier = ({ subscription, numSites, numBooks, tierName }: Props) => {
  if (subscription.state == 'canceled') {
    return (
      <>
        <p>
          Your license was canceled. But it's not too late! You can still purchase an{' '}
          <ExternalLink href="https:\/\/appsumo.com/products/cloudsponge-contact-picker/">
            AppSumo Lifetime plan here
          </ExternalLink>
          .
        </p>
      </>
    );
  }
  return (
    <>
      <h1 className="mb-4 mb-sm-5 text-center">Your license:</h1>
      <ContentCard>
        <h2 className="h3 mb-4">{tierName}</h2>

        <ul>
          <li className="fw-semibold text-dark">
            {numSites} {numSites == 1 ? 'website' : 'websites'}
          </li>
          <li className="fw-semibold text-dark">Unlimited address books</li>
          <li>Custom Stylesheet and Fonts</li>
          <li>Custom Languages and Labels</li>
          <li>Your Brand White-Labeled</li>
          <li>RESTful Contacts API</li>
          <li>Integrations</li>
        </ul>
        <div className="mt-4">
          <ExternalLink
            className="btn btn-primary"
            href={subscription.billing_portal_url}
          >
            Manage your license
          </ExternalLink>
        </div>
      </ContentCard>
    </>
  );
};

export default AppsumoTier;
