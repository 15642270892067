import React from 'react';
import { Button } from 'react-bootstrap';
import { HiCheckCircle } from 'react-icons/hi2';

import { Account } from '../shared/types';

type Props = {
  name: string;
  features: string[];
  price: string;
  handle: string;
  updatePlan: (plan: string, name: string, price_point: string) => void;
  subscription?: Account['subscription'];
  loading: boolean;
  price_points: {
    monthly?: {
      handle: string;
      price: string;
    };
    yearly: {
      handle: string;
      price: string;
    };
  };
};

const PlanPickerItem = (props: Props) => {
  const {
    name,
    features,
    price,
    handle,
    updatePlan,
    subscription,
    loading,
    price_points,
  } = props;

  const { monthly, yearly } = price_points;

  return (
    <div className="plan-picker-item">
      <div className="plan-picker-item__header">
        <h2 className="plan-picker-item__title fs-3">{name}</h2>
      </div>
      <div className="plan-picker-item__body">
        <h3 className="plan-picker-item__price">{monthly?.price || yearly.price}</h3>
        <ul className="plan-picker-item__features">
          {features.map((feature, index) => (
            <li key={index} className="d-flex gap-2 mb-2">
              <HiCheckCircle className="icon text-primary" />
              {feature}
            </li>
          ))}
        </ul>
        <div className="d-flex flex-column gap-2 mt-auto">
          {monthly && (
            <div className="w-100">
              <Button
                onClick={() => updatePlan(handle, name, monthly.handle)}
                disabled={loading}
                className="w-100"
              >
                {monthly.price}
              </Button>
            </div>
          )}

          <div className="w-100">
            <Button
              disabled={loading}
              variant={monthly?.price ? "outline-primary" : "primary"}
              onClick={() => updatePlan(handle, name, yearly.handle)}
              className="w-100"
            >
              {yearly.price}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanPickerItem;
