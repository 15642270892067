import { axios } from '../utils/utils';
import { ActionFunctionArgs, LoaderFunctionArgs } from 'react-router-dom';

import config from '../config/config';
import { trackedEvents } from '../config/tracked-events';
import analytics from '../libs/analytics';
import { SitesLoaderArgs } from '../shared/types';

const LOCAL_STORAGE_KEY = 'sites';
/**
 * Used to paginate
 * sites
 * query by segment and page
 * The response contains total number of pages!
 * @param param
 * @returns
 */
export const fetchSitesByQuery = async ({ params }: LoaderFunctionArgs) => {
  try {
    const res = await axios.get(
      `${config.DATA_PATH}/keys/${params.id}/sites?segment=production`,
    );
    return res.data;
  } catch (error) {
    console.error({ error });
    return Promise.reject(error);
  }
};
/**
 * Fetches initial
 * sites load
 * unites production and test
 * sites in the result
 * The response contains total number of pages!
 *
 * @param param
 * @returns
 */
export const fetchSites = async (projectId: string) => {
  try {
    const prodRes = await axios.get(
      `${config.DATA_PATH}/keys/${projectId}/sites?segment=production`,
    );
    const devRes = await axios.get(
      `${config.DATA_PATH}/keys/${projectId}/sites?segment=development`,
    );

    return { productionSites: prodRes.data, devSites: devRes.data };
  } catch (error) {
    console.error({ error });
    return Promise.reject(error);
  }
};

export const fetchSite =async (siteId: string) => {
  try {
    const res = await axios.get(
      `${config.DATA_PATH}/sites/${siteId}`,
    );

    return res.data;
  } catch (error) {
    console.error({ error });
    return Promise.reject(error);
  }  
}

/**
 * Fetches sites with
 * a query string
 * to provide pagination
 * and filtering by name
 * @param params
 * @returns
 */
export const fetchSitesWithQueryStr = async (params: SitesLoaderArgs) => {
  const page = params.page ? params.page : 1;
  const filter = params.filter ? params.filter : '';
  const segment = params.segment;

  try {
    const fetchParams = { page, filter, segment };
    const res = await axios.get(`${config.DATA_PATH}/keys/${params.projectId}/sites`, {
      params: fetchParams,
    });
    if (segment === 'production') {
      return { productionSites: res.data };
    } else {
      return { devSites: res.data };
    }
  } catch (error) {
    console.error({ error });
    return Promise.reject(error);
  }
};

export async function siteActions({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData();
  if (formData.get('action') === 'create') {
    const siteUrl = formData.get('siteUrl');
    const projectId = formData.get('projectId');
    try {
      const requestParams = {
        name: siteUrl,
      };
      const res = await axios.post(`${config.DATA_PATH}/keys/${projectId}/sites`, {
        ...requestParams,
      });
      analytics.track(trackedEvents.userSetSite, { name: res.data.name, enabled: res.data.enabled, production: res.data.production });
      return { ok: true, site: res.data };
    } catch (error: any) { 
      //check for a certain error - site name alredy exists 
      if( error.response && error.response.status && error.response.status === 400){
        if (error.response.data.errors.name[0] === config.RESPONSE_ERRORS.SITE_NAME_EXISTS){//it is an array of names
          return Promise.resolve({error: config.RESPONSE_ERRORS.SITE_NAME_EXISTS, site_name: error.response.data.name });
        }
      }
      return Promise.reject(error);//return a general Error object with a general error cause
    }
  }
  //@TODO - check analytics.track
  if (formData.get('action') === 'delete') {
    const siteId = formData.get('siteId');
    try {
      const res = await axios.delete(`${config.DATA_PATH}/sites/${siteId}`);
      analytics.track(trackedEvents.userDeletedSite);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }

    return { ok: true };
  }
  if (formData.get('action') === 'convertToDev') {
    const siteId = formData.get('siteId');
    try {
      const requestParams = {
        id: siteId,
      };
      const res = await axios.put(`${config.DATA_PATH}/sites/${siteId}/dev`, {
        ...requestParams,
      });
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
    return { ok: true };
  }

  return new Response(null);
}
