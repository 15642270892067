export const supportEmail = 'support@cloudsponge.com';

export const marketingSiteUrl = 'https://www.cloudsponge.com';

export const socialUrls = {
  slack: 'https://slack.cloudsponge.com/',
  twitter: 'https://twitter.com/CloudSponge',
  facebook: 'https://www.facebook.com/CloudSponge/',
  calendly: 'https://www.cloudsponge.com/demo/',
};

export const developerUrls = {
  docs: `${marketingSiteUrl}/developer/`,
  basicInstall: `${marketingSiteUrl}/developer/address-book-widget/basic-installation/`,
  deepLinks: `${marketingSiteUrl}/developer/address-book-widget/linking-options/deep-links/`,
  deepLinkExample: `${marketingSiteUrl}/address-book-widget/examples/deep-links/`,
  customStylesheets: `${marketingSiteUrl}/developer/address-book-widget/options/css/`,
  webhooksApi: `${marketingSiteUrl}/developer/address-book-widget/webhooks-api/`,
  options: `${marketingSiteUrl}/developer/address-book-widget/options/`,
  dataStructure: `${marketingSiteUrl}/developer/address-book-widget/data-structure/`,
  callbacks: `${marketingSiteUrl}/developer/address-book-widget/javascript-callbacks/`,
  examples: `${marketingSiteUrl}/address-book-widget/examples/`,

  learnAboutKeys: `${marketingSiteUrl}/developer/getting-started/keys/`,
  learnAboutOauth: `${marketingSiteUrl}/developer/oauth/oauth-credentials/`,
  learnAboutProxyUrl: `${marketingSiteUrl}/developer/oauth/proxy-url/`,
  howToInstall: `${marketingSiteUrl}/developer/contact-picker/basic-installation/`,

  restApi: `${marketingSiteUrl}/developer/contacts-api/`,
  // videos
  oauthVideos: {
    gmail: [
      {
        title: 'Creating Google OAuth',
        url: 'https://youtube.com/playlist?list=PLRqR-OxeQ97o_vELZH5VnSAv6v2jIFSBC',
      },
      {
        title: 'Getting Google OAuth Approval',
        url: 'https://youtu.be/yeIR0OvGLb0',
      },
    ],
    proxyUrl: '', // we don't have this one, yet...
  },
  oauthSetup: `${marketingSiteUrl}/setup-concierge/`,
  oauth: {
    gmail: `${marketingSiteUrl}/developer/oauth/oauth-credentials/google/`,
    yahoo: `${marketingSiteUrl}/developer/oauth/oauth-credentials/yahoo/`,
    outlookcom: `${marketingSiteUrl}/developer/oauth/oauth-credentials/microsoft/`,
  },
  microsoftAzurePortal:
    'https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationsListBlade',
  yahooForm: 'https://senders.yahooinc.com/developer/developer-access-mail-form',
  yahooPortal: 'https://developer.yahoo.com/apps/',
  proxyGist: 'https://gist.github.com/dangerouse/ff9b5a5d0c070734542d',
  proxyFile:
    'https://gist.github.com/dangerouse/ff9b5a5d0c070734542d/archive/07bb29ffd84d4ea29dd74c0aa9012cb5ab35e17e.zip',
};

export const footerUrls = {
  // solutions:
  referral: `${marketingSiteUrl}/solutions/referrals/`,
  invitations: `${marketingSiteUrl}/solutions/invitations/`,
  importing: `${marketingSiteUrl}/solutions/contact-importing/`,
  // products:
  demo: `${marketingSiteUrl}/test-drive/`,
  faq: `${marketingSiteUrl}/help/`,
  widget: `${marketingSiteUrl}/address-book-widget/`,
  restApi: `${marketingSiteUrl}/contacts-api/`,
  industries: `${marketingSiteUrl}/industry/`,
  // resources:
  documentation: `${marketingSiteUrl}/developer/`,
  blog: `${marketingSiteUrl}/blog/`,
  integrations: `${marketingSiteUrl}/integrations/`,
  security: `${marketingSiteUrl}/security/`,
  changelog: `${marketingSiteUrl}/changelog/`,
  status: `${marketingSiteUrl}/contact-importers/`,
  webinar: `${marketingSiteUrl}/webinar/`,
  // company:
  about: `${marketingSiteUrl}/about/`,
  news: `${marketingSiteUrl}/news/`,
  contact: `${marketingSiteUrl}/contact/`,
  slack: socialUrls.slack,
  twitter: socialUrls.twitter,
  // bottom of footer
  terms: `${marketingSiteUrl}/terms/`,
  privacy: `${marketingSiteUrl}/privacy/`,
  gdpr: `${marketingSiteUrl}/news/2018/05/25/cloudsponge-gdpr-compliance/`,
};
