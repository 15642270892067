import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';

import App from './App';
import './assets/scss/app.scss';

Sentry.init({
  dsn: 'https://7013731ebc0f4f7b967861056112faaf@o33192.ingest.sentry.io/1232322',
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // specify release version
  release: '3.0.1',
  // 
  environment: import.meta.env.MODE,
});

ReactDOM.createRoot(document.getElementById('customer') as HTMLElement).render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <CookiesProvider>
      <App />
    </CookiesProvider>
    {/* </PersistGate> */}
    {/* </Provider> */}
  </React.StrictMode>,
);
