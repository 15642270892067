import React, { useEffect } from 'react';
import { Button, Collapse, Form, Stack } from 'react-bootstrap';
import { HiArrowLeft } from 'react-icons/hi2';
import { toast } from 'react-toastify';

import { supportEmail } from '../config/external-urls';
import { useGauntletWelcome } from '../hooks/useGauntletWelcome';
import ButtonIcon from './ButtonIcon';
import ButtonSubmit from './ButtonSubmit';
import ContentCard from './ContentCard';
import ExternalLink from './ExternalLink';
import PageSection from './PageSection';
import { trackClick } from '../libs/analytics';

const OptionLabel = ({
  option: { value, description },
}: {
  option: { value: string; description?: string };
}) => {
  return (
    <Stack gap={0} direction="vertical">
      <span className="text-dark">{value}</span>
      {description && <span className="text-muted fs-9">{description}</span>}
    </Stack>
  );
};

const GauntletWelcome = () => {
  const {
    currentStep,
    currentStepValue,
    otherOption,
    showSubmit,
    showBack,
    handleStepValueChange,
    handleNext,
    handleBack,
    handleSubmit,
    handleOtherOption,
    loading,
  } = useGauntletWelcome();

  const handleClick = (e) => {
    trackClick(e, {
      title: currentStep.title,
      name: currentStep.stepKey,
      value: currentStepValue
    });
    if (showSubmit) {
      handleSubmit();
    } else {
      handleNext();
    }
  };

  return (
    <>
      <PageSection className="my-xl-auto">
        <h2 className="mb-4 mb-lg-5 text-center">Welcome to your CloudSponge account!</h2>
        <ContentCard className="p-sm-5">
          <Stack gap={3} direction="horizontal" className="align-items-center mb-4">
            {showBack && (
              <>
                <ButtonIcon onClick={handleBack}>
                  <HiArrowLeft className="icon" />
                </ButtonIcon>
              </>
            )}
            <h3 className="m-0">{currentStep.title}</h3>
          </Stack>
          <Form>
            <Stack gap={2} direction="vertical" className="mb-4">
              {currentStep.options.map((option) => (
                <Form.Check
                  name={'step'}
                  type="radio"
                  key={option.value}
                  id={option.value}
                  label={<OptionLabel option={option} />}
                  value={option.value}
                  checked={currentStepValue === option.value}
                  onChange={(e) => {
                    handleOtherOption(false);
                    handleStepValueChange(e.target.value);
                  }}
                />
              ))}

              {currentStep.showOtherOption && (
                <>
                  <Form.Check
                    name={'step'}
                    type="radio"
                    id={'Other'}
                    label={'Other'}
                    value={'Other'}
                    checked={otherOption}
                    onChange={(e) => {
                      handleStepValueChange('');
                      handleOtherOption(e.target.checked);
                    }}
                    className="text-dark"
                  />

                  <Collapse in={otherOption}>
                    <div>
                      <Form.Control
                        type="text"
                        placeholder="Other"
                        value={currentStepValue}
                        onChange={(e) => {
                          handleStepValueChange(e.target.value);
                        }}
                      />
                    </div>
                  </Collapse>
                </>
              )}
            </Stack>
            <div>
              <ButtonSubmit
                variant="primary"
                className="w-100"
                type="button"
                data-event-name={`Gauntlet ${currentStep.stepKey} ${showSubmit ? 'Submit' : 'Next'}`}
                loading={loading}
                onClick={handleClick}
                disabled={currentStepValue === '' || loading}
              >
                {showSubmit ? 'Submit' : 'Next'}
              </ButtonSubmit>
            </div>
          </Form>
        </ContentCard>
        <p className="text-center fs-7 mt-3 mb-0">
          You can always{' '}
          <ExternalLink href={`mailto:${supportEmail}`}>reach out to us</ExternalLink>,
          we're happy to help!
        </p>
      </PageSection>
    </>
  );
};

export default GauntletWelcome;
