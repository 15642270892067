import moment from "moment";

export const METRICS_DATA = {
  typeDetails: [
    {
      id: 0,
      value: 'total_loads',
      chartData: 'loads',
      chartType: 'line',
      name: 'People who Loaded a Page',
      title: 'People who Loaded a Page',
      infoText:
        'The number of people who visited a page that included the Contact Picker script.',
    },
    {
      id: 1,
      value: 'total_engagements',
      chartData: 'engagements',
      chartType: 'line',
      name: 'People who Engaged',
      title: 'People who Engaged',
      infoText:
        'The number of people who launched the Contact Picker or started an address book connection.',
    },
    {
      id: 2,
      value: 'total_connects',
      chartData: 'connects',
      chartType: 'line',
      name: 'People who Connected',
      title: 'People who Connected',
      infoText:
        'The number of people who successfully connected one of more address books using the Contact Picker.',
    },
    {
      id: 3,
      value: 'total_shares',
      chartData: 'shares',
      chartType: 'line',
      name: 'People who Submitted',
      title: 'People who Submitted',
      infoText:
        'The number of people who selected and submitted one or more contacts using the Contact Picker.',
    },
    {
      id: 4,
      value: 'total_connection_rate',
      chartData: 'connection_rates',
      chartType: 'line',
      name: 'Successful Connection Rate',
      title: 'Successful Connection Rate',
      infoText:
        'The percentage or people who successfully connected their address book using the Contact Picker (Engagements / Connected Address Books).',
      suffix: '%',
      decimals: 1,
    },
    {
      id: 5,
      value: 'total_contacts',
      chartData: 'contacts',
      chartType: 'line',
      name: 'Total Contacts',
      title: 'Total Contacts',
      infoText:
        'The total number of contacts in all address books connected using the Contact Picker.',
    },
    {
      id: 6,
      value: 'total_contacts_shared',
      chartData: 'contacts_shared',
      chartType: 'line',
      name: 'Submitted Contacts',
      title: 'Submitted Contacts',
      infoText:
        'The total number of contacts that were selected and submitted to your app using the Contact Picker.',
    },
    {
      id: 7,
      chartData: 'average_contacts_shared',
      chartType: 'line',
      name: 'Average Submitted Contacts',
      title: 'Average Submitted Contacts',
      infoText: 'The average number of contacts submitted using the Contact Picker.',
      decimals: 1,
    },
    {
      id: 8,
      chartDataFetch: 'volume',
      chartType: 'bar',
      name: 'Total Address Books',
      title: 'Total Address Books',
      infoText: 'The number of address books connected using the API.',
      server: true,
    },
    {
      id: 9,
      chartDataFetch: 'volume_by_address_book_provider',
      chartType: 'horizontalBar',
      name: 'Address Books by Provider',
      title: 'Address Books by Provider',
      infoText: 'The distribution of address books by source using the API.',
      server: true,
    },
  ],
  periodIntervals: [
    {
      id: 0,
      value: 'past_week',
      name: 'Last 7 Days',
      dateFrom: moment().subtract(8, 'd').format(),
      dateTo: moment().subtract(1, 'd').format(),
    },
    {
      id: 1,
      value: 'past_month',
      name: 'Last 30 Days',
      dateFrom: moment().subtract(30, 'd').format(),
      dateTo: moment().subtract(1, 'd').format(),
    },
    {
      id: 2,
      value: 'past_year',
      name: 'Last 365 Days',
      dateFrom: moment().subtract(365, 'd').format(),
      dateTo: moment().subtract(1, 'd').format(),
    },
  ],
  sitesGroups: [
    { id: null, name: 'All sites' },
    { id: 'prod', name: 'Live sites' },
    { id: 'dev', name: 'Test sites' },
  ]
  
};
