import React from 'react';
import { Alert, Button, Stack } from 'react-bootstrap';

import { useAuth } from '../contexts/authContext';
import { destroyImpersonation } from '../services/user';

const ImpersonationBanner = () => {
  const { user: me } = useAuth();

  const stopImpersonating = () => {
    destroyImpersonation().then(() => {
      window.location = `/admin/accounts/${me.account_id}`
    });
  }

  return (
    <>
      {me?.impersonation_session &&
        <Alert className="col-md-4 mt-4 mx-auto" variant="danger">
          <Stack direction="horizontal" gap={3}>
            <div>You are impersonating a user.</div>
            <Button size="sm" variant="outline-danger" className="ms-auto" onClick={stopImpersonating}>Stop Impersonating</Button>
          </Stack>
        </Alert>
      }
    </>
  )
}

export default ImpersonationBanner
