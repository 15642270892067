import { Formik, FormikState } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, Overlay, Stack, Tooltip } from 'react-bootstrap';
import {
  HiInformationCircle,
  HiOutlineCheckCircle,
  HiOutlineExclamationTriangle,
  HiPlus,
} from 'react-icons/hi2';
import { useFetcher, useRouteLoaderData } from 'react-router-dom';
import * as yup from 'yup';



import config from '../config/config';
import ButtonIcon from './ButtonIcon';
import ButtonSubmit from './ButtonSubmit';
import Loading from './Loading';
import ModalDialog from './ModalDialog';
import analytics from '../libs/analytics';
type FormValues = {
  siteUrl: string;
};
type FormActions = {
  resetForm: (nextState?: Partial<FormikState<FormValues>>) => void;
};
const FormSiteCreate = () => {
  const fetcher = useFetcher();
  const [success, setSuccess] = useState(false);
  const [errorCreateSite, setErrorCreateSite] = useState<Record<string, string> | null>(
    null,
  );
  const { projectId } = useRouteLoaderData('project') as {
    projectId: string;
  };

  const isLoading = useMemo(() => {
    return fetcher.state === 'loading' || fetcher.state === 'submitting';
  }, [fetcher.state]);

  const schema = yup.object({
    siteUrl: yup
      .string()
      .required('Site is required')
      // .test('siteUrl', 'Site domain is not valid', function (value) {
      //   const regexUrl =
      //     /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

      //   const validUrl = regexUrl.test(value as string);
      //   const isLocalhost = value === 'localhost';
      //   if (validUrl || isLocalhost) return true;
      //   return true;
      // }),
  });

  const handleSubmit = (values: FormValues, { resetForm }: FormActions) => {
    const formData = new FormData();
    formData.append('siteUrl', values.siteUrl);
    formData.append('action', 'create');
    formData.append('projectId', projectId);
    fetcher.submit(formData, {
      method: 'post',
    });

    resetForm({ values: { siteUrl: '' } });
  };
  const handleSuccessClose = () => {
    setSuccess(false);
  };
  const handleErrorClose = () => {
    setErrorCreateSite(null);
  };
  useEffect(() => {
    if (!fetcher.data || fetcher.state !== 'idle') return;
    if (
      fetcher.data?.error &&
      fetcher.data?.error === config.RESPONSE_ERRORS.SITE_NAME_EXISTS
    ) {
      setErrorCreateSite({
        message: config.RESPONSE_ERRORS.SITE_NAME_EXISTS,
        site_name: fetcher.data.site_name,
      });
      return;
    }
    setSuccess(true);
  }, [fetcher.data, fetcher.state]);

  const [showToolTip, setShowToolTip] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          siteUrl: '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors, resetForm }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="flex-grow-1">
              <Form.Label className="fw-semibold text-dark">
                Where would you like to install the Contact Picker?
                <ButtonIcon ref={target} onClick={() => setShowToolTip(!showToolTip)}>
                  <HiInformationCircle className="icon text-dark" />
                </ButtonIcon>
                <Overlay target={target.current} show={showToolTip} placement="top">
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      Input the apex domain (without subdomain) for your website or your
                      test environment. Test environments are free of charge.
                    </Tooltip>
                  )}
                </Overlay>
              </Form.Label>
              <Stack direction="horizontal" gap={2} className="align-items-start">
                <div className="flex-grow-1">
                  <Form.Control
                    type="url"
                    name="siteUrl"
                    value={values.siteUrl}
                    onChange={handleChange}
                    isInvalid={touched.siteUrl && !!errors.siteUrl}
                    placeholder="Enter site domain"
                  />
                  {errors.siteUrl && (
                    <Form.Control.Feedback type="invalid">
                      {errors.siteUrl}
                    </Form.Control.Feedback>
                  )}
                </div>
                <div>
                  <ButtonSubmit loading={isLoading} type="submit">
                    <HiPlus className="d-sm-none icon my-n3" />
                    <span className="d-none d-sm-block">Add New Site</span>
                  </ButtonSubmit>
                </div>
              </Stack>
            </Form.Group>
          </Form>
        )}
      </Formik>

      <ModalDialog show={success}>
        <Stack gap={2} className="align-items-center text-center">
          <span>
            <HiOutlineCheckCircle className="icon icon--stroke icon--xl text-success" />
          </span>
          <div>
            <h3>A new site has been successfully added.</h3>
            <p className="fs-7">
              {fetcher.data?.site?.production
                ? 'We’ve automatically identified your new site as a licensed site.'
                : ' We’ve automatically identified your new site as a test site.'}
            </p>
          </div>
          <div className="w-50">
            <Button variant="primary" className="w-100" onClick={handleSuccessClose}>
              OK
            </Button>
          </div>
        </Stack>
      </ModalDialog>
      <ModalDialog show={errorCreateSite ? true : false}>
        <Stack gap={3} className="align-items-center text-center">
          <span>
            <HiOutlineExclamationTriangle className="icon icon--stroke icon--xl text-danger" />
          </span>
          <div>
            <h3>{errorCreateSite?.site_name}</h3>
            <p className="fs-5 text-danger fw-semibold">{errorCreateSite?.message}</p>
          </div>
          <div className="w-50">
            <Button variant="secondary" className="w-100" onClick={handleErrorClose}>
              OK
            </Button>
          </div>
        </Stack>
      </ModalDialog>
    </>
  );
};

export default FormSiteCreate;