import React from 'react';
import { Button, Col, Image, Row, Stack } from 'react-bootstrap';
import { HiInformationCircle } from 'react-icons/hi2';
import { LinkContainer } from 'react-router-bootstrap';

import { developerUrls } from '../config/external-urls';
import { Credential } from '../shared/types';
import ContentCard from './ContentCard';
import ExternalLink from './ExternalLink';
import PageHeader from './PageHeader';
import PageSection from './PageSection';

type Props = {
  credential: Credential;
  onClick: () => void;
};

const CredentialInformation = ({ credential, onClick }: Props) => {
  return (
    <>
      <PageHeader>
        <div className="align-items-center justify-content-between d-flex flex-column flex-md-row gap-2">
          <Stack direction="horizontal" gap={3} className="align-items-center">
            <Image
              fluid
              src={credential.icon}
              alt={credential.shortTitle}
              loading="lazy"
            />
            <h1 className="m-0">{credential.shortTitle}</h1>
          </Stack>
        </div>
      </PageHeader>
      <PageSection>
        <ContentCard>
          <div className="align-items-center justify-content-between d-flex flex-column flex-md-row gap-3">
            <Stack>
              <span className="text-danger fw-semibold">
                You don’t have {credential.title} credentials configured.
              </span>
              <span>
                <ExternalLink href={developerUrls.learnAboutOauth} className="fs-7">
                  Learn more about Provider (OAuth) Credentials
                </ExternalLink>
              </span>
            </Stack>

            <Button variant="primary" className="text-nowrap" onClick={onClick}>
              Add Your OAuth Credentials
            </Button>
          </div>
        </ContentCard>
      </PageSection>
    </>
  );
};

export default CredentialInformation;
