type FaIconProps = {
  icon: string;
  size?: string;
  style?: string;
  className?: string;
  color?: string;
  fixed?: boolean;
};
const FaIcon = ({ icon, size, style, className, color, fixed = true }: FaIconProps) => {
  let classes = style == 'brands' ? 'fab' : 'far';
  classes += ` fa-${icon}`;
  if (fixed) {
    classes += ' fa-fw';
  }
  if (size) {
    classes += ` fa-${size}`;
  }
  if (className) {
    classes += ` ${className}`;
  }
  if (color) {
    classes += ` text-${color}`;
  }
  return <i className={classes}></i>;
};

export default FaIcon;
