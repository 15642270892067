import { Alert, Button } from 'react-bootstrap';

import useSubscriptionDescriptions from '../hooks/useSubscriptionDescriptions';
import { Account } from '../shared/types';
import PageSection from './PageSection';
import { trackClick } from '../libs/analytics';
import SubscriptionTrialInfo from './SubscriptionTrialInfo';

type Props = {
  subscription: Account['subscription'];
};

const SubscriptionDetails = ({ subscription }: Props) => {
  const { details } = useSubscriptionDescriptions(subscription);
  const state = subscription?.state == 'active' ? 'subscribed to' : 
    subscription?.state == 'trialing' ? 'trialing' :
    subscription?.state == 'past_due' ? 'past due on' :
    subscription?.state == 'canceled' ? 'canceled on' :
    subscription?.state == 'on_hold' ? 'on hold' : subscription?.state;

  return (
    <>
      {subscription && (
        <>
          <PageSection className="my-lg-auto">
            <h1 className="mb-4 mb-sm-5 text-center">Billing Details</h1>

            {subscription.ref && (
              <Alert variant="info">
                You have received a reward for signing up with a referral code. Right on!
              </Alert>
            )}
            <p className="text-center">You are currently {state} the <strong>{subscription.plan_name}</strong> plan.</p>
            <p className="text-center mb-4">{details}</p>

            {subscription.billing_portal_url && (
              <>
                <p className="text-center my-4">
                  <Button href={subscription.billing_portal_url} onClick={trackClick}>
                    Manage My Billing Details
                  </Button>
                </p>
                { subscription?.state == 'trialing' && (
                  <>
                    <p className="text-center mt-4">
                      <strong>Remember, your plan includes:</strong><br/>
                    </p>
                    <SubscriptionTrialInfo className="text-center" />
                  </>
                )}
              </>
            )}
            {!subscription.billing_portal_url && (
              <p className="text-center mt-4">
                Talk to your account owner or administrator about your billing details.
              </p>
            )}
          </PageSection>
        </>
      )}
    </>
  );
};

export default SubscriptionDetails;
