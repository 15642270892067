import classNames from 'classnames';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
  sectionDisabled?: boolean;
  [key: string]: unknown;
};

const PageSection = ({
  children,
  sectionDisabled = false,
  className,
  ...rest
}: Props) => {
  return (
    <section
      className={classNames(
        'content__section',
        sectionDisabled && 'content__section--disabled',
        className,
      )}
      {...rest}
    >
      {children}
    </section>
  );
};

export default PageSection;
