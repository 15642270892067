import { axios } from '../utils/utils';
import queryString from 'querystring';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import React from 'react';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';

import config from '../config/config';
import { trackedEvents } from '../config/tracked-events';
import analytics from '../libs/analytics';
import { getSecureParams } from '../services/payment';
import { confirmWithToken, getAccount } from '../services/user';
import {
  Account,
  AuthContextType,
  AuthCredentials,
  UpdatedUser,
  User,
} from '../shared/types';

const AuthContext = createContext<AuthContextType>({
  user: null,
  account: null,
  isAuthed: false,
  loading: false,
  initialLoad: true,
  errors: null,
  secureParams: { 
    secure: {
      api_id: '',
      data: '',
      nonce: '',
      signature: '',
      timestamp: '',
      location: '',
    }
  },
  gauntletStep: null,
  signin: (creds: AuthCredentials) => undefined,
  signout: () => undefined,
  updateUserData: (user: UpdatedUser) => undefined,
  updateAccountData: (account: Account) => undefined,
  fetchAccount: (id: number) => undefined,
  fetchMeAndAccountAndSecureParams: () => undefined,
  signinWithToken: (token: string) => undefined,
});

function AuthProvider({ children }: { children: ReactNode }) {
  const [me, setMe] = useState<User | null>(null);
  const [account, setAccount] = useState<Account | null>(null);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [errors, setErrors] = useState(null);
  const isAuthed = useMemo(() => !!me && !!account, [me, account]);
  const [cookies, setCookie] = useCookies(['authed']);
  const [secureParams, setSecureParams] = React.useState<any>({});

  useEffect(() => {
    if (account && !account.v3Enabled) {
      const newLocation = location.pathname.replace(/^\/v3/, '')
      window.location.pathname = newLocation;
    }
  }, [account]);

  const gauntletStep = useMemo(() => {
    if (!account || !me) return null;

    const { subscription } = account || {};
    const { state } = subscription || {};
    if (!state) {
      return null;
    }

    // here's a special case for admins who are impersonating a customer
    //  this lets us skip the Gauntlet and inspect their account, in case they are expired or canceled
    if (me && me.impersonation_session && account?.skipPaymentMethod) {
      return null;
    }

    // states that permit access to the portal, other states will get stuck on the payment page
    const okStates = ['active', 'trialing', 'past_due'];
    // force the payment page if the current state is not one of the okStates
    const forcePayment = okStates.indexOf(state) < 0;

    if (forcePayment) {
      return 'payment';
    } else if (!account?.gauntletComplete) {
      return 'welcome';
    } else if (subscription?.payment_method_required && !account.skipPaymentMethod) {
      return 'payment';
    }
    return null;
  }, [account, me]);

  const params = queryString.parse(window.location.search.slice(1));

  const signin = async (creds: AuthCredentials) => {
    try {
      setLoading(true);
      const data = await axios.post(`${config.DATA_PATH}/session`, {
        username: creds.username,
        password: creds.password,
      });
      analytics.track("User Signed In");
      const userData = {
        ...data.data.me,
        company_name: data.data.account.name,
        last_signed_in_at: data.data.me.current_sign_in_at,
        account_owner: data.data.me.owner,
        role: data.data.me.is_administrator ? 'customer_admin' : 'developer',
        chargify_state: data.data.account.subscription.state,
        activated_at: data.data.me.confirmed_at,
      };
      const accountData = {
        ...data.data.account,
        chargify_state: data.data.account.subscription.state,
      };
      setMe(userData);
      setAccount(accountData);
      setCookie('authed', 'true', { path: config.APP_PATH });

      if (data.data.me.is_super_user) { 
        window.location.href = "/admin/dashboard";
      }

      await fetchSecureParams(userData);
      setLoading(false);
      return userData;
    } catch (error) {
      analytics.track("User Failed Sign In");
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const signout = async () => {
    return axios
      .delete(`${config.DATA_PATH}/session`)
      .then(() => {
        analytics.track('User signed out');
        // reload the X-CSRF-Token, since the session has expired
        setCookie('authed', '', { path: config.APP_PATH });
        // window.location.replace(config.APP_PATH);
        window.location.reload();
      })
      .catch(({ response }) => {
        console.error(response.data);
        return Promise.reject(response.data);
      });
  };

  useEffect(() => {
    if (!secureParams || !me) return;

    if (secureParams.location) {
      analytics.track("User successfully added payment method");
  
      // fetchAccount(me.account_id);
      if (secureParams.domain) {
        toast(
          `Thank you for adding your payment method to your subscription. ${secureParams.domain?.name} is now an enabled site.`,
          {
            type: 'success',
          },
        );
      } else {
        toast('Thank you for adding your payment method to your subscription.', {
          type: 'success',
        });
      }
    }

    if (secureParams.errors) {
      analytics.track("User failed payment method validation", {
        ...secureParams.errors,
      });

      Object.keys(secureParams.errors).forEach((key) => {
        toast(secureParams.errors[key], {
          type: 'error',
        });
      });
    }
  }, [secureParams]);

  useEffect(() => {
    fetchMeAndAccountAndSecureParams();
  }, []);

  const fetchMeAndAccountAndSecureParams = async () => {
    if (!cookies.authed || cookies.authed !== 'true') {
      setLoading(false);
      setInitialLoad(false);
      return;
    }

    try {
      setLoading(true);
      const me = await fetchMe();
      if (!me.account.subscription.state || me.account.subscription.payment_method_required) {
        await fetchSecureParams(me);
      }
      setCookie('authed', 'true', { path: config.APP_PATH });
    } catch (e) {
      setCookie('authed', '', { path: config.APP_PATH });
      console.error(e);
    } finally {
      setLoading(false);
      setInitialLoad(false);
    }
  };

  const fetchMe = async () => {
    console.log('fetchMe');
    return axios
      .get(`${config.DATA_PATH}/users/me`)
      .then(({ data }) => {
        //re-set user data
        const userData = {
          ...data,
          // company_name: data.data.account.name,
          last_signed_in_at: data.current_sign_in_at,
          account_owner: data.owner,
          role: data.is_administrator ? 'customer_admin' : 'developer',
          // chargify_state: data.data.account.subscription.state,
          activated_at: data.confirmed_at,
        };
        setMe(userData);
        setAccount(data.account)
        return Promise.resolve(data);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };

  const fetchAccount = async (id: number) => {
    console.log('fetchAccount');
    try {
      setLoading(true);
      const data = await getAccount(id);
      const accountData = {
        ...data,
        chargify_state: data.subscription.state,
      };
      setAccount(accountData);

      return Promise.resolve(data);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchSecureParams = async (me: User) => {
    if (!me) return;
    if (account?.subscription?.state) return;
    console.log('fetchSecureParams');
    try {
      setLoading(true);
      const data = (await getSecureParams(params)) as any;
      if (data.location) {
        analytics.track("User successfully added payment method");
        window.location.replace(data.location);
      }
      setSecureParams(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // export const updateUser = (id, user, setState) => {
  //   return axios
  //     .put(`${config.DATA_PATH}/users/${id}`, {
  //       user: user,
  //       authenticity_token: getMetaContent('csrf-token'),
  //     })
  //     .then((response) => {
  //       if ('owner' in user) {
  //         analytics.track('Changed owner', { user_id: id });
  //       }
  //       if ('is_administrator' in user) {
  //         analytics.track('Changed user role', {
  //           user_id: id,
  //           is_administrator: user.is_administrator,
  //         });
  //       }
  //       if (setState) {
  //         setState({ users: response.data });
  //       }
  //       return Promise.resolve(response.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       return Promise.reject(response.data, response.code);
  //     });
  // };
  //TODO - check analytics.track, used to update user password
  const updateUserData = async (user: UpdatedUser) => {
    const userId = me?.id;
    const isPasswordChanged = user.password && user.password_confirmation;

    if (!userId) return;

    try {
      const response = await axios.put(`${config.DATA_PATH}/users/${userId}`, {
        format: 'json',
        user: user,
      });

      analytics.track(trackedEvents.updatedProfile);

      if (isPasswordChanged) {
        analytics.track(trackedEvents.changedPassword);

        setCookie('authed', '', { path: config.APP_PATH });
        setMe(null);
        setAccount(null);
        window.location.href = config.APP_PATH;

        return;
      }

      setMe((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          ...response.data,
        };
      });
    } catch (error: any) {
      return Promise.reject(error.response.data);
    }
  };

  const updateAccountData = async (account: Account) => {
    setLoading(true);
    setAccount(account);
    setLoading(false);
  };

  const signinWithToken = async (token: string) => {
    if (token && token.length > 10 && token !== 'required') {
      try {
        setLoading(true);
        const data = await confirmWithToken(token);
        data?.me && (await fetchSecureParams(data.me));

        if (data?.me && data?.account) {
          setMe(data.me);
          setAccount(data.account);
          setCookie('authed', 'true', { path: config.APP_PATH });

          return Promise.resolve(data);
        }

        setCookie('authed', '', { path: config.APP_PATH });
        return Promise.reject('Invalid token');
      } catch (e) {
        setCookie('authed', '', { path: config.APP_PATH });

        return Promise.reject(e);
      } finally {
        setLoading(false);
      }
    }

    return Promise.reject('Invalid token');
  };

  const values = {
    initialLoad,
    loading,
    user: me,
    account,
    isAuthed,
    errors,
    secureParams,
    gauntletStep,
    signin,
    signout,
    updateUserData,
    updateAccountData,
    fetchAccount,
    fetchMeAndAccountAndSecureParams,
    signinWithToken,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
