import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Router from './components/Router';
import { AnalyticsProvider } from './contexts/analyticsContext';
import { AuthProvider } from './contexts/authContext';

const captureRef = (str: string) => {
  // capture the first 50 characters of the ref code
  var reRef = /\bref=([^&]{0,50})/;
  var refMatch = reRef.exec(str);
  var existingRefs = JSON.parse(Cookies.get('ref') || '[]');
  if (refMatch) {
    var newRef = refMatch[1];
    if (existingRefs[0] !== newRef) {
      // only add this ref if it's not already the first item in the array
      // because reloading the page would otherwise cause this value to be added to the cookies
      existingRefs.unshift(newRef);
    }
    // let's limit the length of the ref value to 20 codes, this keeps us well below the limit of 4096 bytes
    if (existingRefs.length > 10) {
      existingRefs.length = 10;
    }
    // always set the cookies so that we get the
    Cookies.set('ref', JSON.stringify(existingRefs), {
      expires: 14,
      domain: location.hostname.replace(/^www./i, ''),
    });
  }
  return existingRefs;
};

const cookieCapture = () => {
  // Cookies for the App
  if (!Cookies.get('http_referrer')) {
    Cookies.set('http_referrer', document.referrer, {
      expires: 14,
      domain: location.hostname.replace(/^app./i, ''),
    });
  }
  if (!Cookies.get('landing_url')) {
    Cookies.set('landing_url', location.pathname + location.search, {
      expires: 14,
      domain: location.hostname.replace(/^app./i, ''),
    });
  }

  captureRef(window.location && window.location.search);
};
//@TODO end

cookieCapture();

function App() {
  return (
    <div className="app">
      <HelmetProvider>
        <Helmet defaultTitle="CloudSponge" titleTemplate="%s - CloudSponge" />
        <AuthProvider>
          <AnalyticsProvider>
            <Router />
          </AnalyticsProvider>
        </AuthProvider>
      </HelmetProvider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        pauseOnHover
      />
    </div>
  );
}

export default App;
