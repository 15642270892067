import { useMemo, useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { HiOutlineExclamationTriangle, HiOutlineTrash } from 'react-icons/hi2';
import { useFetcher } from 'react-router-dom';

import { User } from '../shared/types';
import ButtonSubmit from './ButtonSubmit';
import ModalDialog from './ModalDialog';

const UserDeleteButton = ({ user }: { user: User }) => {
  const fetcher = useFetcher();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isLoading = useMemo(() => {
    return fetcher.state === 'loading' || fetcher.state === 'submitting';
  }, [fetcher.state]);
  const handleDelete = () => {
    const formData = new FormData();
    formData.append('id', user.id);
    formData.append('action', 'deleteUser');
    fetcher.submit(formData, {
      method: 'post',
    });
  };

  const handleShow = () => {
    setShowDeleteModal(true);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      <Button size="sm" variant="text" onClick={handleShow} className="w-100">
        <div className="d-flex gap-2 align-items-center justify-content-center">
          <HiOutlineTrash className="icon icon--stroke" />
          <span className="d-sm-none">Delete user</span>
        </div>
      </Button>

      <ModalDialog show={showDeleteModal}>
        <Stack gap={2} className="align-items-center text-center">
          <span>
            <HiOutlineExclamationTriangle className="icon icon--stroke icon--xl text-danger" />
          </span>
          <div>
            <h3>Are you sure you want to delete {user.email}?</h3>
          </div>
          <Stack gap={2} direction="horizontal" className="justify-content-center">
            <ButtonSubmit loading={isLoading} variant="danger" onClick={handleDelete}>
              Delete user
            </ButtonSubmit>
            <Button variant="outline-gray" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </ModalDialog>
    </>
  );
};

export default UserDeleteButton;
