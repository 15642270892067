import React from 'react';
import { Stack } from 'react-bootstrap';

import { IntegrationsData } from '../services/integrations';
import InstallListCard from './InstallListCard';
import PageSection from './PageSection';

const InstallList = ({ data }: { data: IntegrationsData }) => {
  return (
    <>
      <PageSection id="featured">
        <h2 className="mb-4">Featured</h2>
        <Stack gap={3}>
          {data.featured.map((integration) => (
            <InstallListCard key={integration.id} integration={integration} />
          ))}
        </Stack>
      </PageSection>

      {data.integrations.map((category) => (
        <PageSection key={category.category_id} id={category.category_id}>
          <h2 className="mb-4">{category.category_name}</h2>
          {category.integrations && (
            <Stack gap={3}>
              {category.integrations.map((integration) => (
                <InstallListCard key={integration.id} integration={integration} />
              ))}
            </Stack>
          )}
        </PageSection>
      ))}
    </>
  );
};

export default InstallList;
