import React from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { marketingSiteUrl } from '../config/external-urls';

import { useAuth } from '../contexts/authContext';
import PageHeader from '../components/PageHeader';
import ReferralForm from '../components/ReferralForm';
import ReferralSnippet from '../components/ReferralSnippet';
import ReferralButtons from '../components/ReferralButtons';
import PageSection from '../components/PageSection';
import Page from '../components/Page';
import ContentCard from '../components/ContentCard';

const ShareHeader = ({title, subtext}:{title: string, subtext: string}) => (
  <Row>
    <Col>
      <h2 className="gilroy-bold mt-0">{title}</h2>
      <p className="text-muted m-0">{subtext}</p>
    </Col>
  </Row>
)

const Referrals = () => {
  const { user: me, account } = useAuth();
  if (!account?.subscription?.referral_code) return null;
  const referralUrl = `${marketingSiteUrl}/?ref=${account?.subscription?.referral_code}`;
  return (
    <Page size="xl">
      <PageHeader>
        <Stack gap={2} direction="horizontal" className="align-items-center">
          <h1>Refer Now</h1>
        </Stack>
      </PageHeader>

      <PageSection className="my-lg-auto">
        <Row>
          <Col>
            <PageHeader title="Give an Amazing Deal">
              <p>
                Be a hero and share this deal:
                Everyone you refer will get <strong>10% off CloudSponge for 12 months!</strong>&nbsp;
                And for you, a $50 credit toward your next invoice.
              </p>
            </PageHeader>
          </Col>
        </Row>

        <ContentCard className="mt-0">
          <ShareHeader
            title="Share your link"
            subtext="Give a sweet deal on CloudSponge and build credit for yourself by sharing your personal referral link with others." />
          <ReferralSnippet referralUrl={referralUrl} />
        </ContentCard>

        <ContentCard className="mt-4">
          <ShareHeader
            title="Share via email"
            subtext="Share this killer deal with your contacts by entering their emails. (We'll automatically add your referral link!)" />
          <ReferralForm />
        </ContentCard>

        <ContentCard className="mt-4">
          <ShareHeader
            title="Share on social"
            subtext="Let your followers enjoy a year of CloudSponge at a discount and build your own CloudSponge credit by sharing your personal referral link to your network." />
          <ReferralButtons url={referralUrl} quote="Looking to double the reach of your referral program? CloudSponge can help get you there."/>
        </ContentCard>
      </PageSection>
    </Page>
  );
}

export default Referrals;
