import React, { useEffect, useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import { Credential, Project } from '../shared/types';

type Props = {
  show: boolean;
  projects: Project[];
  credentialSource: Credential['source'];
  handleClose: () => void;
  copyCredentials: (projectCredential: Credential) => void;
};

const CredentialCopy = ({
  show,
  handleClose,
  copyCredentials,
  projects,
  credentialSource,
}: Props) => {
  const [selectedProjectId, setSelectedProjectId] = React.useState<string>(
    projects[0].id,
  );

  const selectProject = (id: string) => {
    setSelectedProjectId(id);
  };

  const selectedCredentials = useMemo(() => {
    const p = projects.find(
      (project) => project.id.toString() === selectedProjectId.toString(),
    );
    const credential = p?.credentials.find(
      (credential) => credential.source === credentialSource,
    ) as Credential;

    return credential
      ? {
          proxy_url: credential.proxy_url,
          client_id: credential.client_id,
          client_secret: credential.client_secret,
          scope: credential.scope,
        }
      : null;
  }, [selectedProjectId, credentialSource, projects]) as Credential;

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Copy Credentials From Another Project?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="copyFromProject">
          <Form.Label>Copy From Project</Form.Label>

          <select
            className="form-select"
            aria-label="Copy from another project"
            onChange={(e) => selectProject(e.target.value)}
          >
            {projects.map((project: any) => (
              <option value={project.id} key={project.id}>
                {project.name}
              </option>
            ))}
          </select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="ghost" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={!selectedCredentials}
          onClick={() => {
            copyCredentials(selectedCredentials);
          }}
        >
          Copy Credentials
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CredentialCopy;
