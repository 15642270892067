import { DetailedMetricsType, FocussedSiteType } from '../shared/types';
import MetricsDataBlock from './MetricsDataBlock';

type CommonMetricsProps = {
  site: FocussedSiteType;
  detailedMetricsTypes: DetailedMetricsType[];
};
const CommonMetrics = ({ site }: CommonMetricsProps) => {
  return (
    <div className="row">
      <div className="col-sm-3">
        <MetricsDataBlock
          title="People who Submitted"
          subTitle="Number of people who picked and submitted one or more contacts in the Contact Picker."
          value={site.total_shares || 0} //@TODO fix typing
          previous={site.prev_total_shares || 0}
        />
      </div>
      <div className="col-sm-3">
        <MetricsDataBlock
          title="Total Contacts"
          subTitle="The total number of contacts in all connected address books."
          value={site.total_contacts || 0} //@TODO fix typing
          previous={site.prev_total_contacts || 0}
        />
      </div>
      <div className="col-sm-3">
        <MetricsDataBlock
          title="Submitted Contacts"
          subTitle="The total number of contacts selected and submitted with the Contact Picker."
          value={site.total_contacts_shared || 0} //@TODO fix typing
          previous={site.prev_total_contacts_shared || 0}
        />
      </div>
      <div className="col-sm-3">
        <MetricsDataBlock
          title="Average Submitted Contacts"
          subTitle="The average number of contacts selected and submitted with the Contact Picker."
          value={site.total_average_contacts_shared || 0} //@TODO fix typing
          previous={site.prev_total_average_contacts_shared || 0}
        />
      </div>
    </div>
  );
};

export default CommonMetrics;
