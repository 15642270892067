import React, { useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import { RoutePaths } from '../config/route-paths';

const NavigationGuest = () => {
  const location = useLocation();

  const show = useMemo(() => {
    const path = location.pathname;

    return {
      signup: path !== RoutePaths.SIGNUP,
      signin: path !== RoutePaths.SIGNIN,
      reset:
        !path.includes(RoutePaths.RESET_PASSWORD) &&
        !path.includes(RoutePaths.CONFIRMATION),
      confirm: path !== RoutePaths.CONFIRMATION,
    };
  }, [location.pathname]);

  return (
    <Stack gap={1} className="fs-7">
      {show.signin && (
        <div>
          Already have an account? <Link to="/">Sign In</Link>
        </div>
      )}

      {show.reset && (
        <div>
          Forgot your password? <Link to="/reset">Request a link to reset it</Link>
        </div>
      )}

      {show.signup && (
        <div>
          Need an account? <Link to="/signup">Sign Up</Link>
        </div>
      )}

      {show.confirm && (
        <div className="pt-2">
          <Link to="/confirm">Request a new Confirmation Email</Link>
        </div>
      )}
    </Stack>
  );
};

export default NavigationGuest;
