import { axios } from '../utils/utils';
import { ActionFunctionArgs } from 'react-router-dom';

import config from '../config/config';
import { Project } from '../shared/types';

export const fetchProjects: () => Promise<Project[]> = async () => {
  try {
    const res = await axios.get(`${config.DATA_PATH}/keys`);
    return res.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const fetchProjectById: (id?: string) => Promise<Project> = async (id) => {
  try {
    const res = await axios.get(`${config.DATA_PATH}/keys/${id}`);
    return res.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export async function projectsActions({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  if (formData.get('action') === 'create') {
    const projectName = formData.get('name');
    const params = { name: projectName };
    try {
      const res = await axios.post(`${config.DATA_PATH}/keys`, params);
      return { ok: true, project: res.data };
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }
  return;
}

export type { Project };
