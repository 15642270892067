import { axios } from '../utils/utils';
import config from '../config/config';

export async function fetchProxyUrls() {
  try {
    return await axios.get(`${config.DATA_PATH}/proxy_urls`);
  } catch (errors) {
    console.error(errors);
    return await Promise.reject(errors);
  }
}

export async function fetchCredentials(projectKey: string = '', source: string = '') {
  const params = source
    ? { source: source, project_key: projectKey }
    : { project_key: projectKey };

  try {
    const res = await axios.get(`${config.DATA_PATH}/credentials`, {
      params: params,
    });

    return res.data;
  } catch (errors) {
    console.error(errors);
    return await Promise.reject(errors);
  }
}

export async function credentialsActions({ request }: any) {
  const formData = await request.formData();

  if (formData.get('action') === 'create') {
    return await axios
      .post(`${config.DATA_PATH}/credentials`, {
        format: 'json',
        credential: Object.fromEntries(formData),
      })
      .then((res) => {
        return { ok: true, credential: res.data };
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  if (formData.get('action') === 'update') {
    const id = formData.get('id');

    return await axios
      .put(`${config.DATA_PATH}/credentials/${id}`, {
        format: 'json',
        credential: Object.fromEntries(formData),
      })
      .then((res) => {
        return { ok: true, credential: res.data };
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }
  return;
}
