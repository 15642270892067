import { useMemo } from 'react';
import { useRouteLoaderData } from 'react-router-dom';

import { SitesLoaderData } from '../shared/types';

export const useDisabledSection = () => {
  const {
    data: { devSites, productionSites },
  } = useRouteLoaderData('sites') as { data: SitesLoaderData };

  const isSectionDisabled = useMemo(() => {
    return devSites.totalPages === 0 && productionSites?.totalPages === 0;
  }, [devSites, productionSites]);

  return { isSectionDisabled };
};
