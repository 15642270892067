import { useCallback, useMemo } from 'react';

import { DetailedMetricsType, FocussedSiteType } from '../shared/types';
import EmptyChart from './EmptyChart';
import MetricsChart from './MetricsChart';
import MetricsDataBlock from './MetricsDataBlock';

const chartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  title: {
    display: false,
  },
  tooltips: {
    mode: 'index',
    intersect: false,
    callbacks: {
      label: (tooltipItem: any, data: any) => {
        if (tooltipItem.yLabel) {
          return tooltipItem.yLabel.toLocaleString();
        }
        return tooltipItem.value;
      },
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        stacked: true,
        ticks: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  legend: false,
};
type ConversionFunnelProps = {
  site: FocussedSiteType;
  detailedMetricsTypes: DetailedMetricsType[];
};
const ConversionFunnel = ({ site, detailedMetricsTypes }: ConversionFunnelProps) => {
  const percentageOf = useCallback((value: number, fromValue: number) => {
    return ((value / fromValue) * 100).toFixed(2);
  }, []);

  const conversionTypes = useMemo((): Record<string, DetailedMetricsType | undefined> => {
    return {
      engagementTypes: detailedMetricsTypes.find(
        (type: DetailedMetricsType) => type.value == 'total_engagements',
      ),
      loadTypes: detailedMetricsTypes.find(
        (type: DetailedMetricsType) => type.value == 'total_loads',
      ),
      connectTypes: detailedMetricsTypes.find(
        (type: DetailedMetricsType) => type.value == 'total_connects',
      ),
      shareTypes: detailedMetricsTypes.find(
        (type: DetailedMetricsType) => type.value == 'total_shares',
      ),
    };
  }, []);
  return !site.total_engagements &&
    !site.total_loads &&
    !site.total_connects &&
    !site.total_shares ? (
    <EmptyChart text="No data for the Funnel" />
  ) : (
    <>
      <div className="conversion-funnel-columns">
        <div className="row mb-4">
          <div className="col-sm-4 conversion-funnel-columns__col">
            <MetricsDataBlock
              title="Engaged"
              value={site.total_engagements || 0}
              info={`${percentageOf(
                site.total_engagements || 0,
                site.total_loads || 0,
              )} % of Page Loads`}
            />
          </div>
          <div className="col-sm-4 conversion-funnel-columns__col">
            <MetricsDataBlock
              title="Connected"
              value={site.total_connects || 0}
              info={`${percentageOf(
                site.total_connects || 0,
                site.total_engagements || 0,
              )}% of People who Engaged`}
            />
          </div>
          <div className="col-sm-4 conversion-funnel-columns__col">
            <MetricsDataBlock
              title="Submitted"
              value={site.total_shares || 0}
              info={`${percentageOf(
                site.total_shares || 0,
                site.total_engagements || 0,
              )}% of People who Engaged`}
            />
          </div>
        </div>
      </div>

      <div className="chart-container">
        <MetricsChart
          type="bar"
          data={{
            datasets: [
              {
                data: [site.total_engagements, site.total_connects, site.total_shares],
                backgroundColor: ['#ACD663', '#ACD663', '#ACD663'],
              },
            ],
            labels: [
              'People who Engaged',
              'People who Connected',
              'People who Submitted',
            ],
          }}
          width={100}
          height={60}
          options={chartOptions}
        />
      </div>
      <div className="conversion-funnel-columns d-none d-sm-flex">
        <div className="row">
          <div className="col-sm-4 conversion-funnel-columns__col">
            <div className="py-sm-3 fs-8">
              {conversionTypes.engagementTypes?.infoText}
            </div>
          </div>
          <div className="col-sm-4 conversion-funnel-columns__col">
            <div className="py-sm-3 fs-8">{conversionTypes.connectTypes?.infoText}</div>
          </div>
          <div className="col-sm-4 conversion-funnel-columns__col">
            <div className="py-sm-3 fs-8">{conversionTypes.shareTypes?.infoText}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConversionFunnel;
