import React from 'react';
import { Helmet } from 'react-helmet-async';

import FormSiteCreate from '../components/FormSiteCreate';
import Page from '../components/Page';
import PageHeader from '../components/PageHeader';
import PageSection from '../components/PageSection';
import SitesList from '../components/SitesList';

const Sites = () => {
  return (
    <>
      <Helmet>
        <title>Sites</title>
      </Helmet>
      <Page>
        <PageHeader>
          <h1>Sites</h1>
        </PageHeader>
        <PageSection>
          <FormSiteCreate />
        </PageSection>
        <SitesList />
      </Page>
    </>
  );
};

export default Sites;
