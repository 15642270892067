import { Formik, FormikHelpers } from 'formik';
import queryString from 'querystring';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { RoutePaths } from '../config/route-paths';
import { createUser } from '../services/user';
import { NewUser } from '../shared/types';
import ButtonSubmit from './ButtonSubmit';

export const jobTitleOptions = [
  'Executive (Owner, Founder)',
  'Technology or Engineering',
  'Product or Design',
  'Marketing or Growth',
  'Finance or Accounts Payable',
];

type FormValues = {
  full_name: NewUser['full_name'];
  company_name: NewUser['company_name'];
  job_title: NewUser['job_title'];
  email: NewUser['email'];
  password: NewUser['password'];
};

const FormSignUp = () => {
  const [loading, setLoading] = useState(false);
  const [cookies, _] = useCookies([
    'ref',
    '_fprom_track',
    '_fprom_code',
    'landing_url',
    'http_referrer',
  ]);

  const location = useLocation();
  const navigate = useNavigate();
  const params = queryString.parse(location.search.slice(1));

  const handleSubmit = async (
    values: FormValues,
    { setErrors }: FormikHelpers<FormValues>,
  ) => {
    const user: NewUser = {
      ...values,
      signup_ref_codes: JSON.parse(cookies.ref || '[]'),
      fp_tracking_id: cookies._fprom_track || '',
      fp_code: cookies._fprom_code || '',
      landing_url: cookies.landing_url || '',
      http_referrer: cookies.http_referrer || '',
      utm_medium: JSON.stringify(params.utm_medium) || '',
      utm_source: JSON.stringify(params.utm_source) || '',
    };

    try {
      setLoading(true);
      await createUser(user);
      navigate(RoutePaths.CONFIRMATION, {
        state: { email: user.email },
      });
    } catch (error: any) {
      const errors = error.response.data?.errors as Record<string, string[]>;
      if (!errors) {
        toast('Something went wrong', {
          type: 'error',
        });

        return;
      }
      let errorsObj = {};
      for (const [key, value] of Object.entries(errors)) {
        const fieldErrors = value.join(' ,');

        errorsObj = {
          ...errorsObj,
          [key]: `${key} ${fieldErrors}`,
        };
      }

      setErrors(errorsObj);
    } finally {
      setLoading(false);
    }
  };

  const schema = yup.object().shape({
    // required  and must have first and last name
    // can have letters and spaces and dashes
    full_name: yup
      .string()
      .required('Full name is required')
      .test(
        'full_name',
        'Please enter your first and last name, separated by a space.',
        (value) => {
          if (!value) return false;
          const names = value.trim().split(' ');
          return names.length > 1;
        },
      ),
    company_name: yup.string().required('Company name is required'),
    job_title: yup.string().required('Job title is required'),
    email: yup.string().email().required('Email is required').test(
      'email',
      'Please enter your business email address',
      (value) => {
        const badEmailsRe = /@(gmail|outlook|yahoo|myyahoo|protonmail|icloud|onmicrosoft|aol|yandex|proton)\.(com|gr|ca|me)$/i;
        return !badEmailsRe.test(value || '');
      }
    ),
    // TODO: maybe Add password validation
    // Password must contain a special character: !, @, #, $, &, or *
    password: yup.string().required('Password is required'),
  });

  return (
    <div>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          full_name: '',
          company_name: '',
          job_title: '',
          email: '',
          password: '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="full_name" className="mb-3">
              <Form.Control
                type="text"
                name="full_name"
                value={values.full_name}
                onChange={handleChange}
                isInvalid={touched.full_name && !!errors.full_name}
                placeholder="Full Name"
                autoComplete="name"
              />
              {errors.full_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.full_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="company_name" className="mb-3">
              <Form.Control
                type="text"
                name="company_name"
                value={values.company_name}
                onChange={handleChange}
                isInvalid={touched.company_name && !!errors.company_name}
                placeholder="Company Name"
                autoComplete="organization"
              />
              {errors.company_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.company_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="job_title" className="mb-3">
              <Form.Select
                aria-label="Job Title"
                name="job_title"
                value={values.job_title}
                onChange={handleChange}
                className={touched.job_title && errors.job_title ? 'is-invalid' : ''}
              >
                <option value="" disabled>
                  Select Role
                </option>
                {jobTitleOptions.map((option, i) => (
                  <option value={option} key={i}>
                    {option}
                  </option>
                ))}
              </Form.Select>

              {touched.job_title && errors.job_title && (
                <Form.Control.Feedback type="invalid">
                  {errors.job_title}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Control
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={touched.email && !!errors.email}
                placeholder="Business Email"
                autoComplete="email"
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Control
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                isInvalid={touched.password && !!errors.password}
                placeholder="Password"
                autoComplete="new-password"
              />
              {errors.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <p className="mt-4">
              <ButtonSubmit className="w-100" type="submit" loading={loading}>
                Sign Up
              </ButtonSubmit>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormSignUp;
