import {
  ReactNode,
  createContext,
  useContext,
} from 'react';
import React from 'react';

type AnalyticsContextType = {
  lastPath: (path?: string) => string | null;
  lastUser: (userId?: string) => string | null;
  lastAccount: (accountId?: String) => string | null;
};
const AnalyticsContext = createContext<AnalyticsContextType>({
  lastPath: (path) => null,
  lastUser: (userId) => null,
  lastAccount: (accountId) => null,
});

function AnalyticsProvider({ children }: { children: ReactNode }) {
  const lastPathRef = React.useRef<string | null>(null);
  const lastUserRef = React.useRef<string | null>(null);
  const lastAccountRef = React.useRef<string | null>(null);

  const values = {
    lastPath: (path?: string) => path ? lastPathRef.current = path : lastPathRef.current,
    lastUser: (userId?: string) => userId ? lastUserRef.current = userId : lastUserRef.current,
    lastAccount: (accountId?: string) => accountId ? lastAccountRef.current = accountId : lastAccountRef.current,
  };

  return <AnalyticsContext.Provider value={values}>{children}</AnalyticsContext.Provider>;
}

function useAnalytics() {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }
  return context;
}

export { AnalyticsProvider, useAnalytics };
