import React, { Suspense, useEffect, useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import CredentialList from '../components/CredentialList';
import ExternalLink from '../components/ExternalLink';
import Page from '../components/Page';
import PageHeader from '../components/PageHeader';
import PageSection from '../components/PageSection';
import SectionDisabledUnlock from '../components/SectionDisabledUnlock';
import { developerUrls } from '../config/external-urls';
import { useCredentials } from '../hooks/useCredentials';
import { useDisabledSection } from '../hooks/useDisabledSection';

const ProviderCredentials = () => {
  const { credentials } = useCredentials();
  const { isSectionDisabled } = useDisabledSection();

  return (
    <>
      <Helmet>
        <title>Provider Credentials</title>
      </Helmet>
      <Page>
        <PageHeader>
          <div className="align-items-center justify-content-between d-flex flex-column flex-md-row gap-2">
            <h1>Provider Credentials</h1>
            <ExternalLink href={developerUrls.learnAboutOauth} className="fs-7">
              Learn more about Provider (OAuth) Credentials
            </ExternalLink>
          </div>
        </PageHeader>
        {isSectionDisabled && (
          <SectionDisabledUnlock lockedSection="Provider Credentials management" />
        )}
        <PageSection sectionDisabled={isSectionDisabled}>
          <Stack gap={3}>
            <CredentialList credentials={credentials} />
          </Stack>
        </PageSection>
      </Page>
    </>
  );
};

export default ProviderCredentials;
