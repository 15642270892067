import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { FormCreateNewUsers } from './FormCreateNewUsers';
import { trackClick } from '../libs/analytics';

type Props = {
  maxNewUsers: number;
  title?: string;
};

const NewUserModal = ({ maxNewUsers, title }: Props) => {
  const [modal, setModal] = useState(false);
  const toggle = (e) => {
    trackClick(e);
    setModal(!modal);
  };

  return maxNewUsers <= 0 ? null : (
    <>
      <Button color="secondary" onClick={toggle}>
        Add Users
      </Button>
      <Modal show={modal} size="lg" centered>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          <FormCreateNewUsers maxNewUsers={maxNewUsers} onSuccess={toggle} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewUserModal;
