import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import Page from '../components/Page';
import Subscription from '../components/Subscription';

const Payment = () => {
  return (
    <>
      <Helmet>
        <title>Payment</title>
      </Helmet>
      <Page size="xl">
        <Subscription />
      </Page>
      ;
    </>
  );
};

export default Payment;
