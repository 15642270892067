import React from 'react';
import { Stack } from 'react-bootstrap';

import { Account, User } from '../shared/types';
import HeaderUserDropdown from './HeaderUserDropdown';
import HeaderUserNotifications from './HeaderUserNotifications';
import SubscriptionStatus from './SubscriptionStatus';

type Props = {
  me: User;
  account: Account;
  signout: () => void;
};

const HeaderUser = ({ me, account, signout }: Props) => {
  return (
    <Stack direction="horizontal" gap={3} className="ms-auto">
      {account && account.alerts ? (
        <>
          <SubscriptionStatus subscription={account.subscription} />
          {/* <HeaderUserNotifications notifications={account?.alerts} /> */}
        </>
      ) : null}
      <HeaderUserDropdown me={me} subscription={account.subscription} signout={signout} />
    </Stack>
  );
};

export default HeaderUser;
