import { FormikErrors } from 'formik';
import { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { HiMinusCircle } from 'react-icons/hi2';

import ButtonIcon from './ButtonIcon';
import { NewUserData } from './FormCreateNewUsers';

type NewUserInputsProps = {
  user: NewUserData;
  idx: number;
  onRemove: (idx: number) => void;
  onChange: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  showDelete: boolean;
  errors: FormikErrors<NewUserData> | null;
  touched: Record<string, boolean> | null;
};
export const NewUserInputs = ({
  user,
  idx,
  onRemove,
  onChange,
  showDelete,
  errors,
  touched,
}: NewUserInputsProps) => {
  return (
    <>
      <div className="d-flex gap-2 justify-content-between align-items-center">
        <div className="align-self-start">
          <ButtonIcon disabled={!showDelete} onClick={() => onRemove(idx)}>
            <HiMinusCircle className="icon" />
          </ButtonIcon>
        </div>
        <div className="d-flex flex-column flex-md-row gap-2 justify-content-between flex-grow-1">
          <Form.Group controlId={`newUsers[${idx}].first_name`}>
            <Form.Control
              type="text"
              placeholder="First name"
              name={`newUsers[${idx}].first_name`}
              value={user.first_name}
              onChange={onChange}
              isInvalid={touched?.first_name && !!errors?.first_name}
            />
            {errors?.first_name && (
              <Form.Control.Feedback type="invalid">
                {errors?.first_name}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId={`newUsers[${idx}].last_name`}>
            <Form.Control
              type="text"
              placeholder="Last name"
              name={`newUsers[${idx}].last_name`}
              value={user.last_name}
              onChange={onChange}
              isInvalid={touched?.last_name && !!errors?.last_name}
            />
            {errors?.last_name && (
              <Form.Control.Feedback type="invalid">
                {errors?.last_name}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId={`newUsers[${idx}].email`}>
            <Form.Control
              type="email"
              placeholder="Email"
              onChange={onChange}
              value={user.email}
              name={`newUsers[${idx}].email`}
              isInvalid={touched?.email && !!errors?.email}
              autoComplete="email"
            />
            {errors?.email && (
              <Form.Control.Feedback type="invalid">
                {errors?.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="pt-2">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={`admin${idx}`}
                onChange={onChange}
                value={user.is_administrator}
                name={`newUsers[${idx}].is_administrator`}
              />
              <label className="form-check-label" htmlFor={`admin${idx}`}>
                Administrator
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
