import { useMemo } from 'react';

import { User } from '../shared/types';

export enum Role {
  OWNER = 'Owner',
  ADMINISTRATOR = 'Administrator',
  DEVELOPER = 'Developer',
}

export const useProfileRole = (user: User) => {
  const role = useMemo(() => {
    if (user) {
      if (user.owner) {
        return Role.OWNER;
      } else if (user.is_administrator) {
        return Role.ADMINISTRATOR;
      } else {
        return Role.DEVELOPER;
      }
    }
  }, [user]);

  return role;
};

export const useProfileName = (me: User, user?: User, convertMe: Boolean = true) => {
  const userName = useMemo(() => {
    if (me && user) {
      if (me.id === user.id) {
        if (convertMe) {
          return 'You';
        } else {
          return `${me.first_name} ${me.last_name}`;
        }
      } else {
        return `${user.first_name} ${user.last_name}`;
      }
    } else if (me && !user) {
      return `${me.first_name} ${me.last_name}`;
    } else if (!me && user) {
      return `${user.first_name} ${user.last_name}`;
    } else {
      return '';
    }
  }, [me, user]);

  return userName;
};
