import React, { Suspense } from 'react';
import {
  HiOutlineChartBarSquare,
  HiOutlineCodeBracketSquare,
  HiOutlineKey,
  HiOutlineRectangleStack,
} from 'react-icons/hi2';
import { IconType } from 'react-icons/lib';
import { Await, NavLink, useParams, useRouteLoaderData } from 'react-router-dom';

import { RoutePaths } from '../config/route-paths';
import { useAuth } from '../contexts/authContext';
import { IntegrationsData } from '../services/integrations';
import { Account } from '../shared/types';
import Indicator from './Indicator';

//import SetupWizardTrigger from './SetupWizardTrigger';

type Props = {
  onClick: () => void;
};
type Item = {
  id: number;
  icon: IconType;
  label: string;
  path: string;
  indicator?: JSX.Element;
  children?: null | JSX.Element;
};
const NavigationMenu = ({ onClick }: Props) => {
  const { id, projectKey } = useParams();
  const { account } = useAuth() as { account: Account };
  const installData = useRouteLoaderData('install') as { data: IntegrationsData };

  const items = [
    {
      id: 1,
      icon: HiOutlineChartBarSquare,
      label: 'Metrics',
      path: `${RoutePaths.PROJECTS}/${projectKey}/${RoutePaths.METRICS}`,
    },
    {
      id: 2,
      icon: HiOutlineRectangleStack,
      label: 'Sites',
      indicator: <Indicator notifications={account.alerts} type="sites_pending" />,
      path: `${RoutePaths.PROJECTS}/${projectKey}/${RoutePaths.SITES}`,
    },
    {
      id: 3,
      icon: HiOutlineCodeBracketSquare,
      label: 'Install',
      path: `${RoutePaths.PROJECTS}/${projectKey}/${RoutePaths.INSTALL}`,
      children: installData ? (
        <Suspense fallback={null}>
          <Await resolve={installData.data}>
            {(data: IntegrationsData) => (
              <>
                <div className="navigation-menu__submenu">
                  {data.categoryList.map((category) => (
                    <a
                      href={`#${category.category_id}`}
                      key={category.category_id}
                      onClick={onClick}
                    >
                      {category.category_name}
                    </a>
                  ))}
                </div>
              </>
            )}
          </Await>
        </Suspense>
      ) : null,
    },
    {
      id: 4,
      icon: HiOutlineKey,
      label: 'Provider Credentials',
      // indicator: <Indicator notifications={account.alerts} type="oauth" />,
      path: `${RoutePaths.PROJECTS}/${projectKey}/${RoutePaths.PROVIDER_CREDENTIALS}`,
    },
  ];

  return (
    <nav className="navigation-menu">
      {items.map((item) => (
        <div key={item.id}>
          <>
            <NavLink
              to={item.path ? item.path : ''}
              className={({ isActive }) =>
                isActive ? `navigation-menu__btn is-active` : `navigation-menu__btn`
              }
              onClick={onClick}
            >
              <item.icon className="icon icon--stroke" />
              <span>{item.label}</span>
              {item.indicator ? <span className="ms-auto">{item.indicator}</span> : null}
            </NavLink>
            {item.children ? item.children : null}
          </>
        </div>
      ))}
      {/*<SetupWizardTrigger />*/}
    </nav>
  );
};

export default NavigationMenu;
