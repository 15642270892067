import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import { HiArrowLeft } from 'react-icons/hi2';
import { Link, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import { RoutePaths } from '../config/route-paths';
import { FocussedSiteType, MetricsPeriodType } from '../shared/types';
import { SelectDropdown } from './SelectDropdown';

type MetrixFiltersProp = {
  context?: string;
  sites: { id: string | null; name: string }[];
  currentSite: FocussedSiteType;
  periodItervals: MetricsPeriodType[];
  currentPeriod: MetricsPeriodType;
  dateRange?: Record<string, string>;
  handleSelectSite: (site: Record<string, string | null>) => void;
  handleSelectPeriod: (period: MetricsPeriodType) => void;
  handleDateChange: (startDate: string, endDate: string) => void;
};
const MetricsFilters = ({
  context,
  sites,
  currentSite,
  periodItervals,
  currentPeriod,
  handleSelectSite,
  handleSelectPeriod,
  handleDateChange,
  dateRange,
}: MetrixFiltersProp) => {
  const { projectKey } = useParams();

  return (
    <div className="d-flex flex-column flex-md-row justify-content-between gap-2 metrics-filters-sticky align-items-md-center">
      <div>
        {context == 'site' ? (
          <Link
            to={`${RoutePaths.PROJECTS}/${projectKey}/${RoutePaths.SITES}`}
            className="link-dark d-flex align-items-center gap-2 text-decoration-none"
          >
            <HiArrowLeft className="icon" />
            <span>Back to sites</span>
          </Link>
        ) : (
          <>
            <p className="fs-8 text-muted mb-1">Sites to view:</p>
            <SelectDropdown>
              <Dropdown.Toggle className="btn-select-white w-100">
                {currentSite && currentSite.domain
                  ? currentSite.domain.name
                  : currentSite && currentSite.domain_id
                  ? currentSite.domain_id
                  : 'All sites'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {sites.map((item) => (
                  <Dropdown.Item
                    key={item.id}
                    active={currentSite && item.id === currentSite.domain_id}
                    onClick={() => {
                      handleSelectSite(item);
                    }}
                  >
                    {item.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </SelectDropdown>
          </>
        )}
      </div>

      <div>
        <p className="fs-8 text-muted mb-1">View By:</p>
        <div className="d-flex flex-column flex-sm-row gap-2">
          <div className="mr-sm-2 mb-2">
            <SelectDropdown>
              <Dropdown.Toggle className="btn-select-white w-100">
                {currentPeriod ? currentPeriod.name : ''}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {periodItervals.map((item) => (
                  <Dropdown.Item
                    key={item.id}
                    active={item === currentPeriod}
                    onClick={() => {
                      handleSelectPeriod(item);
                    }}
                  >
                    {item.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </SelectDropdown>
          </div>
          <div className="box d-flex align-items-center justify-content-center p-0 flex-grow-1 mb-2">
            {currentPeriod && (
              <RangeDatePicker
                startDate={dateRange && dateRange.dateFrom}
                endDate={dateRange && dateRange.dateTo}
                onChange={(startDate: string, endDate: string) =>
                  handleDateChange(startDate, endDate)
                }
                maxDate={new Date()}
                dateFormat="MMM D, YYYY"
                monthFormat="MMM, YYYY"
                startDatePlaceholder="Start Date"
                endDatePlaceholder="End Date"
                startWeekDay="monday"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MetricsFilters;
