import { Helmet } from 'react-helmet-async';

import FormRequestResetPassword from '../components/FormRequestResetPassword';
import PageHeader from '../components/PageHeader';
import PageSection from '../components/PageSection';

const ResetPassword = () => {
  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <PageHeader>
        <h1>Reset Password</h1>
      </PageHeader>
      <PageSection>
        <FormRequestResetPassword />
      </PageSection>
    </>
  );
};

export default ResetPassword;
