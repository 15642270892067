import { Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { requestPasswordReset } from '../services/user';
import ButtonSubmit from './ButtonSubmit';

type FormValues = {
  email: string;
};

type Props = {
  email?: string;
};

const FormRequestResetPassword = ({ email }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      setLoading(true);
      await requestPasswordReset({
        email: values.email,
      });
      actions.resetForm();
      toast(
        'If we found an account linked to that email address, we sent you an email to reset your password from support@cloudsponge.com.',
        {
          type: 'success',
        },
      );
    } catch (error: any) {
      console.log(error);
      toast(
        'Something went wrong when requesting a new password email. Wait a bit before trying again. If the problem persists contact us to help sort it out.',
        {
          type: 'error',
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  return (
    <div>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          email: email || '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label className="fs-7">
                You can request a link to reset your password by entering your email
                address below.
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={touched.email && !!errors.email}
                placeholder="Your Email"
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <p className="mt-4">
              <ButtonSubmit className="w-100" type="submit" loading={loading}>
                Send Me a Link to Reset My Password
              </ButtonSubmit>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormRequestResetPassword;
