import { Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { Button, Form, Stack } from 'react-bootstrap';
import { HiPlusCircle } from 'react-icons/hi2';
import { useFetcher } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import ImportContactsButton from '../assets/images/import-contacts-button.svg';
import config from '../config/config';
import ButtonSubmit from './ButtonSubmit';
import CloudSpongeWidget from './CloudSpongeWidget';
import { NewUserInputs } from './NewUserInputs';
import { trackClick } from '../libs/analytics';

export type NewUserData = {
  first_name: string;
  last_name: string;
  email: string;
  is_administrator: boolean;
};
type FormValues = {
  newUsers: NewUserData[];
};
type FormCreateNewUsersProps = {
  maxNewUsers: number;
  onSuccess: () => void;
};
export const FormCreateNewUsers = ({
  maxNewUsers,
  onSuccess,
}: FormCreateNewUsersProps) => {
  const fetcher = useFetcher();
  const [availableNewUserSlots, setAvailableNewUserSlots] = useState(maxNewUsers);

  useEffect(() => {
    if (!fetcher.data || fetcher.state !== 'idle') return;
    if (fetcher.data.status / 100 === 2) {
      toast('Users have been invited!', {
        type: 'success',
      });
      onSuccess();
    }

    // there was a problem, so let's sort it out
    if (fetcher.data.status === 429) {
      // too many requests, slow down and try again later
      toast('There was a problem inviting the user. Too many requests. Wait a moment before trying again.', {
        type: 'error',
      });
    }
    else if (fetcher.data.status === 400) {
      const message = fetcher.data.data ? 
        fetcher.data.data.map(({ errors }) => Object.entries(errors).map(([key, value]) => `${key} ${value}`)).join('. ') :
        "";
      toast(`There was a problem inviting the user. ${message}.`, {
        type: 'error',
      });
    }
  }, [fetcher.data, fetcher.state]);

  // @TODO loading check
  const isLoading = useMemo(() => {
    return fetcher.state === 'loading' || fetcher.state === 'submitting';
  }, [fetcher.state]);
  // @TODO loading end

  const handleSubmit = async (values: FormValues) => {
    const formData = new FormData();
    formData.append('action', 'createUsers');
    formData.append('users', JSON.stringify(values.newUsers));
    fetcher.submit(formData, {
      method: 'post',
    });
  };

  const validationSchema = yup.object().shape({
    newUsers: yup.array().of(
      yup.object().shape({
        first_name: yup.string().required('Full name is required'),
        last_name: yup.string().required('Full name is required'),
        email: yup.string().email('Email is invalid').required('Email is required'),
      }),
    ),
  });

  return (
    <>
      <h2 className="h1 text-center mb-2">Invite users to your account</h2>
      <p className="text-center">
        Not to toot our own horn, but give our Contact Picker a try below to invite your teammates:
      </p>

      <p className="text-center">Or add them one by one...</p>
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={{
          newUsers: [
            {
              first_name: '',
              last_name: '',
              email: '',
              is_administrator: false,
            },
          ],
        }}
      >
        {({ handleSubmit, handleChange, values, setValues, touched, errors }) => (
          <>
            <p className="text-center mt-4 mb-4">
              <CloudSpongeWidget
                cloudspongeKey={config.CLOUDSPONGE_KEYS.FOR_TEAMS}
                options={{
                  key: config.CLOUDSPONGE_KEYS.FOR_TEAMS,
                  selectAccount: true,
                  selectionLimit: () => {
                    const emptySlots = values.newUsers.filter((user) => {
                      return user.first_name || user.last_name || user.email;
                    });
                    setAvailableNewUserSlots(maxNewUsers - emptySlots.length);
                    return maxNewUsers - emptySlots.length;
                  },
                  afterSubmitContacts: (contacts: any) => {
                    const newCPUsers = contacts.map((contact: any) => {
                      return {
                        first_name: contact.first_name,
                        last_name: contact.last_name,
                        email: contact.selectedEmail(),
                      };
                    });
                    let allNewUsers = [...values.newUsers, ...newCPUsers];
                    // remove any empty user objects from the array
                    allNewUsers = allNewUsers.filter((user) => {
                      return user.first_name || user.last_name || user.email;
                    });
                    setValues({ newUsers: allNewUsers });

                    const emptySlots = allNewUsers.filter((user) => {
                      return user.first_name || user.last_name || user.email;
                    });
                    setAvailableNewUserSlots(maxNewUsers - emptySlots.length);
                  },
                  afterClosing: null,
                  rootNodeSelector: '[role="dialog"]',
                }}
              >
                <button
                  className="btn border-0 cloudsponge-launch"
                  disabled={availableNewUserSlots ? false : true}
                  aria-label="Import contacts"
                  onClick={trackClick}
                >
                  <img src={ImportContactsButton} alt="" width={200} />
                </button>
              </CloudSpongeWidget>
            </p>
            <Form noValidate onSubmit={handleSubmit}>
              <Stack direction="vertical" gap={3}>
                {values.newUsers?.map((user, idx) => (
                  <NewUserInputs
                    user={user}
                    errors={errors.newUsers ? errors.newUsers[idx] : (null as any)}
                    touched={touched.newUsers ? touched.newUsers[idx] : null}
                    key={idx}
                    idx={idx}
                    onRemove={() => {
                      values.newUsers.splice(idx, 1);
                      setValues({
                        newUsers: values.newUsers,
                      });
                    }}
                    onChange={handleChange}
                    showDelete={values.newUsers.length > 1}
                  />
                ))}
              </Stack>
              <div className="mb-4 py-2">
                {maxNewUsers - values.newUsers.length > 0 && (
                  <Button
                    variant="text"
                    onClick={() => {
                      setValues({
                        newUsers: [
                          ...values.newUsers,
                          {
                            first_name: '',
                            last_name: '',
                            email: '',
                            is_administrator: false,
                          },
                        ],
                      });
                      // remove any empty user objects from the array
                      //to evaluate the number of empty slots, in favor of CSW
                      const nonEmptySlots = values.newUsers.filter((user) => {
                        return user.first_name || user.last_name || user.email;
                      });
                      setAvailableNewUserSlots(maxNewUsers - nonEmptySlots.length);
                    }}
                  >
                    <div className="d-flex gap-3 align-items-center p-2">
                      <HiPlusCircle className="icon" />
                      <span>Add Another User</span>
                    </div>
                  </Button>
                )}
              </div>
              <div className="d-flex gap-2 align-items-center justify-content-end">
                <Button variant="light-outline" onClick={() => onSuccess()}>
                  Cancel
                </Button>
                <ButtonSubmit loading={isLoading} type="submit" onClick={trackClick}>
                  Invite Users
                </ButtonSubmit>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
