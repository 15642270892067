import React from 'react';

import { Alert } from '../shared/types';

type Props = {
  notifications: Alert[];
  showCount?: boolean;
  source?: string | null;
  type?: string | null;
};

const Indicator = ({
  notifications,
  showCount = true,
  source = null,
  type = null,
}: Props) => {
  const [count, setCount] = React.useState<number>(0);

  React.useEffect(() => {
    if (type) {
      setCount(notifications.filter((notification) => notification.type === type).length);
      return;
    } else if (source) {
      setCount(
        notifications.filter((notification) => notification.source === source).length,
      );
      return;
    } else if (notifications.length === 1 && notifications[0].count) {
      setCount(notifications[0].count);
      return;
    }

    setCount(notifications.length);
  }, [notifications, source, type]);

  if (count === 0) return null;

  return (
    <>
      {showCount ? (
        <span className="bg-danger text-white rounded-pill fs-9 flex-shrink-0 w-3 h-3 flex-shrink-0 d-flex align-items-center justify-content-center">
          {count}
        </span>
      ) : (
        <span className="bg-danger rounded-circle w-1 h-1 d-inline-block flex-shrink-0" />
      )}
    </>
  );
};

export default Indicator;
