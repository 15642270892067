import { useMemo, useState } from 'react';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { RoutePaths } from '../config/route-paths';
import useSubscriptionDescriptions from '../hooks/useSubscriptionDescriptions';
import { Account } from '../shared/types';

type Props = {
  subscription: Account['subscription'];
};

const SubscriptionStatus = ({ subscription }: Props) => {
  const isActive = useMemo(() => subscription.state === 'active', [subscription]);

  if (subscription.payment_method_required) {
    return null;
  }
  const { summary } = useSubscriptionDescriptions(subscription);

  if (isActive) return null;

  return (
    <>
      <Link to={RoutePaths.PAYMENT} className="subscription-status">
        {summary}
        {subscription.ref && (
          <>
            <Badge bg="gray" className="me-n1">
              1
            </Badge>
            <span className="visually-hidden">messages</span>
          </>
        )}
      </Link>
    </>
  );
};

export default SubscriptionStatus;
