import * as React from 'react';
import NumberFormat from 'react-number-format';
import { boolean } from 'yup';

import FaIcon from './FaIcon';
import TooltipOverlay from './TooltipOverlay';

const percentageOf = (value: number, fromValue: number, precision = 2) => {
  if (fromValue == 0) {
    if (value == 0) {
      return 0.0;
    }
    return Infinity;
  }
  if (Number.isNaN(fromValue)) {
    return NaN;
  }
  return parseFloat(((value / fromValue) * 100).toFixed(precision));
};
type MetrixDataBlockProps = {
  title: string;
  subTitle?: string;
  value: number;
  info?: string;
  suffix?: string;
  previous?: number;
  increasePositive?: boolean;
};
const MetricsDataBlock = ({
  title,
  subTitle,
  value,
  info,
  suffix,
  previous,
  increasePositive = true,
}: MetrixDataBlockProps) => {
  const relativeChange = percentageOf(
    value - (previous ? previous : 0),
    previous ? previous : 0,
  );
  const noChange = relativeChange == 0.0;
  const positiveChange = !noChange && increasePositive && relativeChange > 0;
  const icon = noChange
    ? 'minus-square'
    : positiveChange
    ? 'arrow-square-up'
    : 'arrow-square-down';
  const relativeClass = noChange
    ? 'text-muted'
    : positiveChange
    ? 'text-success'
    : 'text-danger';
  const change = noChange ? 'No change' : relativeChange > 0 ? 'Increased' : 'Decreased';
  return (
    <div className="d-flex flex-column h-100 gap-3">
      <div>
        {title && <h3 className="h4 mb-1">{title}</h3>}
        {subTitle && <p className="text-muted fs-8">{subTitle}</p>}
      </div>
      <div className="mt-auto">
        <p className="fs-1 text-dark fw-semibold lh-1 mb-1">
          <NumberFormat
            value={value}
            decimalScale={2}
            displayType="text"
            thousandSeparator={true}
            suffix={suffix}
          />
        </p>
        {previous ? (
          <p className={relativeClass + ' fs-8 mb-0'}>
            <TooltipOverlay title={`${change} since the previous period.`}>
              <FaIcon icon={icon} />
              {relativeChange == Infinity ? (
                <span>∞%</span>
              ) : (
                <NumberFormat
                  value={Math.abs(relativeChange)}
                  decimalScale={2}
                  suffix={'%'}
                  displayType="text"
                />
              )}
            </TooltipOverlay>
          </p>
        ) : null}
        {info && <p className="fs-8 text-muted">{info}</p>}
      </div>
    </div>
  );
};

export default MetricsDataBlock;
