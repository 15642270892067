import classNames from 'classnames';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
  [key: string]: unknown;
};

const PageHeader = ({ children, className, ...rest }: Props) => {
  return (
    <div className={classNames('content__header', className)} {...rest}>
      {children}
    </div>
  );
};

export default PageHeader;
