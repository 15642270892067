import config from './config';

export const RoutePaths = {
  BASE: config.APP_PATH,
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  RESET_PASSWORD: '/reset',
  RESET_PASSWORD_ACCEPT: '/accept',
  CONFIRMATION: '/confirm',
  CONFIRMATION_TOKEN: ':token',
  PAYMENT: '/payment',
  PROJECTS: '/projects',
  NEW_PROJECT: 'new',
  PROJECT_ID: ':id',
  PROJECT_KEY: ':projectKey',
  METRICS: 'metrics',
  SITES: 'sites',
  SITE_ID: ':siteId',
  INSTALL: 'install',
  PROVIDER_CREDENTIALS: 'provider-credentials',
  PROVIDER_ID: ':provider',
  CONTACT_PICKER: 'contact-picker',
  PROFILE: '/profile',
  TEAM: '/team',
  REFERRALS: '/referrals',
  ZAPIER: 'zapier',
};

export const publicPaths = [
  RoutePaths.SIGNIN,
  RoutePaths.SIGNUP,
  RoutePaths.RESET_PASSWORD,
  RoutePaths.RESET_PASSWORD_ACCEPT,
  RoutePaths.CONFIRMATION,
];
