import { useCookies } from 'react-cookie';
import { Navigate, useOutlet, useParams } from 'react-router-dom';

import { RoutePaths } from '../config/route-paths';
import { useAuth } from '../contexts/authContext';
import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';
import ProjectBar from './ProjectBar';

const LayoutAuthed = () => {
  const { projectKey } = useParams();
  const { loading } = useAuth();
  const outlet = useOutlet();

  const [cookies] = useCookies(['authed']);
  const hasCookie = cookies.authed === 'true';

  if (!hasCookie) {
    return <Navigate to={RoutePaths.SIGNIN} replace />;
  }

  return loading ? (
    <Loading position="fullscreen" />
  ) : (
    <div className={projectKey ? 'layout layout--with-navigation' : 'layout'}>
      <Header navigation={!!projectKey} />
      {!!projectKey && <ProjectBar />}
      <div className="layout__main">
        <div className="content">{outlet}</div>
        <Footer />
      </div>
    </div>
  );
};

export default LayoutAuthed;
