import { Formik, FormikValues } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Button, Col, Form, Image, Modal, Row, Stack } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useFetcher, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { developerUrls } from '../config/external-urls';
import { RoutePaths } from '../config/route-paths';
import { fetchProxyUrls } from '../services/credentials';
import { fetchProjects } from '../services/projects';
import { Credential, Project } from '../shared/types';
import ButtonSubmit from './ButtonSubmit';
import ContentCard from './ContentCard';
import CredentialCopy from './CredentialCopy';
import ExternalLink from './ExternalLink';
import PageHeader from './PageHeader';
import PageSection from './PageSection';

type Props = {
  credential: Credential;
};

const CredentialForm = ({ credential }: Props) => {
  const [show, setShow] = React.useState(false);
  const [proxyUrlList, setProxyUrlList] = React.useState<any>([]);
  const [projectsWithSameCredential, setProjectsWithSameCredential] = React.useState<any>(
    [],
  );
  const formikRef = React.useRef<any>(null);
  const { projectKey } = useParams<{ projectKey: string }>();
  const fetcher = useFetcher();
  const navigate = useNavigate();

  const isLoading = useMemo(() => {
    return fetcher.state === 'loading' || fetcher.state === 'submitting';
  }, [fetcher.state]);

  useEffect(() => {
    if (!fetcher.data || fetcher.state !== 'idle') return;
    if (fetcher.data?.ok) {
      navigate(`${RoutePaths.PROJECTS}/${projectKey}/${RoutePaths.PROVIDER_CREDENTIALS}`);
    }
  }, [fetcher.data, fetcher.state]);

  useEffect(() => {
    const fetchProxyUrlList = async () => {
      const res = await fetchProxyUrls();
      setProxyUrlList(res.data.map((item: any) => item.url));
    };

    fetchProxyUrlList();
  }, []);

  useEffect(() => {
    const fetchProjectsWithSameCredential = async () => {
      const res = await fetchProjects();
      const projects = res
        .filter((projectItem: Project) => {
          return projectItem.key !== projectKey;
        })
        .filter((project: Project) => {
          return project.credentials.some((credentialItem: Credential) => {
            return credentialItem.source === credential.source;
          });
        });
      setProjectsWithSameCredential(projects);
    };

    fetchProjectsWithSameCredential();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (values: FormikValues) => {
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }
    projectKey && formData.append('project_key', projectKey);
    formData.append('source', credential.source);

    if (!credential.readonly && credential.id) {
      formData.append('id', credential.id);

      // If client_secret is not changed, don't send it to the backend
      // because the backend sent us a masked value which we don't want to persist
      if (formData.get('client_secret') === credential.client_secret) {
        formData.delete('client_secret');
      }
      formData.append('action', 'update');
    } else {
      formData.append('action', 'create');
    }

    fetcher.submit(formData, {
      method: 'post',
    });
  };

  const submitForm = () => {
    formikRef.current.handleSubmit();
  };

  const copyCredentials = (projectCredentials: Credential) => {
    formikRef.current.setValues(projectCredentials);

    handleClose();
  };

  const schema = yup.object().shape({
    proxy_url: yup.string().required('Proxy URL is required').url('Invalid URL'),
    client_id: yup.string().required('Client ID is required'),
    client_secret: yup.string().required('Client Secret is required'),
  });

  return (
    <>
      <PageHeader>
        <div className="align-items-center justify-content-between d-flex flex-wrap gap-2">
          <Stack direction="horizontal" gap={3} className="align-items-center">
            <Image
              fluid
              src={credential.icon}
              alt={credential.shortTitle}
              loading="lazy"
            />
            <h1 className="m-0">{credential.shortTitle}</h1>
          </Stack>
          <Stack direction="horizontal" gap={3} className="align-items-center flex-wrap">
            {projectsWithSameCredential.length > 0 && (
              <>
                <Button variant="link" className="fs-7 p-0" onClick={handleShow}>
                  Copy credentials from another project
                </Button>
                <CredentialCopy
                  show={show}
                  projects={projectsWithSameCredential}
                  handleClose={handleClose}
                  copyCredentials={copyCredentials}
                  credentialSource={credential.source}
                />
              </>
            )}

            <ButtonSubmit type="submit" loading={isLoading} onClick={submitForm}>
              Save Credentials
            </ButtonSubmit>
          </Stack>
        </div>
      </PageHeader>
      <PageSection>
        <div className="mb-4">
          <h2 className="mb-0">Enter Your {credential.title} OAuth Credentials</h2>
        </div>
        <ContentCard>
          <Row>
            <Col md={8}>
              <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                innerRef={formikRef}
                initialValues={{
                  proxy_url: (!credential.readonly && credential.proxy_url) || '',
                  client_id: (!credential.readonly && credential.client_id) || '',
                  client_secret: (!credential.readonly && credential.client_secret) || '',
                  scope: (!credential.readonly && credential.scope) || '',
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                  setFieldValue,
                  getFieldHelpers,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="proxy_url" className="mb-3 grid gap-2">
                      <Form.Label bsPrefix="g-col-12 g-col-sm-4 mb-sm-0 py-2">
                        Proxy URL
                      </Form.Label>
                      <div className="g-col-12 g-col-sm-8">
                        <Typeahead
                          id="proxy_url"
                          selected={values?.proxy_url ? [values.proxy_url] : []}
                          multiple={false}
                          emptyLabel="No results found"
                          newSelectionPrefix=""
                          options={proxyUrlList}
                          placeholder="Enter URL"
                          isInvalid={touched.proxy_url && !!errors.proxy_url}
                          onChange={(selected) => {
                            selected[0] && setFieldValue('proxy_url', selected[0]);
                          }}
                          onInputChange={(input) => {
                            setFieldValue('proxy_url', input);
                          }}
                          onBlur={() => {
                            getFieldHelpers('proxy_url').setTouched(true);
                          }}
                          {...(touched.proxy_url &&
                            !!errors.proxy_url && { className: 'is-invalid' })}
                        />
                        {touched.proxy_url && (
                          <Form.Control.Feedback type="invalid">
                            {errors.proxy_url}
                          </Form.Control.Feedback>
                        )}
                        <Form.Text>
                          <ExternalLink href={developerUrls.learnAboutProxyUrl}>
                            Learn more about the Proxy URL
                          </ExternalLink>
                        </Form.Text>
                      </div>
                    </Form.Group>
                    <Form.Group
                      controlId="client_id"
                      className="mb-3 grid gap-2 align-items-center"
                    >
                      <Form.Label bsPrefix="g-col-12 g-col-sm-4 mb-sm-0">
                        Client ID
                      </Form.Label>
                      <div className="g-col-12 g-col-sm-8">
                        <Form.Control
                          type="text"
                          name="client_id"
                          value={values.client_id}
                          onChange={handleChange}
                          isInvalid={touched.client_id && !!errors.client_id}
                          placeholder=""
                        />
                        {errors.client_id && (
                          <Form.Control.Feedback type="invalid">
                            {errors.client_id}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group
                      controlId="client_secret"
                      className="mb-3 grid gap-2 align-items-center"
                    >
                      <Form.Label bsPrefix="g-col-12 g-col-sm-4 mb-sm-0">
                        Client Secret
                      </Form.Label>
                      <div className="g-col-12 g-col-sm-8">
                        <Form.Control
                          type="text"
                          name="client_secret"
                          value={values.client_secret}
                          onChange={handleChange}
                          isInvalid={touched.client_secret && !!errors.client_secret}
                          placeholder=""
                        />
                        {errors.client_secret && (
                          <Form.Control.Feedback type="invalid">
                            {errors.client_secret}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Form.Group>
                    {/* TODO: Think this is not saving contacts.other.readonly */}
                    {credential.source === 'gmail' && (
                      <Form.Group
                        controlId="scope"
                        className="mb-3 grid gap-2 align-items-center"
                      >
                        <Form.Label bsPrefix="g-col-12 g-col-sm-4 mb-sm-0">
                          API scopes
                        </Form.Label>
                        <div className="g-col-12 g-col-sm-8">
                          <Form.Check
                            type="checkbox"
                            id="scope"
                            value="https://www.googleapis.com/auth/contacts.other.readonly"
                            label="Other Contacts"
                            defaultChecked={values.scope.includes(
                              'contacts.other.readonly',
                            )}
                            onChange={(e) => {
                              const { setValue } = getFieldHelpers('scope');
                              setValue(
                                e.target.checked
                                  ? 'profile email https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly'
                                  : 'profile email https://www.googleapis.com/auth/contacts.readonly',
                              );
                            }}
                          />
                          {errors.scope && (
                            <Form.Control.Feedback type="invalid">
                              {errors.scope}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Form.Group>
                    )}
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ContentCard>
      </PageSection>
    </>
  );
};

export default CredentialForm;
