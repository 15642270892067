import { useMemo } from 'react';
import { useParams, useRouteLoaderData } from 'react-router-dom';

import AolLogo from '../assets/images/logo-aol.svg';
import GmailLogo from '../assets/images/logo-google.svg';
import OutlookLogo from '../assets/images/logo-outlook.svg';
import YahooLogo from '../assets/images/logo-yahoo.svg';
import { Credential, SitesLoaderData } from '../shared/types';

export const useCredentials = () => {
  const { provider } = useParams<{ provider: string }>();
  const fetchedCredentials = useRouteLoaderData('credentials') as Credential[];
  const {
    data: { devSites, productionSites },
  } = useRouteLoaderData('sites') as { data: SitesLoaderData };

  const disabledList = useMemo(() => {
    return devSites.totalPages === 0 && productionSites?.totalPages === 0;
  }, [devSites, productionSites]);

  const credentials = useMemo(() => {
    const displayedCredentials = [
      {
        icon: GmailLogo,
        title: 'Google Contacts',
        shortTitle: 'Google Contacts',
        source: 'gmail',
        scope: 'profile email https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly',
      },
      {
        icon: OutlookLogo,
        title: 'Microsoft (Outlook.com, Office365)',
        shortTitle: 'Microsoft',
        source: 'outlookcom',
      },
      {
        icon: YahooLogo,
        title: 'Yahoo',
        shortTitle: 'Yahoo',
        source: 'yahoo',
      },
      {
        icon: AolLogo,
        title: 'AOL',
        shortTitle: 'AOL',
        source: 'aol',
      },
    ];

    const credentionalsMap = displayedCredentials.reduce((acc: any[], credential) => {
      const fetchedCredential = fetchedCredentials.find(
        (fetchedCredential) => fetchedCredential.source === credential.source,
      );

      if (fetchedCredential) {
        acc.push({
          ...credential,
          ...fetchedCredential,
        });
      } else {
        acc.push(credential);
      }

      return acc;
    }, []);

    return credentionalsMap;
  }, [fetchedCredentials]);

  const currentCredential = useMemo(() => {
    return credentials.find((credential) => credential.source === provider);
  }, [credentials, provider]) as Credential;

  return { credentials, currentCredential, disabledList };
};
