import { useMemo } from 'react';
import Avatar from 'react-avatar';

import { Role, useProfileName, useProfileRole } from '../hooks/useProfile';
import { User } from '../shared/types';

type Props = {
  me: User;
  user?: User;
  size?: string;
};

const UserAvatar = ({ me, user, size = '46' }: Props) => {
  const userRole = useProfileRole(me);
  const userName = useProfileName(me, user, false);

  const avatarBackgroundColor = useMemo(() => {
    if (!me || !user || me.id == user.id) {
      return 'var(--bs-cyan)';
    } else if (userRole === Role.OWNER) {
      return 'var(--bs-red)';
    } else if (userRole === Role.ADMINISTRATOR) {
      return 'var(--bs-orange)';
    } else {
      return 'var(--bs-green)';
    }
  }, [userRole]);

  return <Avatar name={userName} size={size} round color={avatarBackgroundColor} />;
};

export default UserAvatar;
