import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { supportEmail } from '../config/external-urls';
import { RoutePaths } from '../config/route-paths';
import { useAuth } from '../contexts/authContext';
import { skipPaymentInfo } from '../services/user';
import ExternalLink from './ExternalLink';
import Loading from './Loading';
import PageHeader from './PageHeader';
import PageSection from './PageSection';
import Subscription from './Subscription';
import SubscriptionTrialInfo from './SubscriptionTrialInfo';

const GauntletPayment = () => {
  const { user: me, account, fetchAccount } = useAuth();
  const navigate = useNavigate();

  const handleSkip = async () => {
    try {
      await skipPaymentInfo();
      const accoutId = account?.id as number;
      await fetchAccount(accoutId);
      navigate(RoutePaths.PROJECTS);
    } catch (error) {
      console.log('error', error);
    }
  };

  if (!account) {
    return <Loading variant="fullheight" />;
  }

  return account.subscription.state == 'canceled' ||
    account.subscription.state == 'trial_ended' ||
    account.subscription.state == 'on_hold' ? (
    <>
      {me?.impersonation_session && (
        <Alert className="sticky-banner" variant="info">
          Only admins can <Alert.Link onClick={handleSkip}>Skip the gauntlet</Alert.Link>
        </Alert>
      )}

      <div className="container my-auto">
        <PageHeader>
          <h1 className="text-center">Reactivate your Contact Picker</h1>
        </PageHeader>
        <PageSection>
          <p className="text-center mb-0">
            You've lost access to your Contact Picker, and any Perfect Personalization
            you've added to your messages.
          </p>

          {account?.subscription.payment_method_required ? (
            <>
              <p className="text-center">
                But all is not lost! You just need to update your payment information and
                restart your subscription.
              </p>

              <p className="text-center mt-3 mb-0 fw-semibold text-dark">
                Do you need a little more time? No problem, just{' '}
                <ExternalLink href={`mailto:${supportEmail}`}>contact us</ExternalLink>{' '}
                to let us know and we can help out.
              </p>

              <div className="my-4 mt-sm-5">
                <Subscription isGauntlet />
              </div>

              <p className="text-center fs-7 mt-5 mb-0">
                You can always{' '}
                <ExternalLink href={`mailto:${supportEmail}`}>
                  reach out to us
                </ExternalLink>
                , we're happy to help!
              </p>
            </>
          ) : (
            <>
              <p className="text-center">
                But all is not lost! Just let us know that you want to restart your
                subscription and we'll get you back in the game!
              </p>
              <p className="text-center mt-3 mb-0 fw-semibold text-dark">
                <ExternalLink href={`mailto:${supportEmail}`}>Contact us</ExternalLink>,
                we're happy to help!
              </p>
            </>
          )}
        </PageSection>
      </div>
    </>
  ) : (
    <>
      <div className="container my-auto">
        <PageHeader>
          <h1 className="text-center">Let's get this party started!</h1>
        </PageHeader>
        <PageSection>
          <p className="text-center">
            Pick a plan and enter your payment information to unlock your Contact Picker.
          </p>

          <p className="text-center">
            All plans include:
          </p>
          <SubscriptionTrialInfo className="text-center" />

          {/* {!hasGoogle && (
            <p className="text-center">
              It also lets us help you enable your Google Contacts integration by letting
              us get started on the{' '}
              <ExternalLink href={developerUrls.oauthSetup}>OAuth setup</ExternalLink>{' '}
              with you.
            </p>
          )} */}

          {account?.subscription.payment_method_required ? (
            <div className="my-4 mt-sm-5">
              <Subscription isGauntlet />
            </div>
          ) : null}

          {!!account?.subscription?.subscription_id &&
            <p className="text-center">
              <Button variant="link" className="fw-semibold" onClick={handleSkip}>
                Skip for now
              </Button>
            </p>
          }

          <p className="text-center fs-7 mt-5 mb-0">
            If anything about our pricing is unclear,{' '}
            <ExternalLink href={`mailto:${supportEmail}`}>reach out to us</ExternalLink>,
            we're happy to help!
          </p>
        </PageSection>
      </div>
    </>
  );
};

export default GauntletPayment;
