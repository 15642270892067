import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Credential } from '../shared/types';
import CredentialCard from './CredentialCard';

type Props = {
  credentials: Credential[];
};

const CredentialList = ({ credentials }: Props) => {
  const navigate = useNavigate();
  const handleOnClick = (credentialSource: Credential['source']) => {
    navigate(credentialSource);
  };

  return (
    <>
      {credentials.map((credential) => (
        <Row className="g-2">
          <Col key={credential.source}>
            <CredentialCard
              credential={credential}
              onClick={() => handleOnClick(credential.source)}
            />
          </Col>
        </Row>
      ))}
    </>
  );
};

export default CredentialList;
