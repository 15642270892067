import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

import { DetailedMetricsType } from '../shared/types';
import { SelectDropdown } from './SelectDropdown';

type MetricsSideMenuProps = {
  items: DetailedMetricsType[];
  selectedItem: DetailedMetricsType;
  onClick: (metricsId: number) => void;
};
const MetricsSideMenu = (props: MetricsSideMenuProps) => {
  const { items, selectedItem, onClick } = props;
  return (
    <SelectDropdown show={true}>
      <Dropdown.Toggle
        color="primary"
        className="btn-select dropdown-mobile__trigger"
      >
        {selectedItem.name}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-mobile__menu">
        {items.map((item) => (
          <Dropdown.Item
            key={item.id}
            active={item.id === selectedItem.id}
            onClick={() => {
              onClick(item.id);
            }}
          >
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </SelectDropdown>
  );
};
export default MetricsSideMenu;
