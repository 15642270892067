const config = {
  DATA_PATH: `${import.meta.env.VITE_APP_BASE}/data`,
  APP_PATH: `${import.meta.env.VITE_APP_BASE}/app`,

  REFRESH_TOKEN_INTERVAL: 3600000, //ms

  CLOUDSPONGE_KEYS: {
    FOR_TEAMS: 'manage-team-page',
    FOR_REFERRALS: 'Z_Krc9lh6X9IV5_a_ODvbQ',
  },

  MESSAGES: {
    SYSTEM_FAIL: 'Something went wrong',
    LOGIN_FAIL: 'Invalid email or password',
    REGISTER_FAIL: 'Something went wrong in your Registration ',
    REGISTER_SUCCESS: 'Registration completed. Please Log In.',
    EMAIL_NOT_FOUND: 'The email address was not found',
    PASSWORD_SENT: 'New Password created and sent by email',
    PASSWORD_TOKEN_SENT:
      'An email has been sent to reset your password. Please check your inbox.',
    UNABLE_TO_FETCH: 'Unable to fetch data from server',
    UNABLE_TO_PUSH: 'Unable to push data to server',
    NOTIFICATIONS_READ: 'Notifications marked as read',
    LOGOUT: 'Signed Out',
    INVALID_TOKEN: 'Invalid Token',
    EXPIRED_TOKEN: 'Token Expired. Request a new token.',
    TOKEN_VERIFIED: 'Registration Token Verified. Please Log In.',
    PROFILE_UPDATED: 'Profile Updated',
    MODERATOR_ADDED: 'Moderator Added',
    MODERATOR_REMOVED: 'Moderator Removed',
    CONTRIBUTOR_INVITED: 'Contributors Invited',
    CHALLENGE_INVITED: 'User Invited to Challenges',
    CONTRIBUTIONS_RESTRICTED: 'Contributions Restricted for this challenge',
    PHASE_CREATED: 'Phase created',
    PHASE_NOT_CREATED: 'Could not create Phase',
    PHASE_UPDATED: 'Phase updated',
    PHASE_NOT_UPDATED: 'Could not save Phase data',
    PHASE_DELETED: 'Phase successfully deleted',
    MODERATION_QUESTION_ADDED: 'Question updated',
    MODERATION_QUESTION_NOT_ADDED: 'Could not add Question',
    MODERATION_QUESTION_UPDATED: 'Question updated',
    MODERATION_QUESTION_NOT_UPDATED: 'Could not update Question',
    MODERATION_QUESTION_REMOVED: 'Question removed',
    MODERATION_QUESTION_NOT_REMOVED: 'Could not remove Question',
    PROJECT_UPDTED: 'Project Updated',
    CHALLENGE_UPDTED: 'Challenge Updated',
    CONTRIBUTION_UPDATED: 'Contribution Updated',
    CONTRIBUTION_UPDATE_NOT_ALLOWED: 'You cannot update this Contribution',
    PASSWORD_UPDATED: 'Password has been updated',
    PASSWORD_NOT_UPDATED: 'Could not update Password. Enter current password again.',
    PASSWORDS_NOT_MATCH:
      'Could not update Password. Password and Password Confimation do not macth',
    BUCKET_NOT_RENAMED: 'Bucket could not be renamed',
    BOARD_SELECT_DATES: 'Please select dates',
    BOARD_INVALID_DATES: 'Inicial Date is greater than Final Date',
    BUCKET_NOT_SET_AS_WINNER: 'Could not set this bucket as Winners',
    WINNER_BUCKET_TITLE: ' - PHASE WINNERS',
    CONTRIBUTION_COPIED: 'Contribution copied to specified phase',
    CONTRIBUTION_NOT_COPIED: 'Contribution could not be copied.',
    CONTRIBUTION_ARCHIVED: 'Contribution archived.',
    CONTRIBUTION_NOT_ARCHIVED: 'Contribution could not be archived.',
    CONTRIBUTION_UNARCHIVED: 'Contribution unarchived.',
    CONTRIBUTION_NOT_UNARCHIVED: 'Contribution could not be unarchived.',
    PROJECT_CREATED: 'Project created',
    PROJECT_NOT_CREATED: 'Project already created from this contribution',
    VIDEO_MANDATORY: 'Please record a video before submitting',
    SCORE_ALL_QUESTIONS: 'Please rate all of the questions',
    SCORE_COMMENT: 'Please enter your notes',
    SCORE_SUBMIT: 'Would you like to submit the scores?',
    SCORE_SAVED: 'Your ratings have been saved. Thank you.',
    RESTRICTED_USER_ADDED: 'Restricted User Added',
    RESTRICTED_USER_REMOVED: 'Restricted User Removed',
    DELETE_PHASE:
      'Do you want to delete this phase? All related content with the phase will be lost.',
    DELETE_COMMENT: 'Do you want to delete this comment?',
    DISCARD_CHANGES: 'Do you want to discard changes?',
    NO_REPORTS_FOUND: 'No reports found for this contribution',
    NO_FOLLOWERS_FOUND: 'No followers found for this contribution',
    ANSWER_ALL_QUESTIONS:
      'Please respond to all of the required questions before submitting',
    ALREADY_ANSWERED_SURVEY:
      'Oops! Looks like you have already participated in this survey',
    SURVEY_SAVED: 'Survey saved',
    SURVEY_DEFAULT_TITLE: 'Survey title here',
    SURVEY_DEFAULT_DESCRIPTION: 'Survey description here...',
    QUESTION_DEFAULT_TITLE: 'Question here',
    QUESTION_DEFAULT_DESCRIPTION: 'Question description here...',
    ANSWER_DEFAULT_DESCRIPTION: 'Answer here',
    DELETE_SURVEY: 'Do you want to delete this survey?',
    SURVEY_QUESTION_SAVED: 'Question saved',
    SURVEY_QUESTION_DELETED: 'Question Deleted',
    DELETE_QUESTION: 'Do you want to delete this question?',
    REMOVE_SURVEY_FROM_PHASE: 'Do you want to remove this survey from this phase?',
  },
  RESPONSE_ERRORS: {
    SITE_NAME_EXISTS: 'has already been taken'
  },
  SITES_PER_LOAD: 5,
};

export default config;
