import { Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { RoutePaths } from '../config/route-paths';
import { useAuth } from '../contexts/authContext';
import ButtonSubmit from './ButtonSubmit';
import { trackClick } from '../libs/analytics';

type FormValues = {
  first_name: string;
  last_name: string;
  job_title: string;
  password: string;
  password_confirmation: string;
};

const FormProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user, updateUserData } = useAuth();
  const [loading, setLoading] = useState(false);

  const jobTitleOptions = [
    'Executive (Owner, Founder)',
    'Technology or Engineering',
    'Product or Design',
    'Marketing or Growth',
    'Finance or Accounts Payable',
  ];

  const handleSubmit = async (
    values: FormValues,
    { setErrors }: FormikHelpers<FormValues>,
  ) => {
    try {
      setLoading(true);
      await updateUserData(values);
      navigate(location.state?.from || RoutePaths.PROJECTS);

      toast('Your profile has been updated successfully', {
        type: 'success',
      });
    } catch (error: any) {
      const errors = error?.errors as Record<string, string[]>;
      if (!errors) {
        toast('Something went wrong', {
          type: 'error',
        });

        return;
      }

      let errorsObj = {};
      for (const [key, value] of Object.entries(errors)) {
        const fieldErrors = value.join(' ,');

        errorsObj = {
          ...errorsObj,
          [key]: `${key} ${fieldErrors}`,
        };
      }

      setErrors(errorsObj);
    } finally {
      setLoading(false);
    }
  };

  const schema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    job_title: yup.string().required('Role is required'),
    // TODO: maybe Add password validation
    // Password must contain a special character: !, @, #, $, &, or *
    password: yup.string(),
    password_confirmation: yup.string(),
  });

  return (
    <div>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          first_name: user?.first_name || '',
          last_name: user?.last_name || '',
          job_title: user?.job_title || jobTitleOptions[0],
          password: '',
          password_confirmation: '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="first_name" className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
                isInvalid={touched.first_name && !!errors.first_name}
                placeholder="First Name"
                autoComplete="given-name"
              />
              {errors.first_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.first_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="last_name" className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                isInvalid={touched.last_name && !!errors.last_name}
                placeholder="Last Name"
                autoComplete="family-name"
              />

              {errors.last_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.last_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="job_title" className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Select
                aria-label="Job Title"
                name="job_title"
                value={values.job_title}
                onChange={handleChange}
              >
                {jobTitleOptions.map((option, i) => (
                  <option value={option} key={i}>
                    {option}
                  </option>
                ))}
              </Form.Select>

              {errors.job_title && (
                <Form.Control.Feedback type="invalid">
                  {errors.job_title}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="password" className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                isInvalid={touched.password && !!errors.password}
                placeholder="Password"
                autoComplete="new-password"
              />
              {errors.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
              <Form.Text className="text-muted">
                Leave it blank if you don&#39;t want to change it.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="password_confirmation">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                name="password_confirmation"
                value={values.password_confirmation}
                onChange={handleChange}
                isInvalid={
                  touched.password_confirmation && !!errors.password_confirmation
                }
                placeholder="Current Password"
              />
              {errors.password_confirmation && (
                <Form.Control.Feedback type="invalid">
                  {errors.password_confirmation}
                </Form.Control.Feedback>
              )}
              <Form.Text className="text-muted">
                Enter your current password only if you are changing your password.
              </Form.Text>
            </Form.Group>
            <p className="mt-4">
              <ButtonSubmit
                variant="info"
                className="w-100"
                type="submit"
                loading={loading}
                onClick={trackClick}
              >
                Save
              </ButtonSubmit>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormProfile;
