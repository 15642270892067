import { format, formatDistanceToNow } from 'date-fns';
import { useMemo } from 'react';

import { Account } from '../shared/types';

type Props = Account['subscription'];

const useSubscriptionDescriptions = (subscription: Props) => {
  const description = useMemo(() => {
    const dateFormat = 'MMMM d, yyyy';
    if (!subscription.state) {
      if (subscription.ref) {
        return {
          summary: 'Sandbox Account: Start your trial to get 10% off for 12 months!',
          tip: 'Start your trial before the end of the month and claim your 10% discount.',
          details:
            "Since you've followed a referral link from one of our customers, you'll get a 10% off your subscription for 1 year when you start your trial.",
        };
      }
      return {
        summary: 'Sandbox Account: Start your trial',
        tip: "You may use your widget for development only. Our system will prevent any access that looks like it's from a live site.",
        details:
          "You haven't added your billing info yet. You may use your keys freely for development only.",
      };
    }
    if (subscription.state === 'trialing') {
      const referralDetails = subscription.ref
        ? " Since you've followed a referral link from one of our customers, you got a discount on your subscription."
        : '';
      const actionDetails = subscription.payment_method_required
        ? ' Visit the billing portal to update your payment method to keep a smooth transition on this day.'
        : '';
      if (
        subscription.handle === 'address-books-monthly' ||
        subscription.handle === 'tiered_monthly'
      ) {
        return {
          summary: `Licensed`,
          details: `Your first payment for ${subscription.plan_name} is due on ${format(
            new Date(subscription.next_billing_date),
            dateFormat,
          )}.${referralDetails}${actionDetails}`,
        };
      }
      if (subscription.payment_method_required) {
        return {
          summary: `${formatDistanceToNow(
            new Date(subscription.next_billing_date),
          )} left in your trial`,
          details: `Your first payment for ${subscription.plan_name} is due on ${format(
            new Date(subscription.next_billing_date),
            dateFormat,
          )}.${referralDetails}${actionDetails}`,
        };
      }
      if (subscription.next_billing_date) {
        return {
          summary: `Trial expires in ${formatDistanceToNow(
            new Date(subscription.next_billing_date),
          )}`,
          details: `Your first payment for ${subscription.plan_name} is due on ${format(
            new Date(subscription.next_billing_date),
            dateFormat,
          )}.${referralDetails}${actionDetails}`,
        };
      }
      return {
        summary: `Trial does not expire`,
        details: `You aren't being billed for ${subscription.plan_name || 'this account'}.${referralDetails}${actionDetails}`,
      };
    }
    if (subscription.state === 'past_due') {
      return {
        summary: 'Past Due: Verify your payment method',
        details: `Your payment for ${subscription.plan_name} has recently failed. Please verify your payment method or contact us to help resolve the issue.`,
      };
    }
    if (subscription.state === 'trial_ended') {
      if (subscription.payment_method_required) {
        return {
          summary: 'Trial ended',
          details:
            'Your trial ended before you added your billing information. Contact us to restart your subscription.',
        };
      }
      return {
        summary: 'Trial ended',
        details: 'Your trial ended. Contact us to restart your subscription.',
      };
    }
    if (subscription.state === 'canceled') {
      if (subscription.payment_method_required) {
        return {
          summary: 'Trial expired',
          details: 'Contact us to restart your subscription.',
        };
      }
      return {
        summary: 'Canceled',
        details: 'Your plan was canceled. Contact us to restart your subscription.',
      };
    }
    if (subscription.state === 'active') {
      if (subscription.partner === 'appsumo') {
        return {
          summary: 'Licensed',
          details: `Thank you, Sumoling!`,
        };
      }
      if (subscription.partner === 'manual') {
        return {
          summary: 'Licensed',
          details: `We love our partners! Thanks for being a partner of ours. Contact us for details of your subscription.`,
        };
      }  
      return {
        summary: 'Licensed',
        details: `Thanks for keeping your plan for ${
          subscription.plan_name
        } up-to-date. Your next payment for ${subscription.plan_name} is due on ${format(
          new Date(subscription.next_billing_date),
          dateFormat,
        )}.`,
      };
    }
    if (subscription.state === 'on_hold') {
      return {
        summary: 'On Hold',
        details: `Your subscription is on hold. Please contact us to resume it.`,
      };
    }
    return {
      summary: 'Unknown',
      details: 'Your subscription is in an unknown state. Contact us for details.',
    };
  }, [subscription]);

  return description as {
    summary: string;
    tip?: string;
    details: string;
  };
};

export default useSubscriptionDescriptions;
